/*
$.getScript("js/jquery.creditcardValidator.js");
$.getScript("js/jquery.maskedinput.min.js");

$.getScript("js/jquery.auto-complete.js");
*/
// $.getScript("js/jquery.creditcardValidator.js");

var total_markers = 0;
var resorts_showed = 10;

// Listen for orientation changes
window.addEventListener("orientationchange", function () {
    // Announce the new orientation number
}, false);

// Listen for resize changes
window.addEventListener("resize", function () {

}, false);


window.addEventListener("pageshow", function (event) {
    var historyTraversal = event.persisted || (typeof window.performance != "undefined" && window.performance.navigation.type === 2);
    if (historyTraversal) {
        NProgress.done();
    }
});

var transform_to_map_search = false;

$(document).ready(function () {

    NProgress.done();
    // ByMC: For addonpayments
    // if($("#addonPayments").length) {
    //    $.ajax({
    //        type: 'POST',
    //        dataType: 'json',
    //        url: 'addonresquest/',
    //        data: {},
    //        success: function (data) {
    //            //RealexHpp.setHppUrl('https://hpp.addonpayments.com/pay');
    //            RealexHpp.setHppUrl('https://hpp.sandbox.addonpayments.com/pay');
    //            RealexHpp.init('addonPayments', 'addonresponse/', data);
    //        },
    //        error: function (XMLHttpRequest, textStatus, errorThrown) {
    //            //console.log(errorThrown);
    //        }
    //    });
    // }

    $("form[method=POST]").submit(function (e) {
        //console.log('submit......post');
        e.preventDefault();
    });

    $("form[method=GET]").submit(function (e) {
        //console.log('submit......get');
        e.preventDefault();

        $(this).find('input, select').each(function () {
            var attr = $(this).attr('required');
            var fill = 0;
            var fields = [];
            if (typeof attr !== typeof undefined && attr !== false) {
                // Element has this attribute
                if ($(this).val() == '') {
                    var name = $(this).attr('name');
                    fill += 1;
                    fields.push(name);
                }
            }

            if (fill > 0) {
                $('#message #text').html('Fill ' + fields.join(", ") + ' fields');
                $('#message').modal('show');
                e.preventDefault();
                return false;
            } else {
                NProgress.start();
            }
        });
    });

    $(window).bind('mousewheel DOMMouseScroll MozMousePixelScroll', function (event) {
        var scrollTop = $(document).scrollTop().valueOf();

        if (scrollTop > 100) {
            $('#header').addClass('top', 200);
        } else {
            $('#header').removeClass('top', 200);
        }
    });

    $('body').inspira();

    $('a[data-event="hover"]').hover(function () {
        var target = $(this).data('target');
        $(target).modal('show');

        $(target + ' .modal-wrapper').mouseenter(function () {

            $(this).mouseleave(function () {
                $(target).modal('hide');
            });

        });

        $(target).draggable({
            handle: ".modal-wrapper"
        });
        $('.modal-backdrop').css('display', 'none');

    });

    $('.navbar-collapse').on('show.bs.collapse', function () {
        $('a#register-box').css('visibility', 'hidden');
    });

    $('.navbar-collapse').on('hide.bs.collapse', function () {
        $('a#register-box').css('visibility', 'visible');
    });

    if (typeof $('body').find('> #carousel-slider')[0] != 'undefined') {
        var height = window.innerHeight;
        $('#carousel-slider .carousel-inner .item').css('height', height);


        $(window).resize(function () {
            var height = window.innerHeight;
            $('#carousel-slider .carousel-inner .item, .carousel-inner video').css('height', height);
            $('#carousel-slider .carousel-inner .item, .carousel-inner video').css('zoom', 1);
        });
    }


    $('div.modal').on('hidden.bs.modal', function () {
        $('.alert').hide();
        $('body').attr('style', 'padding:0;');
        $('body.profile').attr('style', 'padding:0;');
    })

    $('div.modal').on('shown.bs.modal', function () {
        $('body').attr('style', 'padding:0;');
        $('body').addClass('modal-open');
    })


    $('a.do-fb').on('click', function (evnt) {
        var discountCode = $(this).parents('div.modal').find('input[name=access_code]').val();
        //console.log(typeof discountCode);
        if (typeof discountCode == 'undefined') {
            window.location.href = $(this).attr('href');
        } else if (typeof discountCode == 'string' && discountCode != '') {
            window.location.href = $(this).attr('href') + '/' + discountCode;
        }
        return false;
    });


    $('*[data-toggle="tooltip"]').tooltip();


    $('.form-control').on('keypress', function (e) {
        if (e.keyCode == 13)
            e.preventDefault();
    });

    $('select.room-type').on('change', function () {
        var txt = $("select.room-type option:selected").text();
        $('span.rooms-quantity').text(txt);
    });

    $('.plus, .minus').on('click', function () {
        var button = $(this);
        var input = button.siblings().closest('input[type=hidden]');
        var oldValue = input.val();

        if (button.hasClass('plus')) {
            var newVal = parseFloat(oldValue) + 1;
        } else {
            // Don't allow decrementing below zero
            if (oldValue > 1) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 1;
            }

        }

        var name = input.attr('name') + '-quantity';
        $('span.' + name).text(newVal);
        button.parent().find("input").val(newVal);

    });


    $('#saveCustomLocation').on('click', function (evnt) {//gsv
        let active = false;
        if ($('#saveLocationActive').is(":checked")) {
            active = true;
        }

        let usuario = $(this).attr("value")

        let shortCountry = "";
        let state = "";
        let city = "";

        let lat = featureMap._map.getCenter().lat();
        let lng = featureMap._map.getCenter().lng();
        let place = featureMap._autocomplete.getPlace();
        let zoomLevel = featureMap._map.getZoom();

        //console.log(JSON.stringify(place))
        if (typeof place !== "undefined" && place.hasOwnProperty('address_components')) {
            let name = place.name;
            available = "true";
            place.address_components.forEach(function (address, index) {
                shortCountry = address.types.includes("country") ? address.short_name : shortCountry;
                state = address.types.includes("administrative_area_level_1") ? address.long_name : state;
                city = address.types.includes("locality") ? address.long_name : city;
            });
            //}

            $('#saveCustomLocation').addClass('disabled');
            $('#saveCustomLocation').css('cursor', 'hand');
            let data = { "name": name, "city": city, "state": state, "country": shortCountry, "zoom": zoomLevel, "center": { "lat": lat, "lng": lng }, "active": active, "user": usuario };

            $.ajax({
                url: SERVICES_URL + '/map/custom_location/',
                type: 'POST',
                crossDomain: true,
                data,
                async: true
            });

            return false;

        }
    });


    $('#moreFiltersApply').on('click', function (evnt) {
        console.log("#moreFiltersApply")
        console.log("arrSeleccionados", arrSeleccionados)

        $("input[name=amenities]").each(function (index) {
            let value = $(this).attr('value');
            let id = $(this).attr('id');
            if ($(this).is(':checked')) {
                arrSeleccionados.push({ id, value });
            } else {
                arrSeleccionados = arrSeleccionados.filter(function (obj) {
                    return obj.value !== value;
                });
            }
        });
        morefiltersObj.amenities = [...new Set(arrSeleccionados)];;
        featureMap._refresh_cluster();
        //featureMap._more_filters(morefiltersObj);
    });

    $('#moreFiltersReset').on('click', function (evnt) {//gsv
        featureMap._reset_more_filters();
        SnippetMoreFilters.reset();
        //featureMap._more_filters(morefiltersObj);
        featureMap._refresh_cluster();
        //featureMap._reset_more_filters();
        if (all_resorts.length > 0) {
            featureMap._resort_with_wls_full = [...all_resorts];
        } else {
            featureMap._resort_with_wls_full;
        }

    });


    $("#morfiltersModal").on('hide.bs.modal', function () {
        if (barChart != null && barChart !== undefined) {
            barChart.destroy();
        }


        // if(typeof morefiltersObj.original !== "undefined" && typeof morefiltersObj.price !=="undefined"){
        //     _.isEqual(morefiltersObj.original.price, morefiltersObj.price) ? 0 : arrUsedFilters.add("price");
        // }
        console.log("morefiltersObj.modal.rating:", morefiltersObj.modal.rating);
        console.log("morefiltersObj.modal.stars", morefiltersObj.modal.stars);
        if (typeof morefiltersObj.modal.rating !== "undefined") {
            _.isEqual(morefiltersObj.modal.rating, [0, 5]) ? arrUsedFilters.delete("rating") : arrUsedFilters.add("rating");
        }

        if (typeof morefiltersObj.modal.stars !== "undefined") {
            _.isEqual(morefiltersObj.modal.stars, [1, 5]) ? arrUsedFilters.delete("stars") : arrUsedFilters.add("stars");
        }


        if (typeof morefiltersObj.resort_name !== "undefined") {
            morefiltersObj.resort_name.length > 0 ? arrUsedFilters.add("resort_name") : arrUsedFilters.delete("resort_name");
        }

        if (morefiltersObj.amenities.length > 0) {
            arrUsedFilters.add("amenities");
        } else {
            arrUsedFilters.delete("amenities");
        }

        console.log("morefiltersObj:", morefiltersObj);
        console.log("contFilters:", arrUsedFilters.size);
        console.log("arrUsedFilters:", arrUsedFilters);

        if (arrUsedFilters.size > 0) {
            document.getElementById("contFilters").innerHTML = "(" + arrUsedFilters.size + ")";
            document.getElementById("btnMoreFilters").style.borderColor = "#EA732D";
        } else {
            document.getElementById("contFilters").innerHTML = "";
            document.getElementById("btnMoreFilters").style.borderColor = "#DDD";
        }



    })

    $("#morfiltersModal").on('shown.bs.modal', function () {
        console.log("arrUsedFilters:", arrUsedFilters);

        var markers_in_viewport = featureMap._get_markers_in_viewport();
        //get morefilters total resorts
        tagTotalResorts = document.getElementById('totalResorts');
        tagTotalResorts.innerHTML = markers_in_viewport.length;

        //get all markers visible in viewport
        var markers_in_viewport = featureMap._get_markers_in_viewport();
        console.log("card_cert_obj:", card_cert_obj);
        console.log("price_conversion_rate:", price_conversion_rate);

        //cert + cash
        let arrPrice = [];
        let price_array = [];
        for (let x = 0; x < markers_in_viewport.length; x++) {
            let price = markers_in_viewport[x].minimumPrice;
            arrPrice.push(price)
        }

        if (card_cert_obj !== null && card_cert_obj.amount > 0 && card_cert_obj.type == "certificate") {
            sitio_certificados = true;
        }

        const groupArray = _.groupBy(arrPrice);

        let groupEntries = Object.entries(groupArray);

        let dataY = [];
        let dataX = [];
        for (let x = 0; x < groupEntries.length; x++) {
            dataX.push(parseInt(groupEntries[x][0]));
            dataY.push(groupEntries[x][1].length <= 5 ? groupEntries[x][1].length : 5)
        }

        console.log("dataX", dataX)
        console.log("dataY", dataY)

        let min_price = _.min(dataX);
        let max_price = _.max(dataX);
        console.log("min_price:", min_price)
        console.log("max_price:", max_price)

        morefiltersObj.original = { price: [min_price, max_price] };

        //more filter price slider
        var slider = document.getElementById('slider');

        if (slider != null && slider.noUiSlider === undefined) {
            noUiSlider.create(slider, {
                connect: true,
                behaviour: 'tap',
                step: 1,
                start: [min_price, max_price],
                range: {
                    'min': [morefiltersObj.original.price[0]],
                    'max': [morefiltersObj.original.price[1]]
                },
                format: {
                    from: function (value) {
                        return parseInt(value);
                    },
                    to: function (value) {
                        return parseInt(value);
                    }
                }

            });

            var nodesSlider = [
                document.getElementById('lower-value-price'), // 0
                document.getElementById('upper-value-price')  // 1
            ];

            // Display the slider value and how far the handle moved
            // from the left edge of the slider.
            slider.noUiSlider.on('set', _.debounce(function (values, handle, unencoded, isTap, positions) {
                console.log("morefiltersObj Busqueda", morefiltersObj)
                //featureMap._more_filters(morefiltersObj);
                featureMap._refresh_cluster();
            }, 200));

            slider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                nodesSlider[handle].innerHTML = "$" + values[handle];
                let price0 = price_conversion_rate > 0 ? Math.round(values[0] / price_conversion_rate) : price;
                let price1 = price_conversion_rate > 0 ? Math.round(values[1] / price_conversion_rate) : price;

                if (sitio_certificados && values[0] == 0) {
                    //let cert = price_conversion_rate > 0 ? Math.round(card_cert_obj.amount / price_conversion_rate) : price;
                    let cert = card_cert_obj.amount > 0 ? card_cert_obj.amount : 0;
                    document.getElementById("lower-value-price").innerHTML = "1 Cert."
                    document.getElementById("upper-value-price").innerHTML = "1 Cert. + $" + price1 - cert;

                } else if (sitio_certificados && values[0] > 0) {
                    //let cert = price_conversion_rate > 0 ? Math.round(card_cert_obj.amount / price_conversion_rate) : price;
                    let cert = card_cert_obj.amount > 0 ? card_cert_obj.amount : 0;
                    console.log("cert:", values)
                    if ((price0 - cert) > 0) {
                        document.getElementById("lower-value-price").innerHTML = "1 Cert. + $" + (price0 - cert);
                    } else {
                        document.getElementById("lower-value-price").innerHTML = "1 Cert.";
                    }

                    document.getElementById("upper-value-price").innerHTML = "1 Cert. + $" + (price1 - cert);

                } else {
                    document.getElementById("lower-value-price").innerHTML = "$" + price0;
                    document.getElementById("upper-value-price").innerHTML = "$" + price1;
                }
                morefiltersObj.price = values;
                morefiltersObj.modal.price = values;
            });

            slider.noUiSlider.on('change', _.debounce(function (values, handle, unencoded, isTap, positions) {
                console.log("morefiltersObj.original.price:", morefiltersObj.original.price)
                console.log("morefiltersObj.price:", morefiltersObj.price)
                if (typeof morefiltersObj.original !== "undefined" && typeof morefiltersObj.price !== "undefined") {
                    _.isEqual(morefiltersObj.original.price, morefiltersObj.price) ? arrUsedFilters.delete("price") : arrUsedFilters.add("price");
                }
                if ((morefiltersObj.original.price[0] == morefiltersObj.price[0]) && (morefiltersObj.original.price[1] == morefiltersObj.price[1])) {
                    SnippetMoreFilters.setSliderBan(false);
                } else {
                    SnippetMoreFilters.setSliderBan(true);
                }
            }, 200));



        } else {

            console.log("---------------------");
            console.log(morefiltersObj);
            console.log("---------------------");

            slider.noUiSlider.updateOptions(
                {
                    start: [morefiltersObj.price[0], morefiltersObj.price[1]],
                    range: {
                        'min': [morefiltersObj.original.price[0]],
                        'max': [morefiltersObj.original.price[1]]
                    }
                }, // Object
                true
            );
        }


        priceChart = document.getElementById("priceChart")

        var priceChartData = {
            data: dataY,
            backgroundColor: '#f6d0bd',
        };

        barChart = new Chart(priceChart, {
            type: 'bar',
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display: false
                        }
                    },
                    ],
                    yAxes: [{
                        ticks: {
                            display: false,
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                maintainAspectRatio: false,
                responsive: false
            },
            data: {
                labels: dataX,
                datasets: [priceChartData]
            }
        });


        document.getElementById("priceChart").onclick = function (e) {
            var activePoints = barChart.getElementAtEvent(e);
            var firstPoint = activePoints[0];
            try {
                var label = barChart.data.labels[firstPoint._index];
            }
            catch (e) {
                console.log("click out of the canvas area");
            }

            console.log("===============>", label);

        };

    });


    $("#dropDownRatingSlider").click(function (event) {
        console.log("entra click dropDownRatingSlider")
        event.stopPropagation();
    })

    $("#dropDownRating").on('hide.bs.dropdown', function () {
        $("#rating-caret").removeClass("caret caret-up");
        $("#rating-caret").addClass("caret");

        console.log("morefiltersObj.bar.rating:", morefiltersObj.bar.rating);

        if (typeof morefiltersObj.bar.rating !== "undefined") {
            _.isEqual(morefiltersObj.bar.rating, [0, 5]) ? $("#btnRatingBar").css({ "background": "#FFF", "color": "grey" }) : $("#btnRatingBar").css({ "background": "#EA732D", "color": "#FFF" });
        } else {
            $("#btnRatingBar").css({ "background": "#FFF", "color": "grey" })
        }

    });

    $("#dropDownRating").on('show.bs.dropdown', function () {
        $("#btnRatingBar").css({ "background": "#EA732D", "color": "#FFF" });
        $("#rating-caret").addClass("caret caret-up");

        //top bar guest rating slider
        var ratingSliderBar = document.getElementById('ratingSliderBar');
        console.log("ratingSliderBar--->", ratingSliderBar)
        if (ratingSliderBar != null && ratingSliderBar.noUiSlider === undefined) {
            noUiSlider.create(ratingSliderBar, {
                connect: true,
                behaviour: 'tap',
                start: [0, 5],
                step: 1,
                range: {
                    'min': [0],
                    'max': [5]
                },
                format: {
                    from: function (value) {
                        return parseInt(value);
                    },
                    to: function (value) {
                        return parseInt(value);
                    }
                }

            });



            var nodesRatingBar = [
                document.getElementById('lower-value-rating-bar'), // 0
                document.getElementById('upper-value-rating-bar')  // 1
            ];

            // Display the slider value and how far the handle moved
            // from the left edge of the slider.
            ratingSliderBar.noUiSlider.on('set', _.debounce(function (values, handle, unencoded, isTap, positions) {
                //featureMap._refresh_cluster();
                featureMap._more_filters(morefiltersObj);
                //morefiltersObj.bar.rating = values;
            }, 200));


            ratingSliderBar.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                nodesRatingBar[handle].innerHTML = values[handle];
                morefiltersObj.rating = values;
                morefiltersObj.bar.rating = values;
            });

            // ratingSliderBar.noUiSlider.on('change', _.debounce(function (values, handle, unencoded, isTap, positions) {
            //     morefiltersObj.bar.rating = values;
            // },200));

        }


    })

    $("#dropDownStars").on('hide.bs.dropdown', function () {
        $("#stars-caret").removeClass("caret caret-up");
        $("#stars-caret").addClass("caret");

        console.log("morefiltersObj.bar.stars:", morefiltersObj.bar.stars)
        if (typeof morefiltersObj.bar.stars !== "undefined") {
            _.isEqual(morefiltersObj.bar.stars, [1, 5]) ? $("#btnStarsBar").css({ "background": "#FFF", "color": "grey" }) : $("#btnStarsBar").css({ "background": "#EA732D", "color": "#FFF" });
        } else {
            $("#btnStarsBar").css({ "background": "#FFF", "color": "grey" })
        }



    });

    $("#dropDownStarsSlider").click(function (event) {
        $("#btnStarsBar").css({ "background": "#EA732D", "color": "#FFF" });

        event.stopPropagation();
    })

    $("#dropDownStars").on('show.bs.dropdown', function () {
        $("#btnStarsBar").css({ "background": "#EA732D", "color": "#FFF" });
        $("#stars-caret").addClass("caret caret-up");
        //top bar guest rating slider
        var starsSliderBar = document.getElementById('starsSliderBar');
        console.log("starsSliderBar--->", starsSliderBar)
        if (starsSliderBar != null && starsSliderBar.noUiSlider === undefined) {
            noUiSlider.create(starsSliderBar, {
                connect: true,
                behaviour: 'tap',
                start: [1, 5],
                step: 1,
                range: {
                    'min': [1],
                    'max': [5]
                },
                format: {
                    from: function (value) {
                        return parseInt(value);
                    },
                    to: function (value) {
                        return parseInt(value);
                    }
                }

            });

            var nodesStarsBar = [
                document.getElementById('lower-value-stars-bar'), // 0
                document.getElementById('upper-value-stars-bar')  // 1
            ];

            // Display the slider value and how far the handle moved
            // from the left edge of the slider.
            starsSliderBar.noUiSlider.on('set', _.debounce(function (values, handle, unencoded, isTap, positions) {
                //featureMap._refresh_cluster();
                featureMap._more_filters(morefiltersObj);
                morefiltersObj.bar.stars = values;
            }, 200));


            starsSliderBar.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                nodesStarsBar[handle].innerHTML = values[handle];
                morefiltersObj.stars = values;

            });


        }


    })


    $("#dropDownPriceSlider").click(function (event) {
        event.stopPropagation();
    })

    $("#dropDownPrice").on('hide.bs.dropdown', function () {
        $("#btnPriceBar").css({ "background": "#FFF", "color": "grey" });
        $("#price-caret").removeClass("caret caret-up");
        $("#price-caret").addClass("caret");

        console.log("morefiltersObj.bar.price:", morefiltersObj.bar.price)
        console.log("morefiltersObj.original.price:", morefiltersObj.original.price)
        if (arrUsedFiltersBar.has("price")) {
            //_.isEqual(morefiltersObj.bar.price, morefiltersObj.original.price) ?  $("#btnPriceBar").css({"background": "#FFF", "color":"grey"})  : $("#btnPriceBar").css({"background": "#EA732D", "color":"#FFF"}) ;
            $("#btnPriceBar").css({ "background": "#EA732D", "color": "#FFF" });
        } else {
            $("#btnPriceBar").css({ "background": "#FFF", "color": "grey" })
        }

    });

    $("#dropDownPrice").on('show.bs.dropdown', function () {
        $("#btnPriceBar").css({ "background": "#EA732D", "color": "#FFF" });
        $("#price-caret").addClass("caret caret-up");

        //get all markers visible in viewport
        var markers_in_viewport = featureMap._get_markers_in_viewport();

        let arrPrice = [];
        for (let x = 0; x < markers_in_viewport.length; x++) {
            arrPrice.push(markers_in_viewport[x].minimumPrice)
        }

        const groupArray = _.groupBy(arrPrice);

        let groupEntries = Object.entries(groupArray);

        let dataY = [];
        let dataX = [];
        for (let x = 0; x < groupEntries.length; x++) {
            dataX.push(parseInt(groupEntries[x][0]));
            dataY.push(groupEntries[x][1].length <= 5 ? groupEntries[x][1].length : 5)
        }

        console.log("dataX", dataX)
        console.log("dataY", dataY)


        let min_price = _.min(dataX);
        let max_price = _.max(dataX);
        console.log("min_price:", min_price)
        console.log("max_price:", max_price)

        morefiltersObj.original = { "price": [min_price, max_price] };

        //more filter price slider
        var slider = document.getElementById('priceSliderBar');

        // if(slider != null && slider.noUiSlider !== undefined){
        //     slider.noUiSlider.destroy();
        // }

        if (slider != null && slider.noUiSlider === undefined) {
            noUiSlider.create(slider, {
                connect: true,
                behaviour: 'tap',
                step: 1,
                start: [min_price, max_price],
                range: {
                    'min': [min_price],
                    'max': [max_price]
                },
                format: {
                    from: function (value) {
                        return parseInt(value);
                    },
                    to: function (value) {
                        return parseInt(value);
                    }
                }

            });

            var nodesSlider = [
                document.getElementById('lower-value-price-bar'), // 0
                document.getElementById('upper-value-price-bar')  // 1
            ];

            // Display the slider value and how far the handle moved
            // from the left edge of the slider.
            slider.noUiSlider.on('set', _.debounce(function (values, handle, unencoded, isTap, positions) {
                featureMap._more_filters(morefiltersObj);
            }, 200));

            slider.noUiSlider.on('change', _.debounce(function (values, handle, unencoded, isTap, positions) {
                morefiltersObj.bar.price = values;
                if (typeof morefiltersObj.original !== "undefined" && typeof morefiltersObj.price !== "undefined") {
                    _.isEqual(morefiltersObj.original.price, morefiltersObj.bar.price) ? arrUsedFiltersBar.delete("price") : arrUsedFiltersBar.add("price");
                }
            }, 200));


            slider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                // console.log()
                // nodesSlider[handle].innerHTML = values[handle] ;
                // morefiltersObj.price = values;

                if (card_cert_obj !== null && card_cert_obj.amount > 0 && card_cert_obj.type == "certificate") {
                    sitio_certificados = true;
                }

                console.log("price_conversion_rate:", price_conversion_rate);
                console.log("card_cert_obj:", card_cert_obj);
                nodesSlider[handle].innerHTML = "$" + values[handle];
                let price0 = price_conversion_rate > 0 ? Math.round(values[0] / price_conversion_rate) : price;
                let price1 = price_conversion_rate > 0 ? Math.round(values[1] / price_conversion_rate) : price;
                console.log("price0:", price0);
                console.log("price1:", price1);

                if (sitio_certificados && values[0] == 0) {
                    //let cert = price_conversion_rate > 0 ? Math.round(card_cert_obj.amount / price_conversion_rate) : price;
                    let cert = card_cert_obj.amount > 0 ? card_cert_obj.amount : 0;

                    document.getElementById("lower-value-price-bar").innerHTML = "1 Cert."
                    document.getElementById("upper-value-price-bar").innerHTML = "1 Cert. + $" + price1 - cert;


                    console.log("cert...:", cert);
                } else if (sitio_certificados && values[0] > 0) {

                    //let cert = price_conversion_rate > 0 ? Math.round(card_cert_obj.amount / price_conversion_rate) : price;
                    let cert = card_cert_obj.amount > 0 ? card_cert_obj.amount : 0;
                    //console.log("cert:",values)
                    if ((price0 - cert) > 0) {
                        document.getElementById("lower-value-price-bar").innerHTML = "1 Cert. + $" + (price0 - cert);
                    } else {
                        document.getElementById("lower-value-price-bar").innerHTML = "1 Cert.";
                    }

                    console.log("cert...:", cert);
                    document.getElementById("upper-value-price-bar").innerHTML = "1 Cert. + $" + (price1 - cert);

                } else {
                    document.getElementById("lower-value-price-bar").innerHTML = "$" + price0;
                    document.getElementById("upper-value-price-bar").innerHTML = "$" + price1;
                }
                morefiltersObj.price = values;
            });



        } else {
            slider.noUiSlider.updateOptions(
                {
                    start: [min_price, max_price],
                    range: {
                        'min': [min_price],
                        'max': [max_price]
                    }
                }, // Object
                true
            );
        }


        priceChart = document.getElementById("priceChartBar")

        var priceChartData = {
            data: dataY,
            backgroundColor: '#f6d0bd',
        };

        barChart = new Chart(priceChart, {
            type: 'bar',
            options: {
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                },
                scales: {
                    xAxes: [{
                        ticks: {
                            display: false
                        },
                        gridLines: {
                            display: false
                        }
                    },
                    ],
                    yAxes: [{
                        ticks: {
                            display: false,
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                    }]
                },
                maintainAspectRatio: false,
                responsive: false
            },
            data: {
                labels: dataX,
                datasets: [priceChartData]
            }
        });



    })


    $("#dropDownPrice").on('hide.bs.dropdown', function () {
        barChart.destroy();
    });

    const search = document.getElementById("filter-resort-name");
    const matchList = document.getElementById("match-list");

    //Searche states
    const searchStates = searchText => {
        const states = arrResortName;

        if (searchText.length >= 3) {
            let matches = states.filter(state => {
                const regex = new RegExp(`.*${searchText}.*`, 'gi');
                return state.match(regex);
            })


            if (searchText.length === 0) {
                matches = [];
                //search.innerHTML = "";
                matchList.innerHTML = "";
            }

            console.log("---->", matches)
            outputHml(matches);
        }
    }

    //Show results in HTML
    const outputHml = matches => {
        if (matches.length > 0) {
            const html = matches.map(match => `
                <option>${match}</option>
            `).join("")
            matchList.innerHTML = html;
            console.log(matches)
        }
    }

    if (search != null && typeof search !== "undefinded") {
        search.addEventListener('input', () => searchStates(search.value.trim()));
        search.addEventListener("change", () => {
            morefiltersObj.resort_name = document.getElementById("filter-resort-name").value;
            matchList.innerHTML = "";
            featureMap._refresh_cluster();
        });
    }




});
var _testArrFilters = [];
var arrSeleccionados = [];
var morefiltersObj = { "bar": {}, "modal": {} };
var priceChart = null;
var barChart = null;
var arrResortName = [];
var all_resorts = [];
var sitio_certificados = false;
let arrUsedFilters = new Set();
let arrUsedFiltersBar = new Set();
var feature = {
    _init: function () {
        // 			NProgress.start();
        console.log('feature._init....................................');
        setTimeout(function () {
            NProgress.done();
            $('.fade').removeClass('out');
        }, 1000);

        return this.each(function () {
            var _this = $(this);
            var _buttons = _this.find('[data-role=change],[data-role=submit],input[type="submit"],[data-role="load-modal"]');
            var _remove_btn = _this.find('[data-role="remove"]');
            var _page_redirect = _this.find('[data-role=redirect]');
            var _refresh_section = _this.find('[data-role=refresh]');
            var _select_change = _this.find('select.select-change');
            var _email = _this.find('input[type="email"].validate-email');
            var _card = _this.find('input#card_number');
            var _masked = _this.find('input[data-mask-type]');
            var _fund = _this.find('input[name="fondo"]');
            var _language = _this.find('select[id="language"]');
            var _radio_bonus = _this.find('#bonus input[type="radio"]');
            var _anchor = $(this).find('a[data-anchor]');
            var _captcha = _this.find('.g-recaptcha');
            var _window = $(window);
            var _paginator = _this.find('a[data-role="paginator"],input[name="entries"], select[name="entries"]');
            var _number = _this.find('input[type=number]');
            var _search = _this.find('input[data-role="search"],input[name="entries"]');
            var _autocomplete = _this.find('#autocomplete');
            var _autocomplete_map = _this.find('#autocomplete_map');
            var _autocompletewl = _this.find('#autocompletewl');
            var _picker = _this.find('.form_datetime');
            var _fit_container = _this.find('.fit-content');
            var _lawo = _this.find('.lawo');
            var _search_by = _this.find('.search_by');
            var _wlsearch = _this.find('#wl-search');
            var _checkfilter = _this.find('#addfilt');
            var _filtcancel = _this.find('#filtcancel');
            var _filtlabel = _this.find('.filtlabel');
            var _customfilt = _this.find('.customfilt');
            var _savefilt = _this.find('#savefilt');
            var _aview = _this.find('.log-view');
            var _aremove = _this.find('.log-remove');
            var _aedit = _this.find('.log-edit');
            // var _combobox = _this.find('#combobox');
            // var _logsearch = _this.find('#logSearch');
            // var _logtable = _this.find('#example500');
            // var _validateLogSearch = _this.find('#logSearch');
            // var _retag = _this.find("[name='retag']");
            // var _calce = _this.find("#calce");
            // var _toma = _this.find('.lawo');
            // var _regenerate = _this.find("input[name='regenerate']");





            feature._set_change(_buttons);
            feature._set_refresh(_refresh_section);
            feature._on_change_select(_select_change);
            feature._on_change_email(_email);
            feature._apply_card_validation(_card);
            feature._apply_masked_input(_masked);
            feature._enable_fund(_fund);
            feature._change_language(_language);
            feature._apply_bonus(_radio_bonus);
            feature._apply_anchor(_anchor);
            feature._apply_resize(_captcha);
            feature._on_window_change(_window);
            feature._apply_paginate(_paginator);
            feature._apply_number_change(_number);
            feature._apply_search(_search);
            feature._apply_autocomplete(_autocomplete);
            feature._apply_autocomplete_map(_autocomplete_map);
            feature._apply_autocompletewl(_autocompletewl);
            feature._apply_datepicker(_picker);
            feature._do_widgets(_this);
            feature._do_styles(_fit_container);
            feature._do_redirect(_page_redirect);
            feature._remove(_remove_btn);
            feature._vallawo(_lawo);
            feature._searchCheck(_search_by);
            feature._wlsearch(_wlsearch);
            feature._checkfilter(_checkfilter);
            feature._filtcancel(_filtcancel);
            feature._filtlabel(_filtlabel);
            feature._customfilt(_customfilt);
            feature._savefilt(_savefilt);
            feature._aview(_aview);
            feature._aremove(_aremove);
            feature._aedit(_aedit);
            // feature._combobox(_combobox);
            // feature._logsearch(_logsearch);
            // feature._logtable(_logtable);
            // feature._validateLogSearch(_validateLogSearch);
            // feature._retag(_retag);
            // feature._calce(_calce);
            // feature._toma(_toma);
            // feature._regenerate(_regenerate);
            //feature._resetmorefilters();


            $.ajaxSetup({ headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'), 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'X-Requested-With' } });

        });
    },
    _set_actions: function (element) {
        feature._do_widgets(element);
        feature._set_change(element.find('[data-role=submit],[data-role=change],input[type="submit"],[data-role="load-modal"]'));
        feature._set_refresh(element.find('[data-role=refresh]'));
        feature._on_change_select(element.find('select.select-change'));
        feature._apply_card_validation(element.find('input#card_number'));
        feature._on_change_email(element.find('input[type="email"].validate-email'));
        feature._apply_masked_input(element.find('input[data-mask-type]'));
        feature._enable_fund(element.find('input[name="fondo"]'));
        feature._apply_bonus(element.find('#bonus input[type="radio"]'));
        feature._apply_resize(element.find('.g-recaptcha'));
        feature._apply_paginate(element.find('a[data-role="paginator"],input[name="entries"], select[name="entries"]'));
        feature._apply_search(element.find('input[data-role="search"],input[name="entries"]'));
        feature._apply_number_change(element.find('input[type=number]'));
        feature._do_widgets(element);
        feature._do_styles(element.find('.fit-content'));
        feature._do_redirect(element.find('[data-role=redirect]'));
        feature._remove(element.find('[data-role="remove"]'));
        feature._apply_autocomplete(element.find('#autocomplete'));
        feature._apply_autocompletewl(element.find('#autocompletewl'));
        feature._apply_datepicker(element.find('.form_datetime'));
        feature._vallawo(element.find('.lawo'));
        feature._searchCheck(element.find('.search_by'));
        feature._wlsearch(element.find('#wl-search'));
        feature._checkfilter(element.find('#addfilt'));
        feature._filtcancel(element.find('#filtcancel'));
        feature._filtlabel(element.find('.filtlabel'));
        feature._customfilt(element.find('.customfilt'));
        feature._savefilt(element.find('#savefilt'));
        feature._aview(element.find('.log-view'));
        feature._aremove(element.find('.log-remove'));
        feature._aedit(element.find('.log-edit'));
        // feature._combobox(element.find('#combobox'));
        // feature._logsearch(element.find('#logSearch'));
        // feature._logtable(element.find('example500'));
        // feature._validateLogSearch(element.find('#logSearch'));
        // feature._retag(element.find("[name='retag']"));
        // feature._calce(element.find("#calce"));
        // feature._toma(element.find('.lawo'));
        // feature._regenerate(element.find("input[name='regenerate']"));



        feature._hover_flag = false;

        $('a[data-event="hover"]').hover(function () {
            var target = $(this).data('target');
            $(target).modal('show');

            $(target + ' .modal-wrapper').mouseenter(function () {

                $(this).mouseleave(function () {
                    $(target).modal('hide');
                });

            });

            $(target).draggable({
                handle: ".modal-wrapper"
            });
            $('.modal-backdrop').css('display', 'none');

        });

    },

    _do_redirect: function (a) {
        /*a.bind('click', function( e ){
        //$(document).on('click', a, function(){
            NProgress.start();
            // setTimeout(function() { NProgress.start(); }, 1000);

            var _this = $(this);
            var _target = _this.data('target');

            var _route = _this.data('route');

            if(!_route)
                _route = _this.attr('href');

            feature._redirect( _route );
            e.preventDefault();
        });*/
    },
    _wlsearch: function (element) {
        element.click(function () {
            $("#form_wl_search").submit();
        });
    },
    _logtable: function (element) {
        $(document).ready(function () {
            $('#example500').DataTable({
                searching: false,
                information: false,
                ordering: false,
                "dom": '<"top">rt<"bottom"flp><"clear">'
            });
        });
    },
    _validateLogSearch: function (element) {
        var count = $('#alltags > span').length;
        var par = $('.tagsfilter').find('#logSearch');
        countTags(count, par);
    },
    _search_by: function (element) {
        $(document).on('change', '.search_by', function () {
            var filter_by = $('.search_by').val();
            feature._check_search(filter_by);
        });
    },
    _check_search: function (filter_by) {
        if (filter_by == 'destination') {
            if ($('#autocomplete').hasClass('hidden')) {
                $('#autocomplete').removeClass('hidden')
            }
            $('#autocomplete_all').val('');
            $('#autocomplete_all').hide();
            $('#autocompletewl').val('');
            $('#autocompletewl').hide();
            $('#autocomplete').show();

        }
        if (filter_by == 'resort') {
            if ($('#autocompletewl').hasClass('hidden')) {
                $('#autocompletewl').removeClass('hidden')
            }
            $('#autocomplete_all').val('');
            $('#autocomplete_all').hide();
            $('#autocomplete').val('');
            $('#autocomplete').hide();
            $('#autocompletewl').show();
        }

        if (filter_by == '') {
            /* if ($('#autocomplete_all').hasClass('hidden')) {
                 $('#autocomplete_all').removeClass('hidden')
             }

             $('#autocomplete').val('');
             $('#autocomplete').hide();
             $('#autocompletewl').val('');
             $('#autocompletewl').hide();
             $('#autocomplete_all').show();*/


        }

    },
    _regenerate: function (element) {
        $(document).ready(function () {
            if ($("input[name='regenerate']").val()) {
                $("input[name='regenerate']").val(1);
            }
        });
    },
    _retag: function (element) {
        $(document).ready(function () {
            if ($("[name='retag']").val()) {
                var wea = $("[name='retag']").val();
                var b = Array();
                var c = Array();
                var keyword_filter = '';
                var type_filter = '';
                var are_filter = 0;
                //first phase

                var a = wea.split('&');

                $.each(a, function (index, value) {
                    var e = value.split('=');
                    b.push(e);
                });
                if (wea != 'none' || wea == '') {
                    for (var i = 0; i < b.length; i++) {
                        //NOTE:  keyword_filter b[i][1];
                        //NOTE :g[0] = type_filter;
                        //NOTE :g[1] = are_filter;
                        var g = b[i][0].split('[');
                        if (g[1] == 'eq]') {
                            are_filter = 1;
                        }
                        var serializedArr = '[{"name":"type_filter","value":"' + g[0] + '"},{"name":"are_filter","value":"' + are_filter + '"},{"name":"keyword_filter","value":"' + b[i][1] + '"}]';
                        var tag = "<span id='filter" + i + "' class='badge bgsize row' style='background-color: #2a65b7!important; margin-left:18px;'><div id='label" + i + "'><strong>" + g[0] + "</strong></div><div id='cust" + i + "'><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-eye-open log-view'></i></a><a><span style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-edit log-edit'></span></a><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-trash log-remove'></i></a></div><input type='hidden' name='keywordsdata' value='" + serializedArr + "'></span>";
                        $('#alltags').append(tag);
                        $('#label' + i).addClass('filtlabel');
                        $('#cust' + i).addClass('customfilt');

                    }
                }
            }
        });
    },
    _logsearch: function (element) {
        $(document).on('click', '#logSearch', function () {
            var largo = $('#alltags > span').length;
            var jsoninfo = new Array();
            var apiinfo = new Array();
            for (var i = 0; i < largo; i++) {
                var j = i + 1;
                var info = $('#filter' + j).find("[name='keywordsdata']").val();
                info = JSON.parse(info);
                if (info[1]['value'] == 0) {
                    info[1]['value'] = '[ne]';
                } else {
                    info[1]['value'] = '[eq]';
                }
                jsoninfo[i] = info[0]['value'] + info[1]['value'] + ":'" + info[2]['value'] + "'";
                apiinfo[i] = info[0]['value'] + info[1]['value'] + "=" + info[2]['value'];
            }


            $("[name='datacontent']").val(apiinfo);
            var w = $("[name='datacontent']").val();
            var rtr = w + ',';
            var rtr = w.split(",").join("&");
            $("[name='datacontent']").val(rtr);
            var url = window.location.origin;
            rtr = $("[name='datacontent']").val();
            var complete = url + '/admin/log-review?' + rtr;

            window.location.href = complete;

        });
    },
    _calce: function (element) {
        $(element).change(function () {
            //console.log(element.val());
            if (element.val() == 'fixed') {
                $('.lawo').attr('placeholder', 'e.g 50');
            } else {
                $('.lawo').attr('placeholder', 'e.g +50');
            }
        });
    },
    _savefilt: function (element) {
        $(element).click(function () {

            var datafilter = $("[name='type_filter'],[name='are_filter'],[name='keyword_filter']").serializeArray();
            var serializedArr = JSON.stringify($("[name='type_filter'],[name='are_filter'],[name='keyword_filter']").serializeArray());
            var name = $("[name='tag_filter']").val();
            if (name == '') { name = $("[name='type_filter'] option:selected").text(); }

            if ($("[name='idfiltervalue']").val() == '') {
                var num = $('#alltags > span').length;
                if (num == 0) {
                    var filnum = num + 1;
                } else {
                    var aidi = $('#alltags > span:last').attr('id');
                    var numbers = aidi.match(/\d+/g).map(Number);
                    //console.log(numbers[0]);
                    var filnum = numbers[0] + 1;
                }
                var tag = "<span id='filter" + filnum + "' class='badge bgsize row' style='background-color: #2a65b7!important; margin-left:18px;'><div id='label" + filnum + "'><strong>" + name + "</strong></div><div id='cust" + filnum + "'><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-eye-open log-view'></i></a><a><span style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-edit log-edit'></span></a><a><i style='color:white; margin-left:5px; margin-right:5px;' class='glyphicon glyphicon-trash log-remove'></i></a></div><input type='hidden' name='keywordsdata' value='" + serializedArr + "'></span>";
                $('#alltags').append(tag);
                $('#label' + filnum).addClass('filtlabel');
                $('#cust' + filnum).addClass('customfilt');
            } else {
                var serch = $("[name='idfiltervalue']").val();
                $('#' + serch).find("[name='keywordsdata']").val('');
                $('#' + serch).find("[name='keywordsdata']").val(serializedArr);
                $('#' + serch).find("strong").text('');
                $('#' + serch).find("strong").text(name);
            }
            $("[name='type_filter'],[name='keyword_filter'],[name='tag_filter'],[name='idfiltervalue']").val('');
            $('#combobox').val('').trigger('change');
            $('#filterdata').hide();

            $('#logSearch').attr('disabled', false);
            $('#logSearch').css('background-color', '#465664!important');
            $('#logSearch').css('cursor', 'pointer');

        });
    },
    _aedit: function (element) {
        $(document).on('click', '.log-edit', function () {
            var a = $(this).parent().parent().parent().attr('id');
            var c = $('#' + a).find("[name='keywordsdata']").val();
            var name = $('#' + a).find('strong').text();
            c = JSON.parse(c);
            $("[name='type_filter']").val(c[0]['value']);
            $("[name='type_filter'],#combobox").val(c[0]['value']).trigger('change');
            $("[name='are_filter']").val(c[1]['value']);
            $("[name='keyword_filter']").val(c[2]['value']);
            $("[name='idfiltervalue']").val(a);
            if (name != c[0]['value']) {
                $("[name='tag_filter']").val(name);
            }
            $('#filterdata').show();
        });
    },
    _aview: function (element) {
        $(document).on('click', '.log-view', function () {
            var a = $(this).parent().parent().parent().attr('id');
            var c = $('#' + a).find("[name='keywordsdata']").val();
            var name = $('#' + a).find('strong').text();
            c = JSON.parse(c);
            $("[name='type_filter'],#combobox,js-example-basic-single").val(c[0]['value']);
            $("[name='type_filter'],#combobox").val(c[0]['value']).trigger('change');
            $("[name='are_filter']").val(c[1]['value']);
            $("[name='keyword_filter']").val(c[2]['value']);
            if (name != c[0]['value']) {
                $("[name='tag_filter']").val(name);
            }
            $('#savefilt').hide();
            $("[name='keyword_filter'],[name='tag_filter']").attr('readonly', true);
            $("[name='type_filter'],[name='are_filter']").attr('disabled', true);
            $('#filtcancel').val('Close');
            $('#filtcancel').css('background-color', '#465664!important');
            $('#filtcancel').css('color', 'white');
            $('#filterdata').show();
        });
    },
    _aremove: function (element) {
        $(document).on('click', '.log-remove', function () {
            var a = $(this).parent().parent().parent().attr('id');
            $('#' + a).remove();
            var count = $('#alltags > span').length;
            var par = $('.tagsfilter').find('#logSearch');
            countTags(count, par);
        });
    },
    _filtlabel: function (element) {
        $(document).on('mouseenter', '.filtlabel', function () {
            $(this).css('transition-duration', '0.5s');
            $(this).hide();
            $(this).siblings('.customfilt').show();
        });
    },
    _customfilt: function (element) {
        $(document).on('mouseleave', '.customfilt', function () {
            $(this).css('transition-duration', '0.5s');
            $(this).hide();
            $(this).siblings('.filtlabel').show();
        });
    },
    _do_styles: function (p) {
        $.each(p, function () {
            var _this = $(this);
            var _thumbs = _this.find('.fit-in');
            var _height = _this.height();
            _thumbs.css('min-height', _this.height());
        });
    },
    _do_widgets: function (p) {
        $.each(p.find('table, :input, a, span'), function () {
            var _element = $(this);

            if (_element.is('input[type="file"]')) {
                feature._preview_input(_element);
            } else if (_element.is('span.pick')) {
                feature._pick_file(_element);
            }
        });
    },
    _checkfilter: function (element) {
        $(element).click(function () {
            if ($('#filterdata').is(":visible")) {
                $("[name='type_filter'],[name='keyword_filter'],[name='tag_filter'],[name='idfiltervalue']").val('');
                $("[name='type_filter'],#combobox").val('').trigger('change');
                $('#filterdata').hide();
            } else { $('#filterdata').show(); }
        });
    },
    _filtcancel: function (element) {

        $(document).on('click', '#filtcancel', function () {

            $("[name='type_filter'],[name='keyword_filter'],[name='tag_filter'],[name='idfiltervalue']").val('');
            $("[name='type_filter'],#combobox").val('').trigger('change');
            if ($(element).val() == 'Close') {
                $("[name='keyword_filter'],[name='tag_filter']").attr('readonly', false);
                $("[name='type_filter'],[name='are_filter']").attr('disabled', false);
                $('#filtcancel').css('background-color', 'white');
                $('#filtcancel').css('color', '#465664!important');
                $('#savefilt').show();
                $('#filtcancel').val('Cancel');
            }
            $('#filterdata').hide();
        });
    },
    _pick_file: function (element) {
        element.unbind().click(function (element) {
            $('input[type=file]').click();
            element.preventDefault();
            return;
        });
    },
    _hover_flag: false,
    _searchCheck: function (element) {
        $(document).on('change', '.search_by', function () {
            var filter_by = $('.search_by').val();
            feature._check_search(filter_by);
        });

    },
    _toma: function (element) {
        $('input[name=amount]').ready(function (element) {
            if ($('input[name=amount]').val()) {
                var w = $('input[name=amount]').val();
                var stuff = w.substring(0, 1);
                if (stuff == '+' || stuff == '-') {
                    $("#calce option[value=fixed]").attr("selected", false);
                    $("#calce option[value=offset]").attr("selected", true);
                } else {
                    $("select option[value=offset]").attr("selected", false);
                    $("select option[value=fixed]").attr("selected", true);
                }
            }
        });
    },
    _vallawo: function (element) {
        element.keypress(function (event) {
            Validate(event);
        });
        element.keyup(function (event) {
            var wea = element.val();
            var stuff = wea.substring(0, 1);
            if (wea = !'' || wea != null) {
                if (stuff > 0) {
                    stuff = 'num';
                }
                switch (stuff) {
                    case '+':
                        $('#price_operation').val('');
                        $('#price_operation').val('add');
                        break;
                    case '-':
                        $('#price_operation').val('');
                        $('#price_operation').val('minus');
                        break;
                    case 'num':
                        $('#price_operation').val('');
                        $('#price_operation').val('fixed');
                        break;
                    default:
                        $('#price_operation').val('');
                }
            }
        });
    },
    _preview_input: function (p) {
        p.unbind().on("change", function (event) {
            var __this = $(this);
            var route = __this.data('route');
            var file = __this.prop('files')[0];
            var __img = __this.siblings().find('img');;
            //console.log(file);
            if (!window.File && !window.FileReader && !window.FileList && !window.Blob) {
                alert('Seems you have an older browser, please update to get the most of Inspira!.');
                return;
            } else {
                var rd = new FileReader();
                rd.onload = function (e) {




                    object = {};
                    object.filename = file.name;
                    object.data = e.target.result;
                    object.temp_path = URL.createObjectURL(event.target.files[0]);



                    NProgress.start();
                    $.ajax({
                        url: route,
                        type: 'POST',
                        data: { filename: object.filename, data: object.data, temp_path: object.temp_path },
                        success: function (_ajax_response, status, xhr) {
                            if (_ajax_response.message) {
                                $('#message #text').html(_ajax_response.message);
                                $('#message').modal('show');
                                $('#message').on('hidden.bs.modal', function () {
                                    feature._redirect(_ajax_response.redirect);
                                });
                                return false;
                            }

                            feature._redirect(_ajax_response.redirect);
                        }
                    });

                    if (file.type == "image/jpeg" || file.type == "image/png") {
                        __img.attr('src', e.target.result);
                        __img.attr('src', e.target.result).fadeOut();
                        __img.attr('src', e.target.result).fadeIn();

                        __img.addClass('img-select').imgAreaSelect({
                            aspectRatio: '1:1',
                            onSelectEnd: function (img, selection) {
                                if (!selection.width || !selection.height) {
                                    return;
                                }
                                $('#thumbnail_x1').val(selection.x1);
                                $('#thumbnail_y1').val(selection.y1);
                                $('#thumbnail_x2').val(selection.x2);
                                $('#thumbnail_y2').val(selection.y2);
                                $('#thumbnail_width').val(selection.width);
                                $('#thumbnail_height').val(selection.height);
                                $('#img_width').val(__img.width());
                                $('#img_height').val(__img.height());
                                $('#image').val(e.target.result);
                            },
                            onInit: function () {
                                $('#thumbnail_x1').val(0);
                                $('#thumbnail_y1').val(0);
                                $('#thumbnail_x2').val(__img.width());
                                $('#thumbnail_y2').val(__img.height());
                                $('#thumbnail_width').val(__img.height());
                                $('#thumbnail_height').val(__img.height());
                                $('#img_width').val(__img.width());
                                $('#img_height').val(__img.height());
                                $('#image').val(e.target.result);
                            }
                        });


                    }


                };

                rd.onloadend = function (e) {
                    NProgress.done();
                }
                rd.readAsDataURL(file);
                event.preventDefault();
            }
        });

    },
    _apply_datepicker: function (element) {
        var format = $('#date_format').val();
        var date_format = typeof format == 'string' ? format : 'mm/dd/yyyy';

        element.datepicker({ format: date_format, pickTime: false, autoclose: true, startDate: '-1d', clearBtn: true });
    },
    _apply_autocompletewl: function (element) {

        if (element.length > 0) {
            var _this = $(this);
            var _route = _this.data('route');
            var _container = element.data('container');
            var _ul = [];

            var formatName = function (name, parent, city) {
                return toTitle(name);
                if (typeof parent == 'undefined') {
                    if (typeof city == 'undefined')
                        return toTitle(name);
                    return toTitle(city) + ' / ' + toTitle(name);
                } else {
                    return toTitle(name) + ' / ' + toTitle(parent);

                }
            };

            var toTitle = function (str) {
                return str;
                return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

            }

            var selected = false;
            var _del = 0;
            $(element).autocomplete({
                source: function (request, response) {
                    $.ajax({
                        url: "/admin/wl/search",
                        dataType: "json",
                        data: {
                            term: request.term
                        },
                        success: function (data) {
                            response(data);
                        }
                    });
                },
                minLength: 1,
                appendTo: typeof _container == 'string' ? $(_container).children(":first") : 'body',
                messages: {
                    noResults: function (count) {
                        $('.destination-input').find('i').removeAttr('class').html('').hide();
                        $('.ui-helper-hidden-accessible').text('Sorry, no resorts in that area.').removeAttr('class');

                    },
                    results: function (items) {
                        $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                    }
                },
                select: function (event, ui) {
                    var response_box = $(this).parents('[data-role="response"]').last();
                    selected = true;
                    //poner el id, y categoria en un hidden.
                    response_box.find('input[name=destination_id]').val(ui.item.resort_id);
                    response_box.find('input[name=category]').val('resort');
                    ui.item.value = formatName(ui.item.resort_name);
                    $('.destination-input').find('i').attr('class', 'resort').html('RESORT').show();
                },
                autoSelect: true,
                autoFocus: true,
                html: true,
                response: function (event, ui) {
                    var response_box = $(this).parents('[data-role="response"]').last();
                    selected = false;

                    var first = ui.content[0];
                    if (first) {
                        response_box.find('input[name=destination_id]').val(first.resort_id);
                        response_box.find('input[name=category]').val('resort');
                    } else {
                        response_box.find('input[name=destination_id]').val('');
                        response_box.find('input[name=category]').val('');
                    }
                }
            }).bind('keyup', function (e) {
                var response_box = $(this).parents('[data-role="response"]').last();
                response_box.find('input:not(#search-switch):checkbox').removeAttr('checked');
                if (e.keyCode == 46 || e.keyCode == 8) { _del++; } else { _del = 0; }

                if (this.value == '' || _del >= 1) {
                    response_box.find('input[name=destination_id]').val('');
                    response_box.find('input[name=category]').val('');

                    $('.destination-input').find('i').removeAttr('class').html('').hide();
                    $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                } else if (e.keyCode != 46 || e.keyCode != 8) {
                    $('.destination.ui-autocomplete-input').trigger("keydown", { which: 39 });
                }
            }).data("ui-autocomplete")._renderItem = function (ul, item) {
                _ul.push(item);
                return $("<li></li>")

                    .data("ui-autocomplete-item", item)

                    .append("<a><i class='resort'>RESORT</i>" + formatName(item.resort_name) + "</a>")

                    .appendTo(ul);
            };
        }
    },
    _apply_autocomplete_map: function (element) {

        if (element.length > 0) {
            $(element).bind('click', function (e) {
                console.log('click on destinations: ');

                if (typeof (is_logged) == 'undefined') {
                    is_logged = true;
                }

                if (is_logged) {
                    if (!transform_to_map_search && typeof map_thumb === "undefined") {
                        transform_to_map_search = true;
                        //track state
                        title = "Map Search";
                        var urlPath = '_map_search';
                        History.pushState({ path: urlPath, element: [] }, title, './?page=' + urlPath);
                    }
                }
                else {
                    document.querySelector('.login-button').click();
                }


            });
        }
    },
    _apply_autocomplete: function (element) {

        if (element.length > 0) {
            var _this = $(this);
            //element.find('#autocompletewl')
            var _route = _this.data('route');
            var _container = element.data('container');
            var _ul = [];

            var formatName = function (name, parent, city) {
                return toTitle(name);
                if (typeof parent == 'undefined') {
                    if (typeof city == 'undefined')
                        return toTitle(name);
                    return toTitle(city) + ' / ' + toTitle(name);
                } else {
                    return toTitle(name) + ' / ' + toTitle(parent);

                }
            };

            var toTitle = function (str) {
                return str;
                return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });

            }

            var selected = false;
            var _del = 0;
            var destinationscache = {};
            $(element).autocomplete({
                delay: 80,
                source: function (request, response) {
                    if (!(request.term in destinationscache)) {
                        destinationscache[request.term] = $.ajax({
                            url: "/destination/search",
                            dataType: "json",
                            data: {
                                term: request.term
                            }
                            /*,
                                                          success: function( data ) {
                                                            response( data );
                                                        }*/
                        });
                    }
                    destinationscache[request.term].done(function (json) {
                        response(json);
                    });

                },
                minLength: 1,
                appendTo: typeof _container == 'string' ? $(_container).children(":first") : 'body',
                messages: {
                    noResults: function (count) {
                        $('.destination-input').find('i').removeAttr('class').html('').hide();
                        $('.ui-helper-hidden-accessible').text('Sorry, no resorts in that area.').removeAttr('class');

                    },
                    results: function (items) {
                        $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                    }
                },
                select: function (event, ui) {
                    var response_box = $(this).parents('[data-role="response"]').last();
                    selected = true;
                    //poner el id, y categoria en un hidden.
                    response_box.find('input[name=destination_id]').val(ui.item.destination_id);
                    response_box.find('input[name=category]').val(ui.item.category);
                    ui.item.value = formatName(ui.item.destination_name, ui.item.parent_city);
                    $('.destination-input').find('i').attr('class', ui.item.category).html(ui.item.category).show();
                },
                autoSelect: true,
                autoFocus: true,
                html: true,
                response: function (event, ui) {
                    var response_box = $(this).parents('[data-role="response"]').last();
                    selected = false;

                    var first = ui.content[0];
                    if (first) {
                        response_box.find('input[name=destination_id]').val(first.destination_id);
                        response_box.find('input[name=category]').val(first.category);
                    } else {
                        response_box.find('input[name=destination_id]').val('');
                        response_box.find('input[name=category]').val('');
                    }
                }
            }).bind('keyup', function (e) {
                var response_box = $(this).parents('[data-role="response"]').last();
                response_box.find('input:not(#search-switch):checkbox').removeAttr('checked');
                if (e.keyCode == 46 || e.keyCode == 8) { _del++; } else { _del = 0; }

                if (this.value == '' || _del >= 1) {
                    response_box.find('input[name=destination_id]').val('');
                    response_box.find('input[name=category]').val('');

                    $('.destination-input').find('i').removeAttr('class').html('').hide();
                    $('span[role="status"]').addClass('ui-helper-hidden-accessible');
                } else if (e.keyCode != 46 || e.keyCode != 8) {
                    $('.destination.ui-autocomplete-input').trigger("keydown", { which: 39 });

                    // $.ajax({url:'/google-suggestion', data: { 'param' : $(this).val() } ,  type: 'POST'}).done(function(_ajax_response){
                    // 	if(_ajax_response.word.length > 0 ){
                    // 		$('.destination.ui-autocomplete-input').val(_ajax_response.word).trigger("enterKey");;
                    // 		$('.destination.ui-autocomplete-input').trigger("keydown", {which: 39});
                    // 	}
                    // });
                }
            })
                .data("ui-autocomplete")._renderItem = function (ul, item) {
                    _ul.push(item);
                    return $("<li></li>")

                        .data("ui-autocomplete-item", item)

                        .append("<a><i class=" + item.category + ">" + item.category + "</i>" + formatName(item.destination_name, item.parent_city, item.city_in) + "</a>")

                        .appendTo(ul);
                };
        }
    },
    _apply_search: function (element) {
        element.on('keyup', function (evnt) {

            var _this = $(this);
            var _response = _this.closest('div[data-role=response]');

            var _value = _this.closest('input[name=search]').val();
            var _entries = _response.find('[name=entries]').val();

            var _form = _this.parents('form');
            var _route = _this.data('route');
            var _append = (typeof _this.data('append') == 'string') ? _this.data('append') : '#' + _this.closest('div.modal').attr('id');
            var _data = {};

            if (evnt.keyCode == 13) {

                _data = { 'search': _value, 'entries': _entries };

                NProgress.start();
                $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {

                    if (_ajax_response.html) {
                        if (_append == 'no-modal') {
                            $(_response).html(feature._htmlDecode(_ajax_response.html));
                            feature._set_actions(_response);
                        } else {

                            $(_append + ' div.modal-wrapper').html(feature._htmlDecode(_ajax_response.html));
                            $(_append).modal('show');
                            feature._set_actions($(_append + ' div.modal-wrapper'));
                        }

                        NProgress.done();

                        return false;
                    }
                });

            }
        });
    },
    _apply_paginate: function (element) {

        element.each(function () {
            // console.log($(this).attr('type'));
            var bind_event = 'change';
            if (!$(this).is("select")) {
                bind_event = 'click';
            }

            $(this).bind(bind_event, function (evnt) {
                evnt.stopPropagation();
                var _this = $(this);
                var _response = _this.closest('div[data-role=response]');
                var _form = _this.parents('form');
                var _route = _this.data('route');
                var _append = (typeof _this.data('append') == 'string') ? _this.data('append') : '#message';

                var _data = { 'entries': _this.attr('name') == 'entries' ? _this.find("option:selected").val() : 10 };

                NProgress.start();
                $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {

                    if (_ajax_response.html) {

                        if (_append == 'no-modal') {
                            $(_response).html(feature._htmlDecode(_ajax_response.html));
                            NProgress.done();
                            feature._set_actions(_response);
                            return false;
                        }

                        if (_append == 'response') {
                            $(_response).html(feature._htmlDecode(_ajax_response.html));
                            feature._set_actions($(_response));
                            NProgress.done();
                        } else {
                            $(_append + ' div.modal-wrapper').html(feature._htmlDecode(_ajax_response.html));
                            $(_append).modal('show');
                        }

                        feature._set_actions($(_append + ' div.modal-wrapper'));
                        NProgress.done();

                        return false;
                    }
                });

            });
        });
    },
    _on_window_change: function (element) {
        if ($('#captcha').length > 0) {
            element.on('resize', function () {
                location.reload();
            });

            element.on('orientationchange', function (evt) {
                location.reload();
            });
        }
    },
    _apply_resize: function (element) {
        var width = element.parent().width();

        var scale = width / 302;

        var margin = (width - 302);


        if (scale < 1) {
            element.css('transform', 'scale(' + scale + ')');
            element.css('-webkit-transform', 'scale(' + scale + ')');
            element.css('transform-origin', '0 0');
            element.css('-webkit-transform-origin', '0 0');
        } else {
            var margin2 = (margin / 2);
            element.css('margin-left', margin2 + 'px');
        }
    },
    _interval: '',
    _set_refresh: function (element) {
        $.each(element, function () {
            var _this = $(this);

            var _response = _this.closest('div[data-role=response]');
            var _append = '#almostExpired';
            var _route = _this.data('route');
            var _data = {};

            var ajaxRequest = function () {
                $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {
                    if (_ajax_response.html) {
                        _response.html(feature._htmlDecode(_ajax_response.html));
                        if (_ajax_response.redirect) {
                            var _body = $(_append + ' div.modal-wrapper');

                            _body.html(feature._htmlDecode(_ajax_response.message));

                            $('#almostExpired').modal('show');
                            feature._set_actions(_body);



                            // 									feature._set_actions( _body );

                            /*
                                                            $(_append).on('show.bs.modal', function () {
                                                                $('#almostExpired').modal('show');
                                                                console.log('Cleared?');
                                                            });
                            */

                            /*
                                                            if(!feature._hover_flag){ $(_append).modal('show'); feature._hover_flag = true; }
                                                                $(_append).on('hidden.bs.modal', function () {
                                                                    console.log('hidden?');
                                                                    feature._hover_flag = true;
                                                                    window.location.href = '#';
                                                                });

                                                                $(_append).on('show.bs.modal', function () {
                                                                    clearInterval(feature._interval);
                                                                    console.log('Cleared?');
                                                                });*/
                        }

                        feature._set_change(_response.find('a[data-role="load-modal"]'));
                        //	$('.modal-backdrop').remove();
                        // 								feature._set_actions( _response );
                    } else {
                        if (_ajax_response.redirect) {
                            feature._redirect(_ajax_response.redirect);
                        }
                    }

                }).fail(function () {
                    $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function (_ajax_response) {
                        $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                        $('#message').modal('show');
                    });
                });
            }

            var now = new Date();
            var delay = 15 * 1000; // 1 min in msec
            var start = delay - (now.getSeconds()) * 1000 + now.getMilliseconds();


            setTimeout(function () {
                setInterval(ajaxRequest, delay);
                ajaxRequest();

            }, start);
        });
    },
    _set_change: function (element) {
        $.each(element, function () {
            var _this = $(this);
            var _on = 'click';

            if (_this.is('select')) {
                _on = 'change';
            }

            if (_this.attr('data-event')) {
                if (_this.attr('data-event') == 'wallet') {
                    _on = 'change';
                } else {
                    _on = 'click, ' + _this.data('event');
                }
            }

            _this.bind(_on, function (e) {
                var _this = $(this);

                var _response = _this.closest('div[data-role=response]');

                //var _form = _response.parents('form');
                var _form = _this.parents('form');



                var _route = _this.data('route');
                var _append = (typeof _this.data('append') == 'string') ? _this.data('append') : '#message';
                var _body = '';
                _this.attr('data-route', _route);
                var _data = {};

                if (typeof _route != 'string') {
                    if (_form.attr('method') == "GET") {
                        feature._redirect(_form.attr('action') + '?' + _form.serialize());
                    } else {
                        _form.submit();
                    }
                    return;
                } else {
                    _form.submit(function (e) {
                        e.preventDefault();
                        return;
                    });
                }

                e.preventDefault();

                if (_this.attr('data-event') == 'change') {
                    _data = { 'data': _this.val() };
                }


                if (typeof _form[0] == 'object') {
                    _data = _form.serialize();
                }

                if (_this.data('inner') == 'response') {
                    _data = $(_response).find('input, select').serialize();
                }

                if (_this.data('inner') == 'append') {
                    _body = _response.first().find('[data-role="append"]').first();
                    _data = $(_body).find('input, select').serialize();
                }

                if (!feature._hover_flag) {
                    NProgress.start();

                    $('.modal-backdrop, .modal').css('z-index: -1; display:none;');

                    // 						_this.removeAttr('data-role');

                    if (_this.data('pass')) {
                        _form.submit();
                    }

                    feature._hover_flag = true;
                    $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {
                        // ByMC TM: for paypal button
                        if (_this.hasClass('btn-payment')) {
                            if (!_ajax_response.error) {
                                //first do confirm. then seldf_form
                                var ppl = document.getElementsByName('paypal_form');
                                //console.log(ppl);
                                ppl[0].submit();

                                return false;
                            }
                        }


                        if (typeof _ajax_response == 'string') {
                            NProgress.done();
                            _response.html(_ajax_response);
                            feature._set_actions(_response);
                            return;
                        }

                        if (_ajax_response.redirect) {

                            $('.modal').modal('hide');

                            if (_ajax_response.html) {
                                NProgress.done();

                                if (_append != '#message') {
                                    if (_append == 'response') {
                                        _body = _this.parents("[data-role=response]").first().find('[data-role="append"]').first();

                                        _body.append(feature._htmlDecode(_ajax_response.html));

                                        feature._set_actions(_body);
                                        return;
                                    } else {
                                        _body = $(_append + ' div.modal-wrapper');
                                    }
                                } else
                                    _body = $(_append + ' div[class=modal-body]');



                                _body.html(feature._htmlDecode(_ajax_response.html));
                                //$(_append+' div.modal-wrapper').html(htmlDecode(_ajax_response.html));
                                $(_append).modal('show');

                                $(_append).on('hidden.bs.modal', function () {
                                    feature._redirect(_ajax_response.redirect);
                                });

                                if (_append == '#loadModal') {
                                    if (_this.data('draggable') == true) {
                                        $(_append).draggable({
                                            handle: ".modal-wrapper"
                                        });
                                        $('.modal-backdrop').css('display', 'none');
                                    }
                                }


                                feature._set_actions(_body);
                                //feature._set_actions($(_append+' div.modal-wrapper'));

                                return false;
                            } else if (_ajax_response.message) {
                                //Modal
                                NProgress.done();

                                $('#message #text').html(_ajax_response.message);
                                $('#message').modal('show');
                                $('#message').on('hidden.bs.modal', function () {
                                    feature._redirect(_ajax_response.redirect);
                                });

                            } else {

                                if (_route.indexOf("#stripe") != -1) {
                                    NProgress.done();

                                    $('#customButton').attr('data-route', _ajax_response.redirect);
                                    $('#customButton').click();
                                } else {
                                    feature._redirect(_ajax_response.redirect);
                                }


                            }
                        }

                        feature._set_actions(_response);
                    }).fail(function () {
                        feature._hover_flag = false;

                        $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function (_ajax_response) {
                            $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                            $('#message').modal('show');
                            NProgress.done();
                        });
                    });
                }
            });
        });
    },
    _on_change_select: function (element) {
        element.on('change', function () {
            var _this = $(this);
            var _value = _this.val();
            var _route = _this.data('route');
            var _select_state = _this.data('change');
            var _placeholder = $('.select-' + _select_state).find('input').attr('placeholder');
            var _html = $('<input>').attr({ 'type': 'text', 'name': 'state', 'class': 'form-control', 'placeholder': _placeholder });

            $.ajax({ url: _route, data: { value: _value }, type: 'POST' }).done(function (_response) {
                var _data = _response.data;
                var _option = '';

                if (_response.data) {
                    _option += '<option value="">* State</option>';
                    $.each(_data, function (index, value) {
                        _option += '<option value="' + index + '">' + value + '</option>';
                    });
                    _html = '<select name="' + _select_state + '" class="form-control">' + _option + '</select>';
                }

                $('.select-' + _select_state).html(_html);

                if (_response.zip_code) {
                    stripe_settings.zipCode = _response.zip_code;
                    // document.getElementById('customButton').addEventListener('click', function(e) {
                    //                  handler.open(stripe_settings);
                    //                  e.preventDefault();
                    //                });

                }

            }).fail(function () {
                $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function (_ajax_response) {
                    $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                    $('#message').modal('show');
                    NProgress.done();
                });
            });
        });

    },
    _on_change_email: function (element) {
        element.on('keyup', function () {
            var _this = $(this);
            var _route = _this.data('route');
            var _email = _this.val();
            var _error = _this.parent().find('label.error-db');

            delay(function () {

                $.get(_route, { 'email': _email }, function (response) {
                    var _result = response.data;
                    if (_result.exists == true) {
                        if (typeof _error[0] != 'object') {
                            _this.parent().append('<label class="error-db">' + _result.message + '</label>');
                            _this.on('focus', function () {
                                _error.remove();
                            });

                        }
                    } else {
                        _error.remove();
                    }

                });
            }, 500);

        });
        var delay = (function () {
            var timer = 0;
            return function (callback, ms) {
                clearTimeout(timer);
                timer = setTimeout(callback, ms);
            };
        })();

    },
    _apply_card_validation: function (element) {
        var last_valid = '';
        if (typeof element[0] == 'object') {
            element.validateCreditCard(function (result) {
                if (result.card_type != null) {
                    last_valid = result.card_type.name + " valid";
                    this.addClass(last_valid);
                } else {
                    this.removeClass(last_valid);
                }
            });
        }
    },
    _apply_masked_input: function (element) {
        $.each(element, function () {
            var _this = $(this);

            var _placeholder = _this.data('placeholder');
            var _type = _this.data('mask-type');
            var _mask = '';


            if (_type == 'expiration') {
                _mask = "9999/99";
            } else if (_type == 'date') {
                _mask = "9999/99/99";
            } else if (_type == 'date_format') {
                _mask = "99/99/9999";
            } else if (_type == 'expiry_month') {
                _mask = "99";
            } else if (_type == 'expiry_year') {
                _mask = "9999";
            } else if (_type == 'card_number') {
                _mask = "9999 9999 9999 9999";
            } else if (_type == 'celular') {
                _mask = "(999) 999-9999"
            }

            //console.log(typeof _placeholder);
            if (typeof _placeholder != 'undefined') {
                _this.mask(_mask, { placeholder: _placeholder });
            } else {
                _this.mask(_mask);
            }
        });
    },
    _apply_number_change: function (element) {
        $.each(element, function () {
            var _this = $(this);
            var _route = _this.data('route');
            var _on = _this.data('event');
            var _response = _this.closest('div[data-role=response]');
            /*

                        if(_route){
            */
            _this.on('keyup', function (e) {
                e.preventDefault();
                var _data = {};
                if (e.keyCode == 13) {
                    var _data = { 'value': $(this).val() };

                    if (_on == 'change') {
                        /*
                                                    _data = $( this ).closest('form').serialize();
                                                    $( this ).closest('form').submit(function(e){
                                                        e.preventDefault();
                                                    });
                        */
                        var _form = $(this).closest('form');
                        _data = _form.serialize();

                        _form.submit(function (e) {
                            e.preventDefault();
                            return;
                        });
                    }

                    NProgress.start();

                    $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {
                        NProgress.done();
                        if (_ajax_response.redirect) {
                            window.location = _ajax_response.redirect;
                        } else {
                            _response.html(_ajax_response)
                        }
                        feature._set_actions(_response);
                        return;
                    }).fail(function () {
                        $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function (_ajax_response) {
                            $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                            $('#message').modal('show');
                            NProgress.done();
                        });
                    });
                    return;
                }

            });
            //   }
            return;
        });
    },
    _enable_fund: function (element) {
        element.on('change', function () {
            var _this = $(this);
            var _amount_input = $('input[name="amount"]');
            var _disabled = _this.val() == 1 ? false : true;
            var _value = _disabled == true ? '0.00' : '0.00';
            _amount_input.val(_value);
            _amount_input.attr('placeholder', _value);
            _amount_input.prop('disabled', _disabled);
        });
    },
    _change_language: function (element) {
        element.on('change', function () {
            var _this = $(this);
            var _route = _this.data('route');
            var _response = _this.closest('div[data-role=response]');
            var _form = _this.parents('form');

            var _data = {};

            if (typeof _form[0] == 'object') {
                _data = _form.serialize();
            }

            NProgress.start();
            $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_response) {
                if (!_response.error) {
                    NProgress.done();
                    window.location.href = _response.redirect;
                }
            });

        });
    },
    _apply_bonus: function (element) {
        element.on('change', function () {
            var _this = $(this);
            var _response = _this.closest('div[data-role=response]');
            var _form = _this.parents('form');
            var _route = _form.attr('action');

            if (typeof _form[0] == 'object') {
                _data = _form.serialize();
            }

            if (_route) {
                NProgress.start();

                $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {
                    NProgress.done();
                    _response.html(_ajax_response);
                    feature._set_actions(_response);
                }).fail(function () {
                    $.ajax({ url: '/fails/500/modal', type: 'POST' }).done(function (_ajax_response) {
                        $('#message #text').html(feature._htmlDecode(_ajax_response.html));
                        $('#message').modal('show');
                        NProgress.done();
                    });
                });


            }
        });
    },
    _apply_anchor: function (element) {
        element.on('click', function () {
            var _this = $(this);
            var _name = $(this).data('anchor');
            var _top = $('div.header').height();
            $("html,body").animate({ scrollTop: $("div[data-id=" + _name + "]").offset().top - _top }, "500");
        });
    },
    _remove: function (element) {
        element.on('click', function () {
            var _this = $(this);
            //Section to remove
            $(this).parents('[data-role="response"]').first().remove();
        });
    },
    _redirect: function (redirect) {
        // console.log('redirecting.......');
        if (redirect != "#") {

            var redirect_process = {
                start_progress: function () {
                    NProgress.start();
                    return this;
                },
                redirect_page: function (redirect) {
                    var loc = redirect;

                    var _redirect;

                    _redirect = loc.indexOf('?') >= 0 ? redirect + '&ts=' + Date.now() : redirect + '?ts=' + Date.now();

                    if ($("#tier_page_id").val() != null) {
                        _redirect = _redirect.indexOf('?') >= 0 ? (_redirect + '&tier_id=' + $("#tier_page_id").val()) : (_redirect + '?tier_id=' + $("#tier_page_id").val());
                    }
                    if (_redirect.indexOf("#execute") != -1) {
                        NProgress.done();
                    }
                    setTimeout(function () {
                        window.location.href = _redirect;
                    }, 1000);

                    return this;
                }
            };
            redirect_process.start_progress().redirect_page(redirect);
        } else {
            NProgress.done();
        }
    },
    _htmlDecode: function (input) {
        return $('<div>').html(input).text();
        /*
              var e = document.createElement('div');
              e.innerHTML = input;
              return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
        */
    },
    _everyMinute: function (fn) {
        var now = new Date();
        var delay = 15 * 1000; // 1 min in msec
        var start = delay - (now.getSeconds()) * 1000 + now.getMilliseconds();

        setTimeout(function () {
            setInterval(fn, delay);
            // 			  ajaxRequest;
        }, start);
    },
    _make_request: function (route, data, modal_name) {
        var modal_name = modal_name == null ? null : modal_name;
        $.ajax({ url: route, data: data, type: 'POST' }).done(function (_ajax_response) {

            if (_ajax_response.redirect) {

                if (_ajax_response.html) {
                    NProgress.done();

                    if (typeof modal_name == 'string') {
                        if ($('body').find(modal_name + ' .modal-body')[0]) {
                            $(modal_name + ' .modal-body').html(htmlDecode(_ajax_response.html));
                            $(modal_name).modal('show');
                            $(modal_name).on('hidden.bs.modal', function () {
                                window.location.href = _ajax_response.redirect;
                            });
                            feature._set_actions($(modal_name + ' .modal-body'));
                        } else {
                            $(modal_name).html(htmlDecode(_ajax_response.html));
                            feature._set_actions($(modal_name + ' .modal-body'));
                        }

                        return false;
                    }
                    $('#message div[class=modal-body]').html(htmlDecode(_ajax_response.html));
                    $('#message').modal('show');
                    $('#message').on('hidden.bs.modal', function () {

                        window.location.href = _ajax_response.redirect;
                    });

                    function htmlDecode(input) {
                        var e = document.createElement('div');
                        e.innerHTML = input;
                        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
                    }

                    feature._set_actions($('#message div[class=modal-body]'));

                    return false;
                } else {
                    feature._redirect(_ajax_response.redirect);
                }
            }
        });
    }


    // ,
    // _resetmorefilters: function() {
    //     $('#amenitiesMoreFilters').empty();
    //     arrSeleccionados = [];
    //     featureMap._refresh_cluster();
    // },


    // _combobox : function(element){
    // 	$(document).ready(function() {
    // 			$('.js-example-basic-single').select2();
    // 	});
    // },
};

var valor;
(function ($) {

    $.ajaxSetup({ headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content'), 'Access-Control-Allow-Origin': '*', 'Access-Control-Allow-Headers': 'X-Requested-With' } });

    $.fn.inspira = function (method) {
        if (feature[method]) return feature[method].apply(this, Array.prototype.slice.call(arguments, 1));
        if ("object" === typeof method || !method) return feature._init.apply(this, arguments);
        $.error("Method " + method + " does not exist on jQuery.inspira");
    }

    function Validate(event) {
        var cosa = $('#calce').val();
        var regex = new RegExp("[0-9-+-]");
        if (cosa == 'fixed') {
            regex = new RegExp("[0-9]");
        }
        var key = String.fromCharCode(event.charCode ? event.which : event.charCode);
        if (!regex.test(key) && event.which != 8 && event.which != 0) {
            event.preventDefault();
            return false;
        }
    }

    function checkSearch(filter_by) {
        if (filter_by == 'destination') {
            if ($('#autocomplete').hasClass('hidden')) {
                $('#autocomplete').removeClass('hidden')
            }
            $('#autocomplete').show();
            $('#autocompletewl').val('');
            $('#autocompletewl').hide();
        }
        if (filter_by == 'resort') {
            if ($('#autocompletewl').hasClass('hidden')) {
                $('#autocompletewl').removeClass('hidden')
            }
            $('#autocomplete').val('');
            $('#autocomplete').hide();
            $('#autocompletewl').show();
        }
    }

    function countTags(num, element) {
        if (num == 0) {
            $(element).attr('disabled', true);
            $(element).css('background-color', '#cccccc');
            $(element).css('cursor', 'not-allowed');
        } else {
            $(element).attr('disabled', false);
            $(element).css('background-color', '#465664!important');
            $(element).css('cursor', 'pointer');
        }
    }


    $(document).on('click', '[data-role=redirect]', function (e) {
        NProgress.start();
        // console.log('click on redirect link.......');

        var _this = $(this);
        var _target = _this.data('target');
        var _route = _this.data('route');
        if (!_route) {
            _route = _this.attr('href');
        }

        var _map_open = _this.data('map-open');
        var _to_open_map = _this.data('to-open-map');
        //console.log('map open: ' + _map_open);

        //Only when map is open
        if (typeof _map_open !== "undefined") {
            //save history state
            title = "Map Search";
            var urlPath = '_map_search';
            var map_bounds = encodeURIComponent(JSON.stringify(featureMap._map.getCenter()));
            var bounds = featureMap._map.getBounds();
            var areaBounds = {
                north: bounds.getNorthEast().lat(),
                south: bounds.getSouthWest().lat(),
                east: bounds.getNorthEast().lng(),
                west: bounds.getSouthWest().lng()
            };
            var encoded_area_bounds = encodeURIComponent(JSON.stringify(areaBounds));
            var map_zoom = featureMap._map.getZoom();
            var fullUrl = './?page=' + urlPath + '&bounds=' + map_bounds + '&zoom=' + map_zoom + '&areabounds=' + encoded_area_bounds;
            History.pushState({ path: urlPath, element: [] }, title, fullUrl);

            if (!_route.includes('_map_search')) {
                _route += _route.includes('?') ? '&' : '?';
                _route += 'page=' + urlPath;
            }
            _route += '&bounds=' + map_bounds + '&zoom=' + map_zoom + '&areabounds=' + encoded_area_bounds;

            feature._redirect(_route);
            e.preventDefault();

        } else if (typeof _to_open_map !== "undefined") {

            var _place_geometry = decodeURIComponent(_this.data('place-id'));
            //console.log('_place_geometry: ' + _place_geometry);
            if (typeof _place_geometry !== "undefined") {
                var _place = JSON.parse(_place_geometry);
                //var southwest = new google.maps.LatLng( _place.viewport.southwest.lat, _place.viewport.southwest.lng );
                //var northeast = new google.maps.LatLng( _place.viewport.northeast.lat, _place.viewport.northeast.lng );
                //var bounds = new google.maps.LatLngBounds(southwest, northeast);
                var bounds = new google.maps.LatLngBounds(
                    /* sw */
                    { lat: _place.viewport.south, lng: _place.viewport.west },
                    /* ne */
                    { lat: _place.viewport.north, lng: _place.viewport.east });
                //console.dir(bounds);
                //scroll to top
                $("html, body").animate({ scrollTop: 0 }, "slow");
                featureMap._map_search([]);
                //featureMap._map.fitBounds(bounds);

                //we have to wait until map is loaded
                setTimeout(function () {
                    featureMap._map.fitBounds(bounds);
                }, 1000);
            }
            //var service = new google.maps.places.PlacesService( featureMap._map );

            /*service.getDetails({
                    placeId: _place_id
            }, function(place, status) {
                console.log('getting place....: ');
                console.dir( google.maps.places.PlacesServiceStatus);
              if (status === google.maps.places.PlacesServiceStatus.OK) {
                  console.log('place getted successfully: ' + place );
                  var place_obj = {
                        bounds: {
                        lat: place.geometry.location.lat,
                        lng: place.geometry.location.lng
                    },
                    zoom: 6
                  };
                  var attr_array = [];
                  attr_array.push ( place_obj );
                  featureMap._map_search( attr_array );
              }
          });*/
            NProgress.done();
            //console.log('return......');
            e.preventDefault();
            return false;

        } else {
            feature._redirect(_route);
            e.preventDefault();
        }



    });




    $(document).on("click", '#pop_a_view_available_dates', function (event) {
        //console.log('click on available dates....');
        if ($('#pop_available_dates').hasClass('expanded')) {
            TweenMax.to('#pop_available_dates', 1, { x: 0, opacity: 0 }, 0.1);
            TweenMax.to('#pop_available_dates', 3, { className: "-=expanded" });
        } else {
            var resort_id = $(this).attr('rel');
            featureMap._get_available_dates(resort_id, $('#pop_available_dates'));

            var posX = $('.hotel').width();
            var popHeight = $('.hotel').height() - 10;
            $('#pop_available_dates').css('display', 'none');

            TweenMax.set('#pop_available_dates', {
                x: posX,
                opacity: 1,
                className: "+=expanded",
                height: popHeight
            });
            $('#pop_available_dates').fadeIn();


        }
    });


    $(document).on("click", 'a.dates_reserve', function (event) {
        NProgress.start();
        $.ajax({ url: $(this).attr('data-route'), data: { map_view: 1 }, type: 'POST' }).done(function (_ajax_response) {
            NProgress.done();
            $('#map_messages').html(featureMap._htmlDecode(_ajax_response.html)).css({
                visibility: 'visible',
                left: '4.8rem',
                top: '1.2rem' /*$('#mode-selector').offset().top + 280*/
            });
        });
    });

    $(document).on("click", 'a.dates_hold', function (event) {
        NProgress.start();
        $.ajax({ url: $(this).attr('data-route'), data: { map_view: 1 }, type: 'POST' }).done(function (_ajax_response) {
            NProgress.done();
            $('#map_messages').html(featureMap._htmlDecode(_ajax_response.html)).css({
                visibility: 'visible',
                left: '4.8rem',
                top: '1.2rem' /*$('#mode-selector').offset().top + 280*/
            });
        });
    });

    $(document).on("click", '[data-dismiss="map_messages"]', function (event) {
        if ($('#map_messages').css("visibility") == "visible") {
            $('#map_messages').css('visibility', 'hidden');
        }
    });

    $(document).on('click', '[data-role=change_wallet]', function () {
        featureMap._wallet_click($(this));
    });


    $(document).on('change', '[data-role=change_wallet]', function () {
        featureMap._wallet_click($(this));
    });

    $(document).on('click', '[data-role=changex]', function () {
        //console.log('click on right panel item...: ' + $(this).attr('data-resort-id'));
        google.maps.event.trigger(_.find(featureMap._markers_resort, ['resort_id', $(this).attr('data-resort-id')]).marker, 'click');
    });

    $(document).on('mouseenter', '[data-role=changex]', function () {
        if (typeof list_view === "undefined") {
            //console.log('mouse enter');
            _.find(featureMap._markers_resort, ['resort_id', $(this).attr('data-resort-id')]).marker.setAnimation(google.maps.Animation.BOUNCE);
        }
    });

    $(document).on('mouseleave', '[data-role=changex]', function () {
        if (typeof list_view === "undefined") {
            //console.log('mouse leave');
            _.find(featureMap._markers_resort, ['resort_id', $(this).attr('data-resort-id')]).marker.setAnimation(null);
        }
    });

    $(document).on('keyup', '[name=cash_amount]', function (e) {
        e.preventDefault();
        var ENTER = 13;
        var code = (e.keyCode ? e.keyCode : e.which);
        var amount = Number($(this).val());
        var max = Number($('[name=cash_amount]').attr('max'));
        amount = amount > max ? max : amount;
        $(this).val(amount);
        //console.log('cash....: ' + amount);

        if (code == ENTER) {
            //console.log('pressed enter....: ' + amount);

            featureMap._wallet_click($(this));
        }

    });

    $(document).on('show.bs.modal', '#modal_available_dates', function (e) {
        var link = $(e.relatedTarget);
        //console.log('dates for resort: ' + link.attr('rel'));
        $(this).find(".modal-body").html("loading...");
        featureMap._get_available_dates(link.attr('rel'), $(this).find(".modal-body"));
    });

    $(document).on('show.bs.modal', '#search-resorts-list', function (e) {
        $(this).find(".modal-body").html("loading...");
        featureMap._get_resort_name_list_html($(this).find(".modal-body"));
    });

    $(document).on('click', 'div.hidebox', function (e) {
        $('#mode-selector').css('visibility', 'hidden');
    });

    $(document).on('click', '#resort_tabs a', function (e) {
        //console.log('click on tabs...');
        e.preventDefault()
        $(this).tab('show')
    });

    $(document).on('click', '#load_more_resorts', function (e) {
        e.preventDefault();
        var MAX_ITEMS = 10;
        var parent = $(this).parent().parent().parent();
        var whitelabel = $(this).data('wl');
        var start = $('.count_divs').length;
        start = start < 0 ? 0 : start;
        var end = start + MAX_ITEMS;
        // if(end > start){end=start;}
        //remove all button "load-more"
        parent.find('.loadmorecontainer').remove();
        parent.find('.footerlistview').remove();
        parent.find('.pie').remove();
        console.log('start: ' + start + ' end: ' + end);
        resorts_showed = end;

        featureMap._bounds_change(start, end, '#page-items', whitelabel);
    });

    $(document).on('click', '[class^=sort_by]', function (e) {
        e.preventDefault();
        //if not clicked befor maybe not have icon arrow to represent sort order (asc/desc)
        // if (!$(this).find('i:last').is('[class*=fa-sort]')) {
        //     $(this).find('i:last').addClass('fa-sort-down');
        // }
        // $(this).find('i:last').toggleClass('fa-sort-up fa-sort-down');
        // $('a.sort_by_price i:last').toggleClass('fa-sort-up fa-sort-down')



        var classSort = $(this).find('i:last').attr('class');
        var orderBy = 'asc';
        if (classSort.includes('down')) {
            orderBy = 'desc';
        }
        featureMap._sort_by = $(this).data('sort');
        featureMap._sort_by_order = orderBy;
        featureMap._bounds_change();
        //console.log('sort by ' + featureMap._sort_by + ': ' + featureMap._sort_by_order);

        // if (featureMap._sort_by == 'price') {
        //     //remove sort icon arrow for rating
        //     $('a.sort_by_rating i:last').removeClass('fa-sort-up fa-sort-down');
        // } else {
        //     //remove sort icon arrow for price
        //     $('a.sort_by_price i:last').removeClass('fa-sort-up fa-sort-down');
        // }
    });

    $(document).on('click', '#uncheck-all', function (e) {
        var check = $(this).hasClass('check') ? true : false;
        $('#search-resorts-list input').each(function () {
            /*if($(this).prop('checked')){

            }*/
            $(this).prop('checked', check);
        });
        $(this).toggleClass('check uncheck');
    });

    $(document).on('click', '#lv_filter_resorts', function (e) {
        var count_all_resorts = $('#search-resorts-list input').length;
        var resorts_to_filter = [];
        $('#search-resorts-list input').each(function () {
            if ($(this).prop('checked')) {
                //console.log('resort-id: ' + $(this).val());
                resorts_to_filter.push($(this).val());
            }
        });

        if (resorts_to_filter.length == 0) {
            //console.log('user has no made changes.');
        } else {
            //console.log('to filter selected resorts');
            //console.dir(resorts_to_filter);
            var wl = $('li[role=presentation].active a').attr('aria-controls');
            featureMap._bounds_change(undefined, undefined, undefined, wl, resorts_to_filter);
        }
    });

    $('#map_price_slider').on('change', function (e) {
        //console.log('slider price: ' + e.target.value);
        $('#map_max_price').text(site_currency + ' ' + Number(e.target.value).format());
        //convert max price to USD, because prices are USD in fusiontables
        _max_price = Math.round(Number(e.target.value) * price_conversion_rate);
        featureMap._refresh_cluster();
    });


    $('.form_datetime').on('changeDate', function dateChanged(event) {
        //console.log('date changed....');
        if ($(this).val() != "") {
            $('#comboWeeks').show();
        }
        featureMap._refresh_cluster();
    });

    $('.form_datetime').change(function () {
        //console.log('date changed....');
        if ($(this).val() == "") {
            $('#comboWeeks').hide();
            featureMap._refresh_cluster();
        }

    });
    $('#comboWeeks').change(function () {
        //console.log('date changed....');
        featureMap._refresh_cluster();
    });


    //button dropdown
    // $(document).on('click', 'button', function(e){ $(this)
    // .html('<input type="text" style=" background-color:white; color:black;" value = "' + $.trim($(this).text())  + '"/> <input id="' + $(this).text() + '" class = "save" type="button" value="save" />')
    // //valor = $(this).text();
    // e.preventDefault(); // prevents default
    // return false;
    // })
    //
    // $(document).on('click', 'p', function(e){ $(this)
    // .html('<input type="text" style=" background-color:white; color:black;" value = "' + $.trim($(this).text())  + '"/> <input id="' + $(this).text() + '" class = "save" type="button" value="save" />')
    // valor = $(this).text();
    // })






    /*********************************EDITABLE LANGUAGE SECTION**********************************************/
    // var keyLanguage;
    // var newText;
    // var arrayLength;
    // var id;
    // var texto_concatenado = '';

    // //FUNCION QUE SOLO NOS PERMITIRA AVANZAR CON TRES BOTONES.
    // $("h2,p,small,big,div,a,button,u,li,ul,span").click(function(event) {
    //     //console.log('node: ',event.target.nodeName)
    //     //console.log('node: ',event.target.id)

    //     if ((event.target.id != 'goLanguages' && event.target.id != 'saveLanguages' &&
    //             event.target.id != 'cancelLanguages') /*&& event.target.nodeName !='BUTTON'*/ ) {
    //         return false;
    //     }
    // });

    // $('h2,p,small,big,div,a,button,u,li,span').on('click', function(e) {
    //     var this_element = $(this);

    //     if ($('.saveLanguage').is(':visible') == false) {

    //         var is_editable = true;
    //         if (this_element.children().length > 0) {
    //             for (var index = 0; index < this_element.children().length; index++) {
    //                 console.log('hijo ' + index + ': ' + this_element.children()[index].tagName)
    //                 if (this_element.children()[index].tagName === 'DIV' || this_element.children()[index].tagName === 'IMG' || this_element.children()[index].tagName === 'BUTTON') {
    //                     is_editable = false;
    //                 }
    //             }
    //         }
    //         //console.log('contains_DIV: ',is_editable);

    //         //if( this_element.children().length == 0 ){
    //         if (is_editable) {
    //             //CAMBIAR
    //             //if(this.hasAttribute('data-id')) {$(this).removeAttr("id");}
    //             this_element.attr('data-id', 'languageInEdition');

    //             // attribute exists?
    //             if (this.hasAttribute('data-key-language')) {
    //                 console.log('si contiene atributo data-key-language');
    //                 keyLanguage = this.getAttribute("data-key-language");
    //                 /************************************/
    //                 //SE OBTIENE EL VALUE POR MEDIO DEL KEY
    //                 $.ajax({
    //                     url: 'http://localhost:3005/languageByKey',
    //                     type: 'GET',
    //                     crossDomain: true,
    //                     data: 'language=en&key=' + this.getAttribute("data-key-language"),
    //                     async: true,
    //                     success: function(_ajax_response) {
    //                         if (_ajax_response && _ajax_response !== "undefined") {
    //                             //PINTAR EN TEXTO EDITABLE EL VALOR TRAIDO DE MONGO
    //                             console.log('key: ' + _ajax_response.key, 'text: ', _ajax_response.text);

    //                             //SI EL TEXTO CONTIENE PARAMETROS SE DIVIDIRA EN CAJAS DE TEXTO
    //                             regex = /:[a-zA-Z]{1,}[-_]{0,1}[a-zA-Z]{0,}/g;
    //                             var array_words = [];


    //                             if (regex.test(_ajax_response.text)) {
    //                                 var text = _ajax_response.text;
    //                                 array_words = text.match(regex);


    //                                 for (x = 0; x < array_words.length; x++) {
    //                                     text = text.replace(array_words[x], '~' + array_words[x] + '~');
    //                                 }

    //                                 //var newText = text.split('~');
    //                                 arrayLength = text.split('~').length;
    //                                 //$('#languageInEdition').val('');
    //                                 //$('#languageInEdition').removeAttr('value');
    //                                 //$('#languageInEdition').empty();
    //                                 this_element.empty();
    //                                 for (x = 0; x < text.split('~').length; x++) {
    //                                     if (text.split('~')[x].trim() != '') {
    //                                         var element = document.createElement("input");
    //                                         element.setAttribute("id", "languageSplit_" + x);
    //                                         element.setAttribute("value", text.split('~')[x]);
    //                                         element.onclick = function() { return false };
    //                                         if (text.split('~')[x].includes(":")) { element.setAttribute("readOnly", true); }

    //                                         //var elem_in_edition = document.getElementById("languageInEdition");
    //                                         //document.getElementById("text").innerHTML = text;
    //                                         this_element.append(element);
    //                                     }
    //                                 }

    //                             } else {
    //                                 //PINTAR TEXTO OBTENIDO EN LA CAJA EDITABLE
    //                                 this_element.val(_ajax_response.text);
    //                             }

    //                         }
    //                     }
    //                 });
    //                 /************************************/

    //             } else {
    //                 console.log('no contiene atributo data-key-language');

    //                 //SE OBTIENE EL KEY POR MEDIO DEL VALUE
    //                 $.ajax({
    //                     url: 'http://localhost:3005/languageByValue',
    //                     type: 'GET',
    //                     crossDomain: true,
    //                     data: 'language=en&value=' + $(this).text(),
    //                     async: true,
    //                     success: function(_ajax_response) {
    //                         //console.log('_ajax_response: ',_ajax_response);
    //                         if (_ajax_response && _ajax_response !== "undefined") {
    //                             if (_ajax_response.duplicated_key == 0) {
    //                                 console.log('No se encontraron coincidencias');
    //                             } else if (_ajax_response.duplicated_key > 1) {
    //                                 console.log('existe ' + _ajax_response.duplicated_key + ' veces')
    //                                 for (var index = 0; index < _ajax_response.duplicated_key; index++) {
    //                                     console.log('key_duplicado_' + index + ': ' + _ajax_response.key[index].key);
    //                                 }
    //                             } else {
    //                                 console.log('solo hay 1: ' + _ajax_response.key[0].key)
    //                                 keyLanguage = _ajax_response.key[0].key;
    //                             }

    //                             // keyLanguage = _ajax_response.key;
    //                             // //no se llena el textLanguage
    //                             // console.log('keyLanguage1: ',keyLanguage);
    //                         }
    //                     }
    //                 });
    //             }
    //             $(this).attr('contenteditable', 'true').after('<input id="saveLanguages" data-parent="' + $(this).prop("tagName") + '" data-prev-value="' + $(this).text() + '" class = "saveLanguage" type="button" value="save" />')
    //                 .after('<input id="cancelLanguages" class = "cancelLanguage" type="button" value="cancel" data-parent="' + $(this).prop("tagName") + '" data-prev-value="' + $(this).text() + '" />');


    //             //SI EL ELEMENTO ES UN BOTON O ANCHOR REF HABILITARA NUEVO BOTON DE GO
    //             console.log('tag: ', $(this).prop("tagName"));
    //             if ($(this).prop("tagName") == 'SMALL' || $(this).prop("tagName") == 'DIV' || $(this).prop("tagName") == 'A' || $(this).prop("tagName") == 'U') {
    //                 $(this).after('<input id="goLanguages" class = "goLanguage" type="button" value="go" data-parent="' + $(this).prop("tagName") + '" />');
    //                 var select = $(this);
    //                 var attributes = $(this).prop("attributes");

    //                 const _id = document.querySelector('#goLanguages');
    //                 $.each(attributes, function() {
    //                     console.log('this.name: ', this.name, 'this.value: ', this.value)
    //                         //$("#goLanguages").setAttribute(this.name, this.value);
    //                     if (this.name != 'class' && this.name != 'style' && this.name != 'id') { _id.setAttribute(this.name, this.value); }
    //                 });

    //                 //var element = $(this);
    //                 //for (var i = element.attributes.length - 1; i >= 0; i--){
    //                 //   element.removeAttribute(element.attributes[i].name);
    //                 // }
    //                 // loop through <select> attributes and apply them on <div>

    //             }
    //             //SELECCIONAR TODO EL TEXTO AL MOMENTO DE HACERLO EDITABLE
    //             $(this).selectText();

    //             //AL MOMENTO DE SALIR DE ESTE COMPONENTE SE
    //             //TOMARA EL NUEVO TEXTO PARA GUARDAR EN MONGO
    //             $(this).focusout(function() {
    //                 newText = $(this).text();
    //                 id = $(this).attr("id");
    //             });

    //         } else { console.log('Elemento no editable'); }
    //     }
    // });

    // $(document).on('click', '.cancelLanguage', function(e) {
    //     var selector = $(this).data('parent') + "[contenteditable='true']";
    //     $(selector)
    //         .attr('contenteditable', 'false')
    //         .text($(this).data('prev-value'));
    //     $(this).remove();
    //     $('.saveLanguage').remove();
    //     $('.goLanguage').remove();
    //     arrayLength = 0;
    //     return false;
    // });

    // $(document).on('click', '.saveLanguage', function(e) {
    //     var selector = $(this).data('parent') + "[contenteditable='true']";
    //     var element_edited = $(selector);
    //     element_edited.attr('contenteditable', 'false');

    //     if (arrayLength > 0) {
    //         for (x = 0; x < arrayLength; x++) {
    //             texto_concatenado += (typeof $("#languageSplit_" + x).val() !== 'undefined' ? $("#languageSplit_" + x).val().trim() : '') + ' ';
    //             $("#languageSplit_" + x).remove();
    //         }
    //         newText = texto_concatenado.trim();
    //     }
    //     console.dir(element_edited);
    //     element_edited.html(newText);
    //     console.log('newTextSave: ', newText)

    //     $.ajax({
    //         url: 'http://localhost:3005/languageSave',
    //         type: 'GET',
    //         crossDomain: true,
    //         data: 'language=' + _SESION_LANGUAGE + '&key=' + keyLanguage + '&value=' + newText,
    //         async: true,
    //         success: function(_ajax_response) {
    //             console.log('_ajax_response_fuera: ', _ajax_response);
    //             if (_ajax_response && _ajax_response !== "undefined") {
    //                 console.log('_SESION_LANGUAGE: ', _SESION_LANGUAGE);
    //                 console.log('prev-value2: ', $(this).data('prev-value'));
    //                 console.log('newText: ', newText);
    //                 // $.ajax({url:'http://gold.local/cronjobs/update-language/'+_SESION_LANGUAGE+'/'+$(this).data('prev-value')+'/'+newText+', type: 'GET', crossDomain: true,  data: '', async: true,
    //                 // 			success: function(_ajax_response) {
    //                 // 				 console.log('_ajax_response_dentro: ',_ajax_response);
    //                 // 			});
    //                 //CARGAR EL VALOR DE LA BD EN LA CAJA EDITABLE
    //                 //$("#languageInEdition").setAttribute("value", newText);
    //             }
    //         }
    //     });

    //     //COLOCAR EL NUEVO TEXTO EN LA ETIQUETA

    //     $(this).remove();
    //     $('.cancelLanguage').remove();
    //     $('.goLanguage').remove();
    //     arrayLength = 0;
    //     return false;
    // });

    jQuery.fn.selectText = function () {
        var doc = document;
        var element = this[0];
        console.log(this, element);
        if (doc.body.createTextRange) {
            var range = document.body.createTextRange();
            range.moveToElementText(element);
            range.select();
        } else if (window.getSelection) {
            var selection = window.getSelection();
            var range = document.createRange();
            range.selectNodeContents(element);
            selection.removeAllRanges();
            selection.addRange(range);
        }
    };

})(jQuery);

function url_query(query) {
    query = query.replace(/[\[]/, "\\\[").replace(/[\]]/, "\\\]");
    var expr = "[\\?&]" + query + "=([^&#]*)";
    var regex = new RegExp(expr);
    var results = regex.exec(window.location.href);
    if (results !== null) {
        return results[1];
    } else {
        return false;
    }
}


var _queryUrlHead = 'https://www.googleapis.com/fusiontables/v1/query?sql=';
var _max_price = 99998;
var country = "";
var featureMap = {

    _init: function () {
        var layerBoundaries;
        featureMap._initMap();
    },

    _markers: new Array(),
    _markers_resort: new Array(),
    _info_window: new Array(),
    _prev_infowindow: false,
    _map: null,
    _inventory: null,
    _min_price: null,
    _max_price: null,
    _check_in_date: null,
    _check_ext_date: null,
    _max_zoom: 15,
    _min_zoom: 3,
    _sort_by: '',
    _sort_by_order: 'asc',
    _bounds: new Array(),
    _xhr: new window.XMLHttpRequest(),
    _layerBoundaries: null,
    _mc: null,
    _styles: new Array(),
    _autocomplete: null,
    _array_wls_temp: new Array(),
    _resort_with_wls: new Array(),
    _resort_with_wls_full: new Array(),
    _wls_site: new Array(),
    _wls_min_max_prices: new Array(),
    _get_date: function (days) {
        //var check_in_form = Date.parse();
        var check_in = isNaN($('.form_datetime').datepicker('getDate')) ? new Date() : $('.form_datetime').datepicker('getDate');
        console.log(check_in);
        check_in.setDate(check_in.getDate() + days);

        var dd = check_in.getDate();
        var mm = check_in.getMonth() + 1;
        var yyyy = check_in.getFullYear();

        dd = (dd < 10) ? '0' + dd : dd;
        mm = (mm < 10) ? '0' + mm : mm;

        return yyyy + '-' + mm + '-' + dd;
    },
    _get_date_invert: function (days) {
        var check_in_form = Date.parse($('.form_datetime').datepicker('getDate'));
        var check_in = isNaN(check_in_form) ? new Date() : new Date(check_in_form);

        check_in.setDate(check_in.getDate() + days);

        var dd = check_in.getDate();
        var mm = check_in.getMonth() + 1;
        var yyyy = check_in.getFullYear();

        dd = (dd < 10) ? '0' + dd : dd;
        mm = (mm < 10) ? '0' + mm : mm;

        return dd + '/' + mm + '/' + yyyy;
    },
    _initMap: function () {
        console.log('init js 1.1');
        //NProgress.start();
        var _route = $('#map').data('route');
        var _data = '';

        //get whitelabels for site
        //validate if comes from session
        if (WLS_SELECTION) {
            featureMap._wls_site = WLS_SELECTION;
        } else {
            $.each(wls_lists, function (index, value) {
                featureMap._wls_site.push({ wl: index, name: value, active: 1 });
            });
        }


        //get min/max prices
        featureMap._get_min_max_price();

        var card = document.getElementById('pac-card');
        var input = document.getElementById('autocomplete_map');
        var types = document.getElementById('type-selector');
        var strictBounds = document.getElementById('strict-bounds-selector');

        featureMap._autocomplete = new google.maps.places.Autocomplete(input);



        //get map element div
        var map_element = document.getElementById('map');
        featureMap._map = new google.maps.Map(map_element, {
            center: { lat: 0, lng: 0 },
            zoom: 3,
            disableDefaultUI: true,
            zoomControl: (typeof remove_zoom_controls !== "undefined" && remove_zoom_controls) ? false : (typeof map_thumb === "undefined") ? true : false,
            streetViewControl: (typeof remove_zoom_controls !== "undefined" && remove_zoom_controls) ? false : (typeof map_thumb === "undefined") ? true : false,
            gestureHandling: 'greedy'
        });

        google.maps.event.addDomListener(map_element, 'mousedown', function () {
            featureMap._map_click();
        });


        featureMap._map.addListener('zoom_changed', function () {
            featureMap._bounds_change();
        });
        featureMap._map.addListener('idle' /*'bounds_changed'*/, function () {
            featureMap._bounds_change();
        });

        featureMap._refresh_cluster();
        featureMap._autocomplete_function();
        featureMap._init_change_checkbox();
        featureMap._change_checkbox();


        /*************************************************
         * Update changes to Map, as defined in URL
         ***********************************************/
        var url_param = url_query('page');
        //console.log('param...:' + url_param);
        if (url_param) {
            var attributes = [];
            if (url_param == "_map_search") {
                var bounds = JSON.parse(decodeURIComponent(url_query('bounds')));
                var areabounds = JSON.parse(decodeURIComponent(url_query('areabounds')));
                var zoom = url_query('zoom');
                //console.log('addding attributes...:' + bounds);
                //console.log('addding attributes2...:' + zoom);
                attributes.push({
                    bounds: bounds,
                    areabounds: areabounds,
                    zoom: zoom
                });
            }
            //console.dir(attributes);
            featureMap[url_param](attributes);
            $('#autocomplete_map').click();
        }

        //crea componentes para more

        //guest rating slider
        var ratingSlider = document.getElementById('ratingSlider');

        if (ratingSlider != null && ratingSlider.noUiSlider === undefined) {
            noUiSlider.create(ratingSlider, {
                connect: true,
                behaviour: 'tap',
                start: [0, 5],
                step: 1,
                range: {
                    'min': [0],
                    'max': [5]
                },
                format: {
                    from: function (value) {
                        return parseInt(value);
                    },
                    to: function (value) {
                        return parseInt(value);
                    }
                }

            });

            var nodesRating = [
                document.getElementById('lower-value-rating'), // 0
                document.getElementById('upper-value-rating')  // 1
            ];

            // Display the slider value and how far the handle moved
            // from the left edge of the slider.
            ratingSlider.noUiSlider.on('set', _.debounce(function (values, handle, unencoded, isTap, positions) {
                featureMap._more_filters(morefiltersObj);
            }, 200));

            ratingSlider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                nodesRating[handle].innerHTML = values[handle];
                morefiltersObj.rating = values;
                morefiltersObj.modal.rating = values;
                if (values[0] == 0 && values[1] == 5) {
                    SnippetMoreFilters.setSliderRatingBan(false);
                } else {
                    SnippetMoreFilters.setSliderRatingBan(true);
                }
            });
        }


        var starsSlider = document.getElementById('starsSlider');

        if (starsSlider !== null && starsSlider.noUiSlider === undefined) {
            noUiSlider.create(starsSlider, {
                connect: true,
                behaviour: 'tap',
                start: [1, 5],
                step: 1,
                range: {
                    'min': [1],
                    'max': [5]
                },
                format: {
                    from: function (value) {
                        return parseInt(value);
                    },
                    to: function (value) {
                        return parseInt(value);
                    }
                }

            });


            var lower = document.getElementById('div-lower-value-stars');
            var upper = document.getElementById('div-upper-value-stars');

            // Display the slider value and how far the handle moved
            // from the left edge of the slider.
            // starsSlider.noUiSlider.on('set', function (values, handle, unencoded, isTap, positions) {
            //    lower.innerHTML = `<span id="lower-value-stars" style="color:#ea732d" class="star-${values[0]}"></span>`
            //    upper.innerHTML = `<span id="upper-value-stars" style="color:#ea732d" class="star-${values[1]}"></span>`
            //    morefiltersObj.stars = values;

            //    console.log("refresh_cluser...StarsSlider")
            // //    setTimeout(function() {
            // //     featureMap._refresh_cluster()
            // // }, 50);

            // });


            starsSlider.noUiSlider.on('set', _.debounce(function (values, handle, unencoded, isTap, positions) {
                featureMap._more_filters(morefiltersObj);
            }, 200));

            starsSlider.noUiSlider.on('update', function (values, handle, unencoded, isTap, positions) {
                lower.innerHTML = `<span id="lower-value-stars" style="color:#ea732d" class="star-${values[0]}"></span>`
                upper.innerHTML = `<span id="upper-value-stars" style="color:#ea732d" class="star-${values[1]}"></span>`
                morefiltersObj.stars = values;
                morefiltersObj.modal.stars = values;
            });

        }


    },

    _refresh_cluster: function () {
        console.log("_refresh_cluster");

        if (typeof (is_logged) == 'undefined') {
            is_logged = true;
        }
        console.log("_refresh_cluster");
        if (is_logged) {
            if (featureMap._mc && typeof featureMap._mc !== "undefined") {
                featureMap._mc.clearMarkers();
            }
            featureMap._mc = new MarkerClusterer(featureMap._map, [], {
                styles: [{
                    url: '/js/images/map/m1.png',
                    textColor: '#fff',
                    textSize: 11,
                    width: 60,
                    height: 57,
                    anchorIcon: [0, 0],
                    anchorText: [100, 10],
                    backgroundPosition: '100% 0'
                }],
                maxZoom: 7
            });
        } else {
            //document.querySelector('.login-button').click();
        }



        //when clickingmaker cluster. takes the number of clusters
        //this function once wrks, can be changed to zoom_changed function below the code
        google.maps.event.addListener(featureMap._mc, "click", function (c) {
            //console.log("click: ");
            //console.log("&mdash;Center of cluster: " + c.getCenter());
            //console.log("&mdash;Number of managed markers in cluster: " + c.getSize());
            var m = c.getMarkers();
            var p = [];
            for (var i = 0; i < m.length; i++) {
                p.push(m[i].getPosition());
            }

        });

        featureMap._get_data();
    },
    _autocomplete_function: function () {

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        featureMap._autocomplete.bindTo('bounds', featureMap._map);

        // Set the data fields to return when the user selects a place.
        featureMap._autocomplete.setFields(
            ['address_components', 'geometry', 'icon', 'name']);

        featureMap._autocomplete.addListener('place_changed', function () {
            var place = featureMap._autocomplete.getPlace();


            var shortCountry = "";
            var state = "";
            var city = "";
            var street = "";
            var streetNumber = "";
            var postalCode = "";
            var name = place.name;
            var region = "";
            var latitud = ""
            var longitud = "";
            var available = "false";
            if (place.hasOwnProperty('address_components')) {
                available = "true";
                latitud = place.geometry.location.lat();
                longitud = place.geometry.location.lng();
                place.address_components.forEach(function (address, index) {
                    //console.log(address);
                    shortCountry = address.types.includes("country") ? address.short_name : shortCountry;
                    state = address.types.includes("administrative_area_level_1") ? address.long_name : state;
                    city = address.types.includes("locality") ? address.long_name : city;
                    region = address.types.includes("neighborhood") ? address.long_name : region;
                    street = address.types.includes("route") ? address.long_name : street;
                    streetNumber = address.types.includes("street_number") ? address.long_name : streetNumber;
                    postalCode = address.types.includes("postal_code") ? address.long_name : postalCode;
                });
            }


            // SI EL PAIS ES DIFERENTE SE RECARGA EL INVENTARIO
            //var resorts = [];
            //featureMap._more_filters(morefiltersObj);
            if (shortCountry != country) {
                country = shortCountry
                console.log("pais distinto", arrSeleccionados)
                arrSeleccionados = []
                $('#amenitiesMoreFilters').empty();
                featureMap._refresh_cluster();
                console.log("limpia array de los seleccionados", arrSeleccionados)
                all_resorts = [...featureMap._resort_with_wls_full]
                console.log("all_resorts--->", all_resorts.length)
            } else {
                console.log("all_resorts====>", all_resorts.length)
                featureMap._reset_more_filters();
                featureMap._resort_with_wls_full = [...all_resorts];

                //featureMap._more_filters(morefiltersObj);
            }

            var logs_search = {

                latitud: latitud,
                longitud: longitud,
                shortCountry: shortCountry,
                state: state,
                city: city,
                postalCode: postalCode,
                street: street,
                streetNumber: streetNumber,
                name: name,
                region: region,
                available: available,
                ak: AK,
                un: UN
            }

            var _route = SERVICES_URL + "/logs_search";
            $.ajax({ url: _route, data: JSON.stringify(logs_search), contentType: "application/json", type: 'POST', async: true, crossDomain: true });

            //reset more filters
            //$('#amenitiesMoreFilters').empty();
            //arrSeleccionados = [];

            if (!place.geometry || !place) {
                // User entered the name of a Place that was not suggested and
                // pressed the Enter key, or the Place Details request failed.
                window.alert("No details available for input: '" + place.name + "'");


                return;
            }

            //console.dir(place);
            var destination_name = '';
            for (var index = 0; index < place.address_components.length; index++) {
                destination_name += place.address_components[index].long_name + ', ';
            }
            destination_name = destination_name.slice(0, -2);
            //set destination title on desktop list view
            $('#destination_title').text(destination_name);

            // If the place has a geometry, then present it on a map.
            if (place.geometry.viewport) {
                featureMap._map.fitBounds(place.geometry.viewport);
                var customData = [];
                $.ajax({
                    url: SERVICES_URL + '/map/custom_location/',
                    type: 'GET',
                    crossDomain: true,
                    data: 'name=' + name + '&city=' + city + '&state=' + state + '&country=' + shortCountry,
                    async: false,
                    success: function (data) {
                        //console.log("customMapData", JSON.stringify(data));
                        customData = data;

                    }
                });


                if (customData.length > 0) {
                    featureMap._map.setZoom(customData[0].zoom);
                    featureMap._map.setCenter(customData[0].center)
                } else {
                    if (window.location.href.indexOf("resort/listview") > -1) {
                        console.log('auto complete response else >>>>>>', 6);
                        featureMap._map.setCenter(place.geometry.location);
                        featureMap._map.setZoom(6);
                    }

                }
            } else {
                featureMap._map.setCenter(place.geometry.location);
                featureMap._map.setZoom(17); // Why 17? Because it looks good.
            }




            var address = '';
            if (place.address_components) {
                address = [
                    (place.address_components[0] && place.address_components[0].short_name || ''),
                    (place.address_components[1] && place.address_components[1].short_name || ''),
                    (place.address_components[2] && place.address_components[2].short_name || '')
                ].join(' ');
            }

            /******************* Disabled Draw Boundaries by Fusion Tables Shutdown at Dec2019 **********/
            /*if (typeof layerBoundaries !== 'undefined') {
                layerBoundaries.setMap(null);
            }
            if (place !== undefined) {

                if (place.address_components.length > 1) {
                    /******************************************
                     *  Draw Boundaries for States/Provinces
                     ******************************************
                    console.log('place changed...: ' + place.address_components[0].long_name + ', country: ' + place.address_components[1].short_name);
                    layerBoundaries = new google.maps.FusionTablesLayer({
                        query: {
                            select: 'geometry',
                            from: _FT_BOUNDARIES_STATES,
                            where: "ADMIN_NAME = '" + place.address_components[place.address_components.length - 2].long_name.normalize('NFD').replace(/[\u0300-\u036f]/g, "") + "' and FIPS_CNTRY = '" + place.address_components[place.address_components.length - 1].short_name + "'"
                        },
                        styles: [{
                            polygonOptions: {
                                fillColor: '#a4ce3a',
                                strokeWeight: 2,
                                strokeColor: '#a4ce3a',
                                fillOpacity: 0.1
                            }
                        }],
                        options: {
                            suppressInfoWindows: true
                        }
                    });
                    layerBoundaries.setMap(featureMap._map);
                }
                /**************************************
                 * Draw boundaries for countries
                 **************************************
                else if (place.address_components.length == 1) {
                    layerBoundaries = new google.maps.FusionTablesLayer({
                        query: {
                            select: 'geometry',
                            from: _FT_BOUNDARIES_COUTRIES,
                            where: "ISO_2DIGIT = '" + place.address_components[0].short_name + "'"
                        },
                        styles: [{
                            polygonOptions: {

                                fillColor: '#a4ce3a',
                                strokeWeight: 2,
                                strokeColor: '#a4ce3a',
                                fillOpacity: 0.1
                            }
                        }],
                        options: {
                            suppressInfoWindows: true
                        }
                    });
                    layerBoundaries.setMap(featureMap._map);
                }
            }*/

            //console.log(place);
        });
    },
    _init_change_checkbox: function () {
        $('.map-wls input[type=checkbox]').each(function () {
            const itemCheckbox = $(this);
            const checkid = itemCheckbox.attr("id");

            //update wls status
            console.log('update checkssss');
            featureMap._wls_site.filter(function (item) {
                if (item.wl == checkid) {
                    itemCheckbox.attr("checked", item.active ? true : false);
                }
            });
        });
    },
    _change_checkbox: function () {
        $('.map-wls input[type=checkbox]').each(function () {
            $(this).on('change', function () {
                const checkid = $(this).attr("id");
                const isChecked = $(this).is(":checked");
                //console.log('click on WL checkbox....');

                //update wls status
                featureMap._wls_site.filter(function (item) {
                    //console.log('item: ', item, ' == id: ', checkid);
                    if (item.wl == checkid) {
                        item.active = isChecked;
                    }
                });

                //get max price from all active whitelabels
                let wlMaxPrice = 0;
                let maxWlPriceArray = [];
                featureMap._wls_site.filter(function (item) {
                    if (item.active) {
                        maxWlPriceArray.push(featureMap._wls_min_max_prices[item.wl].max);
                    }
                });
                //update new max price
                wlMaxPrice = maxWlPriceArray.length > 0 ? _.max(maxWlPriceArray) : 0;
                $('#map_price_slider').val(wlMaxPrice);
                $('#map_max_price').text(site_currency + ' ' + Number(wlMaxPrice).format());
                $('#map_price_slider').attr('max', wlMaxPrice);
                $('#map_price_slider').val(wlMaxPrice);

                featureMap._refresh_cluster();

                //update cookie for wl selection
                featureMap._wl_click($(this), featureMap._wls_site);


                NProgress.done();


                if ($(this).is(":checked")) {
                    $(this).attr("checked", true);
                    if ($(this).parent().parent().parent().parent().hasClass('mobile')) {
                        $(this).parent().removeClass('t_mobile_unchecked');
                        $(this).parent().parent().parent().parent().removeClass('li_mobile_unchecked');
                    }
                } else {
                    $(this).attr("checked", false);
                    if ($(this).parent().parent().parent().parent().hasClass('mobile')) {
                        $(this).parent().addClass('t_mobile_unchecked');
                        $(this).parent().parent().parent().parent().addClass('li_mobile_unchecked');
                    }
                }
            });
        });
    },
    _get_inventory: function (refresh) {
        //if ( featureMap._inventory == null || refresh ){
        var _route = SERVICES_URL + "/inventory/generateminprice";
        //console.log('call route: ', _route);
        var fechaCI = featureMap._get_date(- ($('#comboWeeks').val() * 7));
        featureMap._check_in_date = $('.form_datetime').val() == undefined || $('.form_datetime').val().trim().length == 0 ? featureMap._get_date(0) : (fechaCI < featureMap._get_date(0) ? featureMap._get_date(0) : fechaCI);
        featureMap._check_ext_date = $('.form_datetime').val() == undefined || $('.form_datetime').val().trim().length == 0 ? featureMap._get_date(730) : featureMap._get_date(($('#comboWeeks').val() * 7));

        var wls_array = [];
        /*$.each(wls_lists, function(index, value) {
            if ($('#' + index + ' input[type="checkbox"]').is(':checked')) {
                wls_array.push(parseInt(index));
            }
        });*/
        featureMap._wls_site.filter(function (item) {
            if (item.active) {
                wls_array.push(parseInt(item.wl));
            }
        });

        //get min,max prices
        const maxPrice = typeof $('#map_price_slider').attr('max') === 'undefined' ? 100000 : parseInt($('#map_price_slider').attr('max'));
        const currentPrice = typeof $('#map_price_slider').val() === 'undefined' ? maxPrice : parseInt($('#map_price_slider').val());
        featureMap._min_price = 0; //typeof $('#map_price_slider').attr('min') === 'undefined' ? 0 : parseInt($('#map_price_slider').attr('min'));
        featureMap._max_price = currentPrice;

        //more filter min max price
        var slider = document.getElementById('slider');

        if (slider != null && slider.noUiSlider !== undefined) {
            var arrSliderMinMaxPrice = slider.noUiSlider.get();
            featureMap._min_price = arrSliderMinMaxPrice[0];
            featureMap._max_price = arrSliderMinMaxPrice[1];
        }

        var arrMoreFilters = [];

        for (let x = 0; x < arrSeleccionados.length; x++) {
            arrMoreFilters.push(arrSeleccionados[x].value);
        }


        //console.log("document.getElementById('filter-resort-name).value", document.getElementById('filter-resort-name'))
        //var resort_name = document.getElementById('filter-resort-name') == null ? '': document.getElementById('filter-resort-name').value;
        // morefiltersObj.resort_name = resort_name;
        morefiltersObj.amenities = arrMoreFilters;

        if (morefiltersObj.price === undefined) {
            //morefiltersObj.price = [featureMap._min_price, featureMap._max_price ]
            morefiltersObj.price = [0, 5000]
        }

        var _data = {
            inspiraId: 1,
            country: country,
            whitelabel_ids: wls_array, //[18,999],
            check_in_date_start: featureMap._check_in_date, //"2019-06-17",
            check_in_date_end: featureMap._check_ext_date, //"2020-01-17",
            min_price: morefiltersObj.price[0],//featureMap._min_price,
            max_price: morefiltersObj.price[1],//featureMap._max_price,
            more_filters: morefiltersObj.amenities,//arrMoreFilters,
            average_rating: morefiltersObj.stars,//avgRating,
            resort_name: morefiltersObj.resort_name,//resort_name,
            guest_rating: morefiltersObj.rating//guestRating,
        };
        //console.log('getting inventory wiht data: ', _data);
        //featureMap._inventory = JSON.parse( $.ajax({ url: _route, data: JSON.stringify(_data), contentType: "application/json", type: 'POST', async: false, crossDomain: true }).responseText );
        //console.dir( featureMap._inventory );
        //}
        //return featureMap._inventory;
        return $.ajax({ url: _route, data: JSON.stringify(_data), contentType: "application/json", type: 'POST', async: true, crossDomain: true });
    },
    _get_min_max_price: function () {
        //var queryUrlTail = '&key=' + _GOOGLE_API_KEY;
        var arrayPrices = [];

        //prepare array for whitelabels and store min/max prices

        for (var index = 0; index < featureMap._wls_site.length; index++) {
            featureMap._wls_min_max_prices[featureMap._wls_site[index].wl] = { prices: [], min: 0, max: 0 };
        }

        //console.log(':::::: _get_min_max_price() :::::');
        //var inventory = featureMap._get_inventory();
        featureMap._get_inventory().done(function (response) {
            console.log('response2: ', response);
            featureMap._inventory = response; //JSON.parse( response );
            if (typeof featureMap._inventory !== "undefined") {
                const applyResidencyRestrictions = Array.isArray(RESIDENCY_RESTRICTIONS) ? true : false;
                for (var i = 0; i < featureMap._inventory.length; i++) {
                    //validate residency restrictions
                    if (applyResidencyRestrictions && RESIDENCY_RESTRICTIONS.includes(featureMap._inventory[i]._id.resortId)) {
                        console.log('Resort restricted: ', featureMap._inventory[i]._id.resortId);
                        featureMap._inventory.splice(i, 1);
                        continue;
                    }
                    for (var index = 0; index < featureMap._inventory[i].prices.length; index++) {
                        arrayPrices.push(Number(featureMap._inventory[i].prices[index].minPrice));
                        featureMap._wls_min_max_prices[featureMap._inventory[i].prices[index].wl].prices.push(featureMap._inventory[i].prices[index].minPrice);
                    }
                }

                //calculate min/max for wls
                for (let index in featureMap._wls_min_max_prices) {
                    featureMap._wls_min_max_prices[index].min = _.min(featureMap._wls_min_max_prices[index].prices);
                    featureMap._wls_min_max_prices[index].max = _.max(featureMap._wls_min_max_prices[index].prices);
                }


                var minPrice = _.min(arrayPrices);
                minPrice = price_conversion_rate > 0 ? Math.round(minPrice / price_conversion_rate) : minPrice;
                featureMap._min_price = minPrice;
                var maxPrice = _.max(arrayPrices);
                _max_price = maxPrice;
                maxPrice = price_conversion_rate > 0 ? Math.round(maxPrice / price_conversion_rate) : maxPrice;
                featureMap._max_price = maxPrice;



                //console.log('prices, min: ' + minPrice + ", max: " + maxPrice);
                $('#map_max_price').text(site_currency + ' ' + Number(maxPrice).format());
                $('#map_price_slider').attr('min', minPrice);
                $('#map_price_slider').attr('max', maxPrice);
                $('#map_price_slider').val(maxPrice);
            }
        });













        /*$.each(wls_lists, function(index, value) {
            var wl_column = "wl" + index;
            var query = "select MINIMUM(" + wl_column + "), MAXIMUM(" + wl_column + ") from " + _FT_WHITELABELS;
            query += " where " + wl_column + " > 0 and " + wl_column + " < 99999 ";

            console.log(query);
            var queryurl = encodeURI(_queryUrlHead + query + queryUrlTail);

            var jqxhr = $.get(queryurl, function(response) {
                if (typeof response.rows !== "undefined") {
                    console.dir(response);
                    for (var i = 0; i < response.rows.length; i++) {
                        for (var index = 0; index < response.rows[i].length; index++) {
                            arrayPrices.push(Number(response.rows[i][index]));
                        }
                    }

                    console.log('full array prices: ' + arrayPrices);
                    var minPrice = _.min(arrayPrices);
                    minPrice = price_conversion_rate > 0 ? Math.round(minPrice / price_conversion_rate) : minPrice;
                    var maxPrice = _.max(arrayPrices);
                    _max_price = maxPrice;
                    maxPrice = price_conversion_rate > 0 ? Math.round(maxPrice / price_conversion_rate) : maxPrice;


                    console.log('prices, min: ' + minPrice + ", max: " + maxPrice);
                    $('#map_max_price').text(site_currency + ' ' + Number(maxPrice).format());
                    $('#map_price_slider').attr('min', minPrice);
                    $('#map_price_slider').attr('max', maxPrice);
                    $('#map_price_slider').val(maxPrice);
                }


            }, "jsonp");

        });*/





    },
    _get_wl_count: function (wl_number, is_active) {
        /*var queryUrlTail = '&key=' + _GOOGLE_API_KEY;

        var extraquery = "and wl" + wl_number + " >= 0 AND wl" + wl_number + " <= 100000";
        var check_in_date = featureMap._get_date(-60);
        var check_ext_date = featureMap._get_date(60);

        var query = "select count() as total from " + _FT_WHITELABELS + " where check_in_date >= '" + check_in_date + "' and check_in_date <= '" + check_ext_date + "'" + extraquery;

        var queryurl = encodeURI(_queryUrlHead + query + queryUrlTail);

        var jqxhr = $.get(queryurl, function(response) {

            if (typeof response.rows !== "undefined") {
                var numbers = response.rows[0].toString();
                if (is_active) {
                    $('#' + wl_number + ' input[type="checkbox"]').prop('checked');
                }
                $('#' + wl_number + ' span.resort-number').html(numbers);
            }
        }, "jsonp");*/
    },
    _get_data: function () {
        //var inventory = featureMap._get_inventory(true);
        featureMap._get_inventory().done(function (response) {
            //console.log('response1: ', response);
            featureMap._inventory = response; //JSON.parse( response );
            if (typeof featureMap._inventory !== "undefined") {
                featureMap._resort_with_wls_full = [];

                const applyResidencyRestrictions = Array.isArray(RESIDENCY_RESTRICTIONS) ? true : false;
                //_.map(featureMap._inventory, function(item) {
                for (var i = 0; i < featureMap._inventory.length; i++) {
                    if (typeof featureMap._inventory[i] !== "undefined" && typeof featureMap._inventory[i]._id !== "undefined" && typeof featureMap._inventory[i].resort_info[0] !== "undefined") {

                        //validate residency restrictions
                        if (applyResidencyRestrictions && RESIDENCY_RESTRICTIONS.includes(featureMap._inventory[i]._id.resortId)) {
                            console.log('Resort restricted: ', featureMap._inventory[i]._id.resortId);
                            featureMap._inventory.splice(i, 1);
                            continue;
                        }

                        //generate wl array
                        wls_array = [];
                        $.each(featureMap._inventory[i].prices, function (index, value) {
                            wls_array.push({ desc: wls_lists[value.wl], price: value.minPrice });
                        });

                        //new one, not already assigned
                        featureMap._resort_with_wls_full.push({
                            inspiraId: featureMap._inventory[i]._id.inspiraId,
                            resort_id: featureMap._inventory[i]._id.resortId,
                            resort_name: featureMap._inventory[i].resort_info[0].resort_name,
                            location: featureMap._inventory[i].resort_info[0].latitude + ',' + featureMap._inventory[i].resort_info[0].longitude,
                            check_in_date: null,
                            rating: featureMap._inventory[i].resort_info[0].average_rating,
                            guest_rating: featureMap._inventory[i].resort_info[0].guest_rating,
                            min_price: featureMap._inventory[i].prices[0].minPrice,
                            whitelabels: wls_array
                        });
                    } else {
                        console.log('resort info is not complete: ', featureMap._inventory[i]);
                    }

                }
                featureMap._draw_cluster(featureMap._resort_with_wls_full);
            }
        });



        /* Old version
        var queryUrlTail = '&key=' + _GOOGLE_API_KEY;
        var query = "select resort_id, location, MINIMUM(check_in_date) as check_in_date, count(), MINIMUM(rating) ";
        var extraquery = "";
        var check_in_date, check_ext_date;
        if ($('.form_datetime').val().trim().length > 0) {
            check_in_date = featureMap._get_date(-60);
            check_ext_date = featureMap._get_date(60);
        } else {
            check_in_date = featureMap._get_date(0);
            check_ext_date = featureMap._get_date(730);
        }

        //clear array of wls
        featureMap._array_wls_temp = [];
        //clear array of resorts with wls
        featureMap._resort_with_wls = [];
        //get enabled whitelabel list
        $.each(wls_lists, function(index, value) {
            var is_active = $('#' + index + ' input[type="checkbox"]').is(':checked');
            if (is_active) {
                featureMap._array_wls_temp.push({ id: index, desc: value });
            }
        });




        //get an item of whitelabel array and query to fusiontable
        var nextWl = featureMap._array_wls_temp.pop();
        featureMap._get_data_by_wl(nextWl);*/
        /***query+= ", MINIMUM( wl" + nextWl.id + ") as '" + nextWl.desc + "'";
        extraquery += " AND wl" + nextWl.id + " <= " + maximum_price ;
        featureMap._get_wl_count( nextWl.id, true);*/

        //console.dir(wls_lists);
        /*var wl_column = '';
          var maximum_price = _max_price;
          var condition = " <= ";
         $.each(wls_lists,function(index, value){
          var is_active = $('#'+index+' input[type="checkbox"]').is(':checked');

          if(is_active){
              wl_column = "wl"+index;
              query+= ", MINIMUM("+wl_column+") as '"+value+"'";
              extraquery += " AND " + wl_column + condition + maximum_price ;
              maximum_price = 0;
              condition = " > ";
              featureMap._get_wl_count(index, true);
          }else{
              featureMap._get_wl_count(index, false);
          }
        });
        //extraquery = extraquery.slice(0, -4);*/

        /***query+= " from " + _FT_WHITELABELS + " where check_in_date >= '"+check_in_date+"' and check_in_date <= '"+check_ext_date+"' "+extraquery+" group by resort_id, location"; //, location, rating ";

         console.log('Fusion table query: '+ query);
         var queryurl = encodeURI(_queryUrlHead + query + queryUrlTail);

         var jqxhr = $.get(queryurl, featureMap._process_fusiontable_response, "jsonp");*/
        //var jqxhr = $.get(queryurl, featureMap._draw_cluster, "jsonp");


    },
    /*_get_data_by_wl: function(wl_obj) {
        var queryUrlTail = '&key=' + _GOOGLE_API_KEY;
        var query = "select resort_id, location, MINIMUM(check_in_date) as check_in_date, count(), MINIMUM(rating) ";
        var extraquery = "";
        var check_in_date, check_ext_date;
        if ($('.form_datetime').val().trim().length > 0) {
            check_in_date = featureMap._get_date(-60);
            check_ext_date = featureMap._get_date(60);
        } else {
            check_in_date = featureMap._get_date(0);
            check_ext_date = featureMap._get_date(730);
        }

        query += ", MINIMUM( wl" + wl_obj.id + ") as '" + wl_obj.desc + "'";
        extraquery += " AND wl" + wl_obj.id + " > 0 AND  wl" + wl_obj.id + " <= " + _max_price;
        featureMap._get_wl_count(wl_obj.id, true);

        query += " from " + _FT_WHITELABELS + " where check_in_date >= '" + check_in_date + "' and check_in_date <= '" + check_ext_date + "' " + extraquery + " group by resort_id, location";
        console.log('Fusion table query: ' + query);
        var queryurl = encodeURI(_queryUrlHead + query + queryUrlTail);
        var jqxhr = $.get(queryurl, featureMap._process_fusiontable_response, "jsonp");





    },
    _process_fusiontable_response: function(response) {
        featureMap._resort_with_wls.push(response);
        //validate if there is more wls
        var nextWl = featureMap._array_wls_temp.pop();
        if (typeof nextWl !== "undefined") {
            //query again
            featureMap._get_data_by_wl(nextWl);
        } else {
            //to draw cluster
            console.log("TO DRAWWW CLUSTERRRRR!!!!");
            console.dir(featureMap._resort_with_wls);
            //clear full array
            featureMap._resort_with_wls_full = [];
            //prepare object with all resorts with all wls
            //iterate response array
            _.map([4, 8], function(item) {

            });
            for (var arr_idx = 0; arr_idx < featureMap._resort_with_wls.length; arr_idx++) {
                _.map(featureMap._resort_with_wls[arr_idx].rows, function(item) {
                    //validate if currect resort is already assigned
                    if (arr_idx > 0 && _.find(featureMap._resort_with_wls_full, { resort_id: item[0] })) {
                        //then asign just whitelabel info
                        _.find(featureMap._resort_with_wls_full, { resort_id: item[0] }).whitelabels.push({
                            desc: featureMap._resort_with_wls[arr_idx].columns[5],
                            price: item[5]
                        });
                    } else {
                        //new one, not already assigned
                        featureMap._resort_with_wls_full.push({
                            resort_id: item[0],
                            location: item[1],
                            check_in_date: item[2],
                            rating: item[4],
                            whitelabels: [{
                                desc: featureMap._resort_with_wls[arr_idx].columns[5],
                                price: item[5]
                            }]
                        });
                    }

                });
            }

            console.log("NEW ARRAY!!!!");
            console.dir(featureMap._resort_with_wls_full);
            featureMap._draw_cluster(featureMap._resort_with_wls_full);
        }
    },*/
    _draw_cluster: function (response) {
        //console.log('fusiontable response:');
        //console.dir(response);
        console.log("_draw_cluster")
        if (typeof response !== "undefined") {

            featureMap._markers = [];
            featureMap._markers_resort = [];
            //console.dir(response);
            //console.log("inventory count: " + response.length);
            var resort;
            for (var i = 0; i < response.length; i++) {
                resort = response[i];
                //  console.log( 'resort: '+ response.rows[i][0] + /* 'location: '+response.rows[i][1] +*/ ', Weeks: '+response.rows[i][2]+ ', wl: $' + response.rows[i][3]);

                //get latitude,longitude
                var arr = resort.location.split(",");
                var latlng = new google.maps.LatLng(arr[0], arr[1]);
                var image = '/js/images/map/resort.png';

                var minimum_price = [];
                //iterate all whitelabels to calculate minimum price
                for (var index = 0; index < resort.whitelabels.length; index++) {
                    minimum_price.push({ 'price': resort.whitelabels[index].price, 'wl': resort.whitelabels[index].desc });
                }
                /*for(var index = 5; index < response.rows[i].length; index++){
                    if( response.rows[i][index] > 0 ){
                        minimum_price.push( { 'price': response.rows[i][index], 'wl': response.columns[index] } );
                    }
                }*/
                //console.log('minimum_price: '+minimum_price.length);
                if (minimum_price.length > 0) {
                    var minimumPriceObject = _.minBy(minimum_price, 'price');
                    //console.log('row:' +response.rows[i]);
                    //console.log('minimum: '+minimumPriceObject + ", undefined: " + _.isUndefined( minimumPriceObject ) );
                    if (!_.isUndefined(minimumPriceObject)) {

                        var marker = new google.maps.Marker({
                            position: latlng,
                            map: featureMap._map,
                            icon: image,
                            //customInfo: response.rows[i], //delete until all references are changed to resort property bellow
                            minimumPrice: Number(minimumPriceObject.price),
                            minimumPriceObj: minimumPriceObject,
                            wl: minimumPriceObject.wl.replace(/ /g, '_').toLowerCase(),
                            rating: resort.rating, //delete until all references are changed to resort property bellow
                            resort: resort
                        });




                        //marker.addListener('click', function(e) {
                        google.maps.event.addListener(marker, "click", function (evt) {
                            // actually stops the event from bubbling up to the map or the document

                            if (event !== null) {
                                event.stopPropagation();
                            }

                            var resort = this.resort;
                            var minPrice = this.minimumPrice;
                            var minPriceObj = this.minimumPriceObj;

                            $.ajax({
                                url: SERVICES_URL + '/resorts',
                                type: 'GET',
                                crossDomain: true,
                                data: 'resort_id=' + resort.resort_id + '&search_type=country&language=en',
                                async: false,
                                success: function (_ajax_response) {

                                    if (_ajax_response !== "undefined") {
                                        //console.log('click on resort marker..: ', resort);
                                        featureMap._marker_click(_ajax_response, resort, minPrice, minPriceObj);
                                    } else {
                                        alert('Resort Not available');
                                    }
                                }
                            });
                        });

                        featureMap._markers.push(marker);
                        featureMap._markers_resort.push({ 'resort_id': resort.resort_id, 'marker': marker });

                        featureMap._styles.push({
                            url: '/js/images/map/m1.png',
                            textColor: '#fff',
                            textSize: 10,
                            width: 35,
                            height: 35,
                        });
                    }
                }


            }
            console.log("add { " + featureMap._markers.length + " } to cluster");
            featureMap._mc.addMarkers(featureMap._markers);

            featureMap._mc.setStyles([{
                url: '/js/images/map/m1.png',
                textColor: '#fff',
                textSize: 11,
                width: 60,
                height: 57,
                anchorIcon: [0, 0],
                anchorText: [100, 10],
                backgroundPosition: '100% 0'
            }]);
            // featureMap._mc.setupStyles({});

            featureMap._bounds_change();

        }
    },
    _setCurrentMarker: function (map, location) {
        var marker = new google.maps.Marker({
            position: { lat: location['latitude'], lng: location['longitude'] },
            map: map,
            optimized: false,
            icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: 'black',
            }
        });
    },

    _to_marker: function (lat, lng) {
        var bounds = new google.maps.LatLngBounds();

        featureMap._markers.forEach(function (marker, index) {

            if (marker.getPosition().lat() == lat && marker.getPosition().lng() == lng) {

                if (featureMap._prev_infowindow) {
                    featureMap._prev_infowindow.close();
                }

                featureMap._prev_infowindow = featureMap._info_window[index];

                featureMap._info_window[index].open(featureMap._map, marker);
                $('.gm-style-iw').siblings().hide();
            }
        });

        featureMap._map.panTo(new google.maps.LatLng(lat, lng));
        featureMap._map.setZoom(featureMap._max_zoom);
    },
    _setSingleBox: function (id, checkin, checkout, destination_id, category, wl, lat, lng) {
        var _route = '/resort/map/single-box/' + id + '/' + checkin + '/' + checkout + '?showbox=true&destination_id=' + destination_id + '&category=' + category + '&wl=' + wl;

        var _data = {};

        $(".short-item").removeClass('hover');

        if (lat != null && lng != null) {
            featureMap._to_marker(lat, lng);
        }

        $.ajax({ url: _route, data: _data, type: 'POST' }).done(function (_ajax_response) {

            if (_ajax_response.html) {
                $(".short-item[data-id=" + id + "]").addClass('hover');

                var infobox = $('<div>').css({ 'background-color': 'gray', 'display': 'block', 'height': '20px' });
                $('#mode-selector').html(
                    featureMap._htmlDecode(_ajax_response.html)
                ).css('visibility', 'visible');

                $('#mode-selector').inspira('_init');


                $('#mode-selector').draggable({
                    handle: ".selector-draggable"
                });


                $('div.hidebox').bind('click', function () {
                    $(".short-item").removeClass('hover');

                    $('#mode-selector').css('visibility', 'hidden');
                    featureMap._map.fitBounds(featureMap._bounds);
                });




            }
            return false;
        });
    },
    _setSingleMarker: function (map, location, destination_id, category, multiple, wl) {
        var image = '/css/v2/images/loc_map.png';


        var latLng = { lat: parseFloat(location['latitude']), lng: parseFloat(location['longitude']) };
        var marker = new google.maps.Marker({
            position: latLng,
            map: map,
            optimized: false,
            icon: image,
            zIndex: 99
        });

        featureMap._bounds.extend(latLng);
        featureMap._markers.push(marker);

        /*
              var currency = location['unit_cost_currency'];
              var cost = location['unit_cost'];
              */

        var price = location['availability']['parsed']['total'].replace('/+/', '');;
        var inventory_id = location['inventory_id'];
        var checkin = location['check_in_date'];
        var checkout = location['check_out_date'];
        if (multiple) {
            inventory_id = location['availability']['inventory_id'];
            checkin = location['availability']['check_in_date'];
            checkout = location['availability']['check_out_date'];
        }


        var infowindow = new google.maps.InfoWindow({
            content: '<div class="price-marker">' +
                '<span class="round"></span>' +
                '<div class="price">' +
                price +
                '</div>' +
                '<div class="info"><a data-role="change" data-id="' + location['resort_id'] + '" data-checkin="' + checkin + '" data-checkout="' + checkout + '" data-destination-id="' + destination_id + '" data-category="' + category + '" data-wl="' + wl + '">' + location['resort_name'] + ' &raquo;</a></div>' +
                '</div>',
            zIndex: 0
        });

        featureMap._info_window.push(infowindow);

        $('.price-marker').parents('.gm-style-iw').siblings().hide();

        marker.addListener('mouseover', function () {
            //close all info windows
            //        featureMap._hide_all_iw();


            if (featureMap._prev_infowindow) {
                featureMap._prev_infowindow.close();
            }

            featureMap._prev_infowindow = infowindow;

            infowindow.open(map, marker);

            var _stylebox = $(this).find('.gm-style-iw');
            _stylebox.css({ 'width': ($(this).find('.info').text().length * 11) + 'px!important' });

            //Remove close
            $('.gm-style-iw').siblings().hide();

            $('.gm-style-iw').bind('mouseover', function () {

                var _this = $(this);
                var _elements = _this.find('.price-marker').children();
                _elements.addClass('hover');

            });

            $('.gm-style-iw').find('a').on('click', function (e) {
                $('.left-scroll#page-items').animate({
                    scrollTop: $(".short-item[data-id=" + $(this).data('id') + "]").offset().top - 300
                }, 2000);

                $(".short-item[data-id=" + $(this).data('id') + "]").addClass('hover');

                featureMap._xhr.abort();

                featureMap._setSingleBox($(this).data('id'), $(this).data('checkin'), $(this).data('checkout'), $(this).data('destination-id'), $(this).data('category'), $(this).data('wl'));
            });

            $('.gm-style-iw').on('mouseout', function () {
                var _this = $(this);
                var _elements = _this.find('.price-marker').children();
                _elements.removeClass('hover');

            });

        });

    },
    _marker_click: function (content, resort, minPrice, minPriceObj) {
        var obj_content = content; //JSON.parse(content);
        var wl_desc_no_spaces = minPriceObj.wl.replace(/ /g, '_').toLowerCase();


        var wl_reverse = Object.keys(wls_lists).reverse();
        var wl_list_count = wl_reverse.length;
        var price = 0;
        var wl = 0;

        wl = featureMap._get_wl_id(wls_lists, wl_desc_no_spaces);


        //PRICE OPERATIONS
        var price = minPrice;
        console.log('minPrice >>>>>>>>>>>>>>>>>>>>>>>', price);
        //convert inventory price to page selected currency
        price = price_conversion_rate > 0 ? Math.round(price / price_conversion_rate) : price;
        var cash_amount = $("input[name='cash_amount']").val();
        //convert cash to page selected currency
        cash_amount = cash_conversion_rate > 0 ? Math.round(cash_amount / cash_conversion_rate) : cash_amount;
        //console.log('***price: ',price);

        var useCertificate = ($("input[name='certs']").is(':checked') || $("input[name='cards']").is(':checked'));
        var useCash = $("input[name='cash']").is(':checked');
        SnippetPrice.setPriceShow(show_cert_and_price)
        console.log('price >>>>>>>>>>>>>>>>>>>>>>>');
        console.log(price);

        price_array = featureMap._price_breakdown(card_cert_obj, cash_amount, useCash, price);
        price_html = featureMap._price_html(null, null, price, price_array, site_currency, useCertificate, useCash, 'address discount');
        if (is_condo_guest == true) {
            price_html = '';
        }


        //var url = '/resort/'+obj_content.resort_id+'?destination_id='+obj_content.destination_id+'&category='+obj_content.category+'&wl='+wl;
        var content_html =
            '<div id="pop_available_dates" style="min-width: 700px;"><div class="col-sm-12"> <div class="row" > <div class="header_wl">&nbsp;</div> </div> <div class="row"> <table class="table dates"> <thead> <tr class="first"> <th> </th> <th></th> <th></th> <th colspan="3"></th> </tr> <tr> <th>&nbsp;</th> <th>&nbsp;</th> <th>&nbsp;</th> <th>&nbsp;</th> <th>&nbsp;</th> <th>&nbsp;</th> </tr> </thead> <tbody> <tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr>' +
            '<tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr><tr><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td><td>&nbsp;</td></tr> </tbody> </table> </div> </div></div>' +
            '<div  class="hotel selector-draggable">' +
            '<div class="thumb">' +
            '<img src="' + obj_content.image + '" class="img-responsive img_pop_available_dates">' +
            '<div class="hidebox">X</div>' +
            '<span class="tag_wl ' + minPriceObj.wl.replace(/ /g, '_').toLowerCase() + '">' + minPriceObj.wl + '</span>' +
            '</div>' +
            '<div class="row info resort-info">' +
            '<div class="col-xs-12">' +
            '<h3>' + obj_content.resort_name + '</h3>' +

            '</div>' +
            '<div class="col-xs-12 address" style="font-size:1.2rem;">' +
            obj_content.address_line1 + ' ' +
            obj_content.address_city + ', ' + obj_content.address_state + ' ' +
            obj_content.address_country + ' ' + obj_content.address_postalcode +
            '</div>';
        content_html += '<p class="number_map"; style="margin:.3em 0; font-size:.8em;color: white; background-color: #ea732d;border-radius:.6rem;width: 2.3rem; height: 2.3rem;display: table-cell;  vertical-align: middle;text-align: center;">' + new Number(obj_content.average_rating).toFixed(1) + '</p>';
        if (resort.average_rating >= 4) {
            content_html += '<p style="margin:.3em 0; font-size:1.1rem;color:#ea732d; margin-left: 3rem;margin-top: -1.5rem;" class="rating-text rating_text_6444"><span>Excellent</span></p>';
        }
        else if (resort.average_rating >= 3 && resort.average_rating < 4) {
            content_html += '<p style="margin:.3em 0; font-size:1.1rem;color:#ea732d; margin-left: 3rem;margin-top: -1.5rem;" class="rating-text rating_text_6444"><span>Good</span></p>';
        }
        else {
            content_html += '<p style="margin:.3em 0; font-size:1.1rem;color:#ea732d; margin-left: 3rem;margin-top: -1.5rem;" class="rating-text rating_text_6444"><span>Normal</span></p>';
        }

        content_html += '<div class="col-xs-12 price-info text-right" style="margin-bottom:.2rem;">' +
            price_html +
            '</div>';
        if (is_condo_guest == true) {
            happi_price = price - 200;
            content_html += '<div style="" class="col-xs-12 nopadding">' +
                '<div style="padding-bottom: 2px; display: flex; justify-content: space-between;" class="col-xs-12 nopadding">' +
                '<span style="font-size: 10px;align-self: center;">Your rate: ' + site_currency + ' ' + price + '</span>' +
                '<a style="background-color: #465664;text-align: center;color: white;width: 30%;padding: .3rem;display: inline-block;font-size:' + '10px;border-radius: 5px!important;outline: none;" id="botonview" href="/resort/' + obj_content.resort_id + '///?wl=' + wl + '" data-map-open="true" > ' + language.inspira_view + ' </a>' +
                '<button style="display:none" id="pop_a_view_available_dates" rel="' + obj_content.resort_id + '" data-destination-id="' + obj_content.destination_id + '" data-category="' + obj_content.category + '" data-wl="">' + language.inspira_view + ' </button>' +
                '</div>' +
                '<div style="display: flex; justify-content: space-between;" class="col-xs-12 nopadding">' +
                '<span style="font-size: 10px;align-self: center;">HappiPrice: ' + site_currency + ' ' + happi_price + ' </span>' +
                '<a style="background-color: #ffaf57;text-align: center;color: white;width: 30%;padding: .3rem;display: inline-block;font-size:' + '10px;border-radius: 5px!important;outline: none;" onclick="funcion_happi()" > Upgrade </a>' +
                '</div>' +
                '</div>';
        }
        else {
            content_html += '<div class="col-xs-12 nopadding">' +
                '<a id="botonview" href="/resort/' + obj_content.resort_id + '///?wl=' + wl + '" target="_blank"  data-map-open="true" >' + language.inspira_view + '</a>'
                + '<button style="display:none" id="pop_a_view_available_dates" rel="' + obj_content.resort_id + '" data-destination-id="' + obj_content.destination_id + '" data-category="' + obj_content.category + '" data-wl="">' + language.inspira_view + '</button>'
                + '</div>';
        }

        content_html += '</div>' +
            '</div>';

        $('#mode-selector').html(content_html).css({
            visibility: 'visible'
        });



        $('#mode-selector').inspira('_init');
        $('#mode-selector').draggable({
            handle: ".selector-draggable"
        });

        //open view available dates
        if (!mobile) {
            $('#pop_a_view_available_dates').click();
        }


    },
    _hide_all_iw: function () {
        featureMap._info_window.forEach(function (iw) {
            iw.close();
        });
    },

    _mediumPoints: function (locations) {
        var medium = { lat: null, lng: null };
        var locLength = locations.length;

        if (locations.length > 1) {
            locations.forEach(function (location) {
                medium['lat'] += parseFloat(location['latitude']);
                medium['lng'] += parseFloat(location['longitude']);
            });
        }
        medium['lat'] = medium['lat'] / locLength;
        medium['lng'] = medium['lng'] / locLength;
        return medium;
    },
    _htmlDecode: function (input) {
        var e = document.createElement('div');
        e.innerHTML = input;
        return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
    },



    _get_markers_in_viewport: function () {
        var markers_in_vwprt = [];
        //extract all resorts visible in viewport from all markers
        var m = featureMap._markers;
        for (var i = 0, bounds = featureMap._map.getBounds(); i < m.length; i++) {
            if (typeof bounds !== "undefined" && bounds.contains(m[i].getPosition())) {
                markers_in_vwprt.push(m[i]);
            }
        }

        return _.sortBy(markers_in_vwprt, 'minimumPrice');
    },
    _get_markers_by_whitelabel: function (markers_in_viewport) {
        var resorts_by_wl = [];
        for (var key in wls_lists) {
            resorts_by_wl.push({ key: wls_lists[key].replace(/ /g, '_').toLowerCase(), desc: wls_lists[key], value: [] });
        }

        var wl_key;
        var marker_temp;
        for (var index = 0; index < markers_in_viewport.length; index++) {
            for (var indexWl = 0; indexWl < markers_in_viewport[index].resort.whitelabels.length; indexWl++) {
                wl_key = markers_in_viewport[index].resort.whitelabels[indexWl].desc.replace(/ /g, '_').toLowerCase();
                marker_temp = markers_in_viewport[index];
                _.find(resorts_by_wl, ['key', wl_key]).value.push(markers_in_viewport[index]);
                //minimum_price.push({ 'price': resort.whitelabels[index].price, 'wl': resort.whitelabels[index].desc });
            }

        }

        // console.log('***separate by whitelabel resorts_by_wl: ');
        // console.dir(resorts_by_wl);
        return resorts_by_wl;
    },

    _bounds_change: function (position_start, position_end, append, wl, filter_resort_array) {
        $("#footer").show();
        if (window.location.href.indexOf("?page=_map_search") > -1) {
            $("#footer").hide();
        }
        console.log("_bounds_change", mobile);
        // if (mobile) { return; }

        // if (!mobile) {
        var zoomLevel = featureMap._map.getZoom();
        $('#saveCustomLocation').removeClass('disabled');
        // Si el zoom es mayor a 6 o es mayor a 9 pero hay hoteles en el list view
        if (zoomLevel >= 6 || (typeof list_view !== "undefined" && zoomLevel >= 3)) {

            //get all markers visible in viewport
            var markers_in_viewport = featureMap._get_markers_in_viewport();

            // console.log('*********markers: ');
            // console.dir(markers_in_viewport);
            //separate by whitelabel resorts_by_wl
            var resorts_by_wl = featureMap._get_markers_by_whitelabel(markers_in_viewport);

            // console.log('*********markers by wl: ');
            // console.dir(resorts_by_wl);
            // console.log("**resorts_by_wl: ");
            // console.dir(resorts_by_wl);
            if (typeof list_view !== "undefined") {
                //List View
                var r_list;
                // for (var index_wl = 0; index_wl < resorts_by_wl.length; index_wl++) {
                //     if (typeof wl !== "undefined" && wl != resorts_by_wl[index_wl].key) {
                //         continue;
                //     }
                //     //validate if have to filter resorts
                //     if (typeof filter_resort_array !== "undefined" && filter_resort_array.length > 0) {
                //         var r_list_temp = _.findByValues(featureMap._markers_resort, 'resort_id', filter_resort_array);
                //         r_list = [];
                //         for (var indexlist = 0; indexlist < r_list_temp.length; indexlist++) {
                //             r_list.push(r_list_temp[indexlist].marker);
                //         }
                //     } else {
                //         r_list = resorts_by_wl[index_wl].value;
                //     }
                //
                //     //list view
                //     var resortListHtml = featureMap._get_resort_list_html(markers_in_viewport, position_start, position_end, resorts_by_wl[index_wl].key,'list_view');
                //     if (typeof append === "undefined") {
                //         $('#map-sidebar').html(resortListHtml);
                //     } else {
                //         $('#map-sidebar').append(resortListHtml);
                //     }
                //
                //     //change tab label, to add resort count
                //     $("a[href='#" + resorts_by_wl[index_wl].key + "']").html(resorts_by_wl[index_wl].desc + ' <small>(' + r_list.length + ')</small>');
                // }

                var resortListHtml = featureMap._get_resort_list_html(markers_in_viewport, position_start, position_end, undefined, 'list_view');
                if (typeof append === "undefined") {
                    $('#map-sidebar').html(resortListHtml);
                } else {
                    $('#map-sidebar').append(resortListHtml);
                }

            } else {
                //Map View
                var resortListHtml = featureMap._get_resort_list_html(markers_in_viewport, position_start, position_end);
                if (typeof append === "undefined") {
                    $('#page-items').html(resortListHtml);
                } else {
                    $('#page-items').append(resortListHtml);
                }

                if ($("#map-sidebar").hasClass("rpanelcollapsed")) {
                    var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
                    if (!isMobile) {
                        $("#map-sidebar").toggleClass("rpanelcollapsed");
                        $("#map-content").toggleClass("col-md-12 col-md-9 col-sm-12 col-sm-7");
                    }
                    $("#map").attr('style', 'opacity: 1; position: relative; overflow: hidden;height:calc( 100vh - 239px  ) !important');
                    $("#page-items").attr('style', 'height:calc( 100vh - 189px    ) !important');

                    $("footer").fadeOut();


                }
            }


        } else {
            //hide right panel
            if (typeof list_view === "undefined" && !$("#map-sidebar").hasClass("rpanelcollapsed")) {
                // $("footer").fadeIn();
                $("#map").attr('style', 'opacity: 1; position: relative; overflow: hidden;height:calc( 112vh - 338px ) !important');
                $("#page-items").attr('style', 'height:calc( 100vh - 338px ) !important');
                $("#map-sidebar").toggleClass("rpanelcollapsed");
                $("#map-content").toggleClass("col-md-12 col-md-9 col-sm-12 col-sm-7");
            }

            //remove Fusiontables layers (boundaries)
            if (typeof layerBoundaries !== 'undefined') {
                layerBoundaries.setMap(null);
            }

        }


        //}//mobile
        //create more filters????
        //create moreFilters
        //get all markers visible in viewport
        var markers_in_viewport = featureMap._get_markers_in_viewport();
        featureMap._min_max_price_markers(markers_in_viewport);


        let resorts = markers_in_viewport;
        let arrayInspiraID = resorts.map(r => r.resort.inspiraId);

        console.log(SERVICES_URL + '/resorts/amenities')
        var amenities = [];
        $.ajax({
            url: SERVICES_URL + '/resorts/amenities',
            type: 'POST',
            data: {
                "inspiraId": arrayInspiraID
            },
            crossDomain: true,
            async: false,
            success: function (_ajax_response) {
                if (_ajax_response && _ajax_response !== "undefined") {
                    amenities = _ajax_response;
                }
            }
        });

        let arrAmenities = amenities.length > 0 ? amenities[0].amenitiesTotals : [];

        let arrAmenitiesByResort = amenities.length > 0 ? amenities[0].amenitiesByResort : [];
        arrResortName = arrAmenitiesByResort.map(r => r.resortName);

        //var arrSeleccionados = [];
        // $('.custom-control-input').change(function() {
        if (arrSeleccionados.length > 0) {
            $('input.custom-control-input:checkbox:checked').each(function () {
                var sThisVal = $(this).attr('value');
                console.log("--array->", sThisVal)
                //arrSeleccionados.push(sThisVal)
                arrSeleccionados = [...new Set(arrSeleccionados)];
                console.log("arrSeleccionados despues de agregar", arrSeleccionados)
            });
            //});
        }

        console.log("arrSeleccionados despues ", arrSeleccionados);

        $('#amenitiesMoreFilters1').empty();
        $('#amenitiesMoreFilters2').empty();

        let lsmoreamenities = '';
        let lsamenities = '';
        let remainAmenities = (arrAmenities.length - 14) / 2;
        console.log("remainAmenities:", remainAmenities);
        let remainAmenities1 = 14 + remainAmenities;
        console.log("remainAmenities1:", remainAmenities1);
        SnippetMoreFilters.setCheckBan(false);

        for (let x = 0; x < arrAmenities.length; x++) {
            let amenity = (arrAmenities[x].amenity).replace(/\s/g, '');
            let amenity_lang = (arrAmenities[x].amenity).replace(/\s/g, '_');
            if (x <= 6) {
                $('#amenitiesMoreFilters1').append(
                    `       <div style="margin-bottom:10px">
                            <input type="checkbox" class="custom-control-input" id="${amenity}" value="${arrAmenities[x].amenity}" name="amenities">
                            <label  for="${amenity}">
                                <span style="padding-left:10px; margin-right: 5px">
                                    <img id="img_${amenity}" src="../css/svg/amenities/${amenity}Off.svg" height="30px" width="30px">
                                </span>
                                <span  style="font-weight: normal">${arrAmenities[x].amenity}  (${arrAmenities[x].count})</span>
                            </label>
                            </div>
                    `);

            } else if (x >= 7 && x <= 13) {
                $('#amenitiesMoreFilters2').append(
                    `       <div style="margin-bottom:10px">
                            <input type="checkbox" class="custom-control-input" id="${amenity}" value="${arrAmenities[x].amenity}" name="amenities">
                            <label  for="${amenity}">
                                <span style="padding-left:10px; margin-right: 5px">
                                    <img id="img_${amenity}" src="../css/svg/amenities/${amenity}Off.svg" height="30px" width="30px">
                                </span>
                                <span  style="font-weight: normal">${arrAmenities[x].amenity}  (${arrAmenities[x].count})</span>
                            </label>
                            </div>
                    `);
            } else if ((x >= 14) && (x < remainAmenities1)) {
                if (x == 14) {
                    $('#amenitiesMoreFilters1').append(`<span id="all-amenities1" style="display: none"></span>`);
                }
                $('#all-amenities1').append(
                    `<div class="row" style="margin-left:0; margin-bottom: 10px">
                        <input type="checkbox" class="custom-control-input" id="${amenity}" value="${arrAmenities[x].amenity}" name="amenities">
                        <label  for="${amenity}">
                            <span style="padding-left:10px; margin-right: 5px">
                                <img id="img_${amenity}" src="../css/svg/amenities/${amenity}Off.svg" height="30px" width="30px">
                            </span>
                            <span  style="font-weight: normal">${arrAmenities[x].amenity}  (${arrAmenities[x].count})</span>
                        </label>
                    </div>`);
            } else {
                if (x > remainAmenities1) {
                    $('#amenitiesMoreFilters2').append(`<span id="all-amenities2"  style="display: none"></span>`);
                }
                $('#all-amenities2').append(
                    `<div class="row" style="margin-left:0; margin-bottom: 10px">
                        <input type="checkbox" class="custom-control-input" id="${amenity}" value="${arrAmenities[x].amenity}" name="amenities">
                        <label  for="${amenity}">
                            <span style="padding-left:10px; margin-right: 5px">
                                <img id="img_${amenity}" src="../css/svg/amenities/${amenity}Off.svg" height="30px" width="30px">
                            </span>
                            <span  style="font-weight: normal">${arrAmenities[x].amenity}  (${arrAmenities[x].count})</span>
                        </label>
                    </div>`);
            }

        }
        // $('#amenitiesMoreFilters1').append(`<span id="all-amenities1"></span>`);
        // $('#amenitiesMoreFilters2').append(`<span id="all-amenities2"></span>`);

        if (arrAmenities.length > 14) {

            $('#amenitiesMoreFilters1').append(`<input type="checkbox" id="show-all-amenities1" style="display: none;" onClick="showAll()">
            <label id="lblShowAll1" style="color: #465665" for="show-all-amenities1">${language_more_filters.show_all} <i class="fa fa-caret-down">`);

        }


        //$('#amenitiesMoreFilters2').append(`<input type="checkbox" id="show-all-amenities2" style="display: none;" onClick="showAll()">
        //<label id="lblShowAll2" for="show-all-amenities2">Show all <i class="fa fa-caret-down">`);

        for (let x = 0; x < arrSeleccionados.length; x++) {
            $(`#${arrSeleccionados[x].id}`).attr('checked', 'checked');
            console.log("arrSeleccionados[x].id", arrSeleccionados[x].id)
            $(`#img_${arrSeleccionados[x].id}`).attr('src', `../css/svg/amenities/${arrSeleccionados[x].id}On.svg`)
        }

        $('.custom-control-input').change(function () {
            let banCheked = 0;
            $('input.custom-control-input:checkbox:checked').each(function () {
                var sThisVal = $(this).attr('value');
                var id = $(this).attr('id');
                console.log("value:", sThisVal)
                console.log("id:", id)
                $(`#img_${id}`).attr('src', `../css/svg/amenities/${id}On.svg`)
                console.log("arrSeleccionados --->", arrSeleccionados)
                banCheked++;
            })

            //var id = $(this).attr('id');


            //  if($(this).prop("checked") == false){
            //     console.log("Checkbox is checked.");
            //     var sThisVal = $(this).attr('value');
            //     var id = $(this).attr('id');
            //     console.log("value:", sThisVal)
            //     console.log("id:", id)
            //     $(`#img_${id}`).attr('src','../css/svg/amenities/SpaOn.svg')
            //  }else
            if ($(this).prop("checked") == false) {
                var id = $(this).attr('id');
                $(`#img_${id}`).attr('src', `../css/svg/amenities/${id}Off.svg`)
                console.log("Checkbox is unchecked.");


                // const index = arrSeleccionados.indexOf(id);
                // if (index > -1) {
                //     arrSeleccionados.splice(index, 1);
                // }
                // console.log("arrSeleccionados despues de remover", arrSeleccionados)

            }
            if (banCheked > 0) {
                SnippetMoreFilters.setCheckBan(true);
            } else {
                SnippetMoreFilters.setCheckBan(false);
            }
        });






    },
    _get_resort_list_html: function (markers_in_viewport, position_start, position_end, wl, type_view) {
        total_markers = markers_in_viewport.length;
        //validations
        if (typeof markers_in_viewport !== "undefined" && markers_in_viewport.length <= 0) {
            if (typeof list_view !== "undefined") {
                return '';
            } else {
                return '<div class="not_results_container"><div class="row"><div class="col-sm-12 text-center t_all_inclusive" style="left: 0rem"><h1>' + language.map_view_no_results_title + '</h1></div></div>' +
                    '<div class="row"><div class="col-sm-12 text-center t_all_inclusive" style="left: 0rem"><p>' + language.map_view_no_results_desc + '</p></div></div>' +
                    '<div class="row"><div class="col-sm-12 "><img src="images/no_results.jpg" style="width:100%;" /></div></div>' +
                    '</div>';
            }
        }
        position_start = (typeof position_start === "undefined") ? 0 : position_start;
        position_end = (typeof position_end === "undefined") ? 10 : position_end;
        wl = (typeof wl === "undefined") ? '' : wl;
        var html;
        //console.dir( _.sortBy( p, 'minimumPrice') );
        //var arr = _.slice( _.sortBy( markers_in_viewport, 'minimumPrice'), position_start, position_end) ;
        var sort_by_column = featureMap._sort_by == 'price' ? 'minimumPrice' : featureMap._sort_by;
        var arr = _.slice(_.orderBy(markers_in_viewport, [sort_by_column], [featureMap._sort_by_order]), position_start, position_end);

        html = '';

        var price = 0,
            remaining = 0,
            temp_diff = 0;
        var cash_amount = $("input[name='cash_amount']").val() !== undefined ? Number($("input[name='cash_amount']").val()) : 0;
        //convert cash to page selected currency
        cash_amount = cash_conversion_rate > 0 ? Math.round(cash_amount / cash_conversion_rate) : cash_amount;
        var strPrice = '';
        var strWalletPrice = '';

        for (var index = 0; index < arr.length; index++) {

            //PRICE OPERATIONS
            if (wl.length > 0) {
                for (var idxwl = 0; idxwl < arr[index].resort.whitelabels.length; idxwl++) {
                    if (wl == arr[index].resort.whitelabels[idxwl].desc.replace(/ /g, '_').toLowerCase()) {
                        price = arr[index].resort.whitelabels[idxwl].price;
                    }
                }
            } else {
                price = arr[index].minimumPrice;
            }

            price = price_conversion_rate > 0 ? Math.round(price / price_conversion_rate) : price;

            var useCertificate = ($("input[name='certs']").is(':checked') || $("input[name='cards']").is(':checked'));
            //console.log('use certificate...: ' + useCertificate);
            console.log('useCertificate >>>>>>>>>', useCertificate, card_cert_obj);
            var useCash = $("input[name='cash']").is(':checked');
            //console.log('use cash...: ' + useCash + ', amount..: ' + cash_amount);
            SnippetPrice.setPriceShow(show_cert_and_price)

            //get wl ID
            var wl_desc_no_spaces = wl.length > 0 ? wl : arr[index].minimumPriceObj.wl.replace(/ /g, '_').toLowerCase();
            var wl_id = featureMap._get_wl_id(wls_lists, wl_desc_no_spaces);
            var wl_description = featureMap._get_wl_desc(wls_lists, wl_desc_no_spaces);

            price_array = featureMap._price_breakdown(card_cert_obj, cash_amount, useCash, price);
            price_html = featureMap._price_html(arr[index].resort.resort_id, wl_id, price, price_array, site_currency, useCertificate, useCash, 'discount');

            var resort_obj = {
                'resort_id': arr[index].resort.resort_id,
                'check_in_date': arr[index].resort.check_in_date,
                'wl_title_no_spaces': wl_desc_no_spaces,
                'wl_title': wl_description,
                'wl_id': wl_id,
                'view_btn': '',
                'price_html': price_html
            };
            console.log('************************pasa aqui00000000000000');
            if (typeof list_view !== "undefined") {
                //list view
                resort_obj.price_html = price_html.replaceAll("width: 40%;", "");
                html += featureMap._resort_info_listview_html(resort_obj);
            } else {
                //remove redirection VIEW button in map view
                console.log('************************pasa aqui111111111');
                resort_obj.price_html = price_html.replace("location.href=", "");
                //map view
                html += featureMap._resort_info_html(resort_obj);
            }


            //get resort info
            $.ajax({
                url: SERVICES_URL + '/resorts',
                type: 'GET',
                crossDomain: true,
                data: 'resort_id=' + arr[index].resort.resort_id + '&search_type=country&language=en',
                async: true,
                success: function (_ajax_response) {
                    if (_ajax_response && _ajax_response !== "undefined") {
                        var resort = _ajax_response; //JSON.parse(_ajax_response);
                        jQuery('.img_' + resort.resort_id).css({ "background-image": "url(" + resort.image + ")" });
                        jQuery('.rn_' + resort.resort_id).text(resort.resort_name);
                        jQuery('.radd1_' + resort.resort_id).text(resort.address_line1);
                        jQuery('.radd2_' + resort.resort_id).text(resort.address_city);
                        // jQuery('.rating_' + resort.resort_id).html(' <span class="star-' + Math.floor(new Number(resort.average_rating)) + '"></span>');
                        jQuery('.rating_number_' + resort.resort_id).html(new Number(resort.average_rating).toFixed(1));
                        if (resort.average_rating >= 4) {
                            jQuery('.rating_text_' + resort.resort_id).html('<span>Excellent</span>');
                        }
                        else if (resort.average_rating >= 3 && resort.average_rating < 4) {
                            jQuery('.rating_text_' + resort.resort_id).html('<span>Good</span>');
                        }
                        else {
                            jQuery('.rating_text_' + resort.resort_id).html('<span>Normal</span>');
                        }

                        if (typeof resort.amenities !== "undefined" && resort.amenities.length > 0) {
                            var concat_amenities = '';
                            more_amenities = resort.amenities.length - 4;

                            if (more_amenities <= 0) {
                                more_amenities = '';
                            }

                            for (var index = 0; index < resort.amenities.length; index++) {

                                concat_amenities += '<img style="height: 2.5rem;width: 2.5rem;margin-right: 1rem;"src="../css/v2/images/amenities/' + resort.amenities[index].replace(/ /g, "_") + '.svg" title="' + resort.amenities[index] + '">';
                                if (index == 3) {
                                    break;
                                }
                            }
                            if (more_amenities > 0) {
                                concat_amenities += '<span style="font-size: 1.5rem;font-family: fantasy;padding-left: 4%; ">+' + more_amenities + '</span>';
                            }
                        }

                        jQuery('.amenities_list_view_' + resort.resort_id).html(concat_amenities);

                    }
                }
            });


        }


        if (typeof footerVar == "undefined") {
            footerVar = "";
        }

        var anchor_button = 'width:75%;';
        var padding = 'padding: 0.4rem 1rem;';
        var font_size = 'font-size: 2rem;';

        if (type_view == 'list_view') {
            anchor_button = 'width:40%;';
            padding = 'padding: .9rem 1.5rem;';
            font_size = 'font-size: 2.3rem;'
        }

        console.log('resorts_showed: ' + resorts_showed + ' total_markers: ' + total_markers);
        if (resorts_showed < total_markers) {
            html +=
                '<div class="row"><div style="justify-content: center;display: flex;" class="col-sm-12 loadmorecontainer"><button id="load_more_resorts" style="' + anchor_button + padding + font_size + '" class="loadmore" data-wl="' + wl + '">' + language.btn_load_more_text + '</button></div></div>';

            // $(".load_more_resorts").show();
        }
        else {
            $(".load_more_resorts").hide();
        }
        if (typeof hide_footer == 'undefined') {
            var elements = arr.length > 0 ? 0 : $('.short-item.hotel').length;
            if (typeof footerVar != "undefined") {
                html +=
                    '<div class="row pie" style="height:calc(100VH - 391PX   - ' + ((arr.length + elements) * 148) + 'px)"></div>' +
                    '<div class="footerlistview" style=" margin-top: 15px">' + footerVar + '</div>';
            }
        }
        return html;
    },
    _resort_info_html: function (resort_obj) {
        var html =
            '<div class="short-item hotel" data-role="changex" data-resort-id="' + resort_obj.resort_id + '" data-checkin="2018-09-22" data-checkout="2018-09-29" data-category="city" data-destination-id="203" onclick="">' +
            '<div class="container resort-info-mini">' +
            '<div class="row info_mobile count_divs">' +
            '<div class="col-xs-4 img_' + resort_obj.resort_id + '" style="background-image:url(); background-size: cover; background-position: 50%;height: 13rem;" />' +
            '<div class="col-xs-8 text-left">' +
            '<div style="float:right;margin-right:-18px;margin-top:-3px;padding:.2em 1.8em; font-size:0.7em;" class="' + resort_obj.wl_title_no_spaces + '">' + resort_obj.wl_title + '</div>' +
            '<p style="margin: .3em 0;font-weight:bold;max-height: 3.2em;overflow:hidden;font-size:.9em;" class="rn_' + resort_obj.resort_id + '"></p>' +
            '<p style="max-width: 70%; margin:0; color:gray;font-size:.7em;" class="radd1_' + resort_obj.resort_id + '"></p>' +
            '<p style="color:gray;font-size:.7em;" class="radd2_' + resort_obj.resort_id + '"></p><br>' +
            '<div class="row">' +
            '<div class="col-sm-5 col-xs-8" style="position: absolute;bottom: -30%;">' +


            // '<p style="margin:.3em 0; font-size:.8em;color:#ea732d" class="rating_' + resort_obj.resort_id + '"><span class="star-3"></span></p>' +

            resort_obj.view_btn +
            '</div>' +
            '</div>' +

            '</div>' +
            '<div  class="col-xs-8 text-left test" style="position: relative;">' +
            '<p style="margin:.3em 0; font-size:.8em;color: white; background-color: #ea732d;border-radius:.6rem;width: 2.3rem; height: 2.3rem;display: table-cell;  vertical-align: middle;text-align: center;" class="number_ map rating_number_' + resort_obj.resort_id + '"><span class="star-3"></span></p>' +
            '<p style="margin:.3em 0; font-size:1.1rem;color:#ea732d; margin-left: 3rem;margin-top: -1.5rem;" class="rating-text rating_text_' + resort_obj.resort_id + '"></p>' +
            '</div>' +
            '</div>' +
            '<div class="price_position">' +
            resort_obj.price_html +
            '</div>' +
            '</div>' +
            '</div>';
        console.log('************************pasa aquireturn');
        return html;
    },
    _resort_info_listview_html: function (resort_obj) {
        var html =

            '<div style="float:right;margin-right:-15px;margin-top:0px;padding:.2em 1.8em; font-size:0.7em;" class="type_resort_mobile ' + resort_obj.wl_title_no_spaces + '">' + resort_obj.wl_title + '</div>' +
            '<div style="box-shadow: 2px 2px 10px 3px #e4e4e4;border-color: #e4e4e4 !important;" class="row resort-info fit-content count_divs" data-role="changex" data-resort-id="' + resort_obj.resort_id + '" data-checkin="2018-09-22" data-checkout="2018-09-29" data-category="city" data-destination-id="203" onclick="">' +
            '<div class="col-sm-3 nopadding resort_image_mobile">' +
            '<a href="" class="resort-thumb" data-role="redirect">' +
            '<div class="fit-in fit-in-img img_' + resort_obj.resort_id + '" style="background-image:url();min-height:19rem;height: 100%;"></div>' +
            '</a>' +
            '</div>' +
            '<div class="col-sm-5">' +
            '<div class="row description fit-in">' +
            '<div>' +
            '<h3 class="rn_' + resort_obj.resort_id + '"></h3>' +
            '</div>' +
            '<div style="color: #949EA6;">' +
            '<span class="radd1_' + resort_obj.resort_id + '"></span>,&nbsp;' +
            '<span class="radd2_' + resort_obj.resort_id + '"></span>' +
            // '<p style="margin:.3em 0; font-size:1.1rem;color:#ea732d" class="rating_' + resort_obj.resort_id + '"></p>'+





            '</div>' +

            '</div>' +
            '<div class="details det_mobile" style="position: absolute;top: 135%;font-family: popppins;color: #949EA6;">' +
            '<p style="margin:.3em 0; font-size:1rem;color: white; background-color: #ea732d;border-radius:.6rem;width: 2.5rem; height: 2.5rem;display: table-cell;  vertical-align: middle;text-align: center;" class="rating_number_' + resort_obj.resort_id + '"><span class="star-3"></span></p>' +
            '<p style="margin:.3em 0; font-size:1.1rem;color:#ea732d;margin-left: 3rem; margin-top: -1.5rem;" class="rating_text_' + resort_obj.resort_id + '"></p>' +
            '<p style="margin:.3em 0; font-size:.8rem;margin-top: -3rem;" class="amenities_mobile amenities_list_view_' + resort_obj.resort_id + '"></p>' +
            '<a class="hide_when_mobile available_button" data-toggle="modal" data-target="#modal_available_dates" rel="' + resort_obj.resort_id + '" style="">' + language.btn_view_avaible_dates_text + ' ></a>' +

            '</div>' +

            '</div>' +

            '<div class="col-sm-4 price-info price_info_mobile" style="line-height: 200%;letter-spacing: 1px;font-family: poppins;text-align: right;">' +
            resort_obj.price_html +
            '<div class="row">';
        if (is_condo_guest != true) {
            html += '<div class="col-xs-10 button_view_web" style="padding-right: 0px;">' +
                '<a href="/resort/' + resort_obj.resort_id + '/' + resort_obj.check_in_date + '//?wl=' + resort_obj.wl_id + '" target="_blank">' +
                language.inspira_view +
                '</a>' +
                '</div>';
        }
        html += '</div>' +
            '</div>' +
            '</div>';

        return html;
    },
    _price_breakdown: function (certificate, cash_amount, useCash, price) {
        var remaining = 0;
        var response = {
            "certificate": { "amount": 0, "type": null },
            "cash_amount": 0,
            "remaining_amount": 0
        };

        console.log("cash_amount:", cash_amount);
        console.log("price:", price);
        console.log("useCash:", useCash);
        console.log("certificate original >>>>>>>>>>>>:", certificate);
        //validations
        if (price !== null && price > 0) {

            //init
            remaining = price;
            cash_amount = cash_amount > 0 ? cash_amount : 0;

            //Certificate & Cards
            if (certificate !== null && certificate.amount > 0) {
                //Certificates
                if (certificate.type.toUpperCase() == "CERTIFICATE") {
                    remaining -= certificate.amount;
                    response.certificate.amount = 1;
                    response.certificate.type = certificate_key_lang;
                }
                //cards
                else if (certificate.type.toUpperCase() == "CARD") {
                    remaining -= certificate.amount;
                    response.certificate.amount = 1;
                    response.certificate.type = certificate.type;
                }
            }

            //cash
            if (remaining > 0 && useCash) {
                response.cash_amount = cash_amount > remaining ? remaining : cash_amount;
                remaining -= response.cash_amount;
            }

            console.log("ramaining:", remaining)
            //remaining
            response.remaining_amount = remaining > 0 ? remaining : 0;
        }
        //console.dir(response);
        return response;
    },
    _price_html: function (resort_id, whitelabel_id, price, price_object, currency, use_cert_card, useCash, class_already_covered) {

        const labelTypeItem = price_object.certificate.type == 'card' ? 'Card' : certificate_key_lang;
        const labelTypeItem2 = price_object.certificate.type == 'card' ? 'card' : certificate_key_lang.substring(0, 4);


        var remainig_amount = Number(price_object.remaining_amount) > 0 ? currency + ' ' + Number(price_object.remaining_amount).format() : currency + ' 0';
        var price_label = price_object.certificate.amount > 0 ? price_key_lang + ': ' + price_object.certificate.amount + ' ' + labelTypeItem2 + ' + ' + remainig_amount : price_key_lang + ': ' + currency + ' ' + price;
        var happi_price = price - 200;
        happi_price = happi_price.toString();

        if (show_cert_and_price == 'old_version_prices' || show_cert_and_price == 'true') {
            var html = '';
            if (is_condo_guest == true) {
                if (resort_id != null && whitelabel_id != null) {
                    //href="/resort/' + resort_obj.resort_id + '/' + resort_obj.check_in_date + '//?wl=' + resort_obj.wl_id + '"
                    let href_string = "onclick=\"location.href='/resort/" + resort_id + "/" + "null/?wl=" + whitelabel_id + "" + "';\"";

                    html += '<span class="span-view" style="color: #808080; font-size: 1rem;width: 40%;display: inline-flex; margin-right: 0.5rem;">Your rate: ' + currency + ' ' + price + '</span><input ' + href_string + ' class="reserve_button" type="button" style="vertical-align: bottom;border-color: transparent;background-color: #465664;text-align: center;color: white;width: 37%;display: inline-block;font-size: 10px;border-radius: 5px!important;outline: none;margin-bottom: 2px;" value="View" ><br>';
                    html += '<span class="span-happi" style="color: #f8c910; font-size: 1rem; padding-top: 3px;width: 40%;display: inline-flex;margin-right: 0.5rem;">HappiPricess: ' + currency + ' ' + happi_price + '</span><input class="happi_button" type="button" style="vertical-align: bottom;border-color: transparent;background-color: #ffaf57;text-align: center;color: white;width: 37%;display: inline-block;font-size: 10px;border-radius: 5px!important;outline: none;" value="Upgrade" onclick="funcion_happi()" >';
                }
            }
            else {
                html += '<span style="color: #808080;font-size: 1rem;">' + price_label + '</span>';
            }

            html += '' +
                (price_object.certificate.amount > 0 ? ('<div style="color:#EA732E" class="cash ' + (use_cert_card ? (class_already_covered + ' ' + price_object.certificate.type) : '') + '"><span></span>' + price_object.certificate.amount + ' ' + labelTypeItem + '</div>') : '') +
                (price_object.cash_amount > 0 && useCash ? ('<div style="color:#EA732E" class="cash ' + class_already_covered + '"><span></span>' +
                    (price_object.certificate.amount > 0 ? '+ ' : '') +
                    currency + ' ' + price_object.cash_amount +
                    '</div>') : '');
            if (is_condo_guest != true) {
                html += (Number(price_object.remaining_amount) > 0 ? ('<div class="total" style="font-size: 1.9rem; margin-bottom: 1.5rem;">' +

                    /*((price_object.certificate.amount + price_object.cash_amount) > 0 ? '+ ' : '') +*/
                    currency + ' ' + Number(price_object.remaining_amount).format() +
                    '</div>') : '');
            }
            return html;
        }
        else {
            var html = '<div class="total" style="font-size: 1.9rem; margin-bottom: 1.5rem;">' +
                currency + ' ' + Number(price).format() +
                '</div>';
            return html;
        }
    },
    _map_click: function () {
        //console.log('Map was clicked!');
        if ($('#mode-selector').css("visibility") == "visible") {
            $('#mode-selector').css('visibility', 'hidden');
        }
        if ($('#map_messages').css("visibility") == "visible") {
            $('#map_messages').css('visibility', 'hidden');
        }
    },
    _wl_click: function (elem, wl_obj) {
        console.log('_wl_click()....');

        var data = { wls_selection: JSON.stringify(wl_obj) };
        console.log('click on wl {', elem.attr('id'), '}:', data);
        $.ajax({
            url: elem.attr('data-route'),
            data: data,
            type: 'POST'
        }).done(function (_ajax_response) {
        });
    },


    _wallet_click: function (elem) {
        console.log('_wallet_click');
        NProgress.start();
        var selected = elem.attr('name') == 'card' ? 1 : (elem.is(':checked') ? 1 : 0);
        var currency = $("select[name='currency'] option:selected").val();
        var card_id = $("select[name='card'] option:selected").val();
        var cash_amount = $("input[name='cash_amount']").val();
        //console.log('click on wallet...: ' + selected);

        var data = {};
        var wallet_type = elem.attr('name') == 'card' ? 'cards' : elem.attr('name');
        data[wallet_type] = selected;
        data['currency'] = currency;
        data['card'] = card_id;
        data['cash_amount'] = cash_amount;
        $.ajax({
            url: elem.attr('data-route'),
            data: data,
            type: 'POST'
        }).done(function (_ajax_response) {
            NProgress.done();

            //TODO: update card object with amount of card selected
            //card_cert_obj = { "amount": card_amount, type: "card" };
            if (card_cert_obj != null && typeof _ajax_response !== "undefined" && typeof _ajax_response.amount !== "undefined") {
                //console.log('wallet click response..: ' + _ajax_response.amount);
                card_cert_obj.amount = _ajax_response.amount;
            }


            //refresh resort listed
            featureMap._bounds_change();
            //close open dialogs
            featureMap._map_click();


        });
    },
    _get_available_dates: function (resort_id, element_to_load_response) {
        //get whitelabels selected
        var wls_selected = '';
        $.each(wls_lists, function (index, value) {
            var is_active = $('#' + index + ' input[type="checkbox"]').is(':checked');
            if (is_active || $('#topnav').is(':hidden')) {  // SI ES ACTIVO O ESTA ESCONDIDO SIEMPRE SE CONCATENA WL
                wls_selected += index + ',';
            }
        });
        wls_selected = wls_selected.slice(0, -1);

        //call api to get available dates
        var check_in_date = isNaN(Date.parse($('.form_datetime').datepicker('getDate'))) ? null : featureMap._get_date(0);
        $.ajax({
            url: '/resort_available_dates',
            type: 'GET',
            data: {
                resort_id: resort_id,
                check_in_date: check_in_date,
                wl: wls_selected,
                destination_id: null,
                category: null,
                max_amount: _max_price,
                comboWeeks: $('#comboWeeks').val()
            },
            async: true,
            success: function (_ajax_response) {
                element_to_load_response.html(_ajax_response);
            }
        });
    },
    _change_viewport: function (attributes_obj) {
        //console.log('_change_viewport() attributes: ', attributes_obj);
        if (typeof featureMap._map !== "undefined" && (attributes_obj.bounds || attributes_obj.areabounds)) {
            var newZoom = Number(attributes_obj.zoom);
            if (newZoom != 0) {

                featureMap._map.setZoom(newZoom);
            }
            if (attributes_obj.areabounds) {
                var bounds = new google.maps.LatLngBounds(
                    /* sw */
                    { lat: attributes_obj.areabounds.south, lng: attributes_obj.areabounds.west },
                    /* ne */
                    { lat: attributes_obj.areabounds.north, lng: attributes_obj.areabounds.east });
                console.log('set new viewport bounds: ', bounds);
                featureMap._map.fitBounds(bounds);
            } else {
                var center = new google.maps.LatLng(attributes_obj.bounds.lat, attributes_obj.bounds.lng);
                featureMap._map.setCenter(center);
            }

        }
    },
    _map_search: function (attributes_array) {
        //console.log('transform to map search....');

        TweenMax.to('#search .container div.row h2, #search .container div.row p', .2, { autoAlpha: 0, display: "none", x: -1500 });
        TweenMax.to('#search', .1, { className: "+=searchrow" });
        TweenMax.to('.destinations, #what-is, #we-believe,#places, .bg-light-blue-about,.companies-benefited,.bg-light-blue-work, #size-slider-customer, #title-slider-customer,#reviewscarousel,#wrapper-fountain, .what-is ', .2, { autoAlpha: 0, display: "none", x: -1500 });

        TweenMax.set('#map', { opacity: 0 });
        TweenMax.to('#map', .7, { className: "+=map_show", opacity: 1 }, 0.1);

        $('#row-map-main').show();
        $('#search_listview').show();
        $('#search_listview').addClass('col-md-2 col-sm-2');
        if ($('#search_input').hasClass('col-md-6')) {
            $('#search_input').toggleClass('col-md-6 col-md-4');
        }
        if ($('#search_input').hasClass('col-sm-6')) {
            $('#search_input').toggleClass('col-sm-6 col-sm-4');
        }

        //$('#search_calendar').toggleClass('col-md-4 col-md-3');
        //$('#search_calendar').toggleClass('col-sm-6 col-sm-2');
        $('#search_calendar').attr('placeholder', '');
        $('#search_btn').removeClass('col-md-2');
        $('#search_btn').addClass('col-md-1');
        $('#srch_row').removeClass('col-md-10');
        $('#srch_row').removeClass('col-md-offset-1');
        $('#srch_row').addClass('col-md-12');
        $('#user_wallet').removeClass('col-md-1');
        $('#user_wallet').addClass('col-md-3 col-sm-4');

        $('#map-more-filters').css('display', 'block');//more filters

        //load wallet Panel
        $.ajax({
            url: '/user_wallet',
            type: 'GET',
            success: function (_ajax_response) {
                //console.log('user wallet response: ' + _ajax_response);
                $('#user_wallet').html(_ajax_response);
            }

        });

        //update map position
        if (typeof attributes_array !== "undefined" && attributes_array.length > 0) {
            var attributes_obj = attributes_array[0];
            featureMap['_change_viewport'](attributes_obj);
        }
    },
    _get_wl_id: function (wls_lists_obj, wl_desc_no_spaces) {
        var wl_id;
        for (var key in wls_lists_obj) {
            if (wls_lists_obj[key].replace(/ /g, '_').toLowerCase() == wl_desc_no_spaces) {
                wl_id = key;
                break;
            }
        }
        return wl_id;
    },
    _get_wl_desc: function (wls_lists_obj, wl_desc_no_spaces) {
        var wl_desc;
        for (var key in wls_lists_obj) {
            if (wls_lists_obj[key].replace(/ /g, '_').toLowerCase() == wl_desc_no_spaces) {
                wl_desc = wls_lists_obj[key];
                break;
            }
        }
        return wl_desc;
    },
    _get_resort_name_list_html: function (response_container) {
        /* get selected tab to filters resorts */
        var wl_title_no_spaces = $('#resort_tabs li.active a').attr('aria-controls');

        //get all markers visible in viewport
        var markers_in_viewport = featureMap._get_markers_in_viewport();

        //separate by whitelabel resorts_by_wl
        var resorts_by_wl = featureMap._get_markers_by_whitelabel(markers_in_viewport);

        /* get resort list by whitelabel selected */
        var r_list;
        for (var index_wl = 0; index_wl < resorts_by_wl.length; index_wl++) {
            if (wl_title_no_spaces == resorts_by_wl[index_wl].key) {
                r_list = resorts_by_wl[index_wl].value;
                break;
            }
        }

        var resort_list_html = '';
        for (var index = 0; index < r_list.length; index++) {
            resort_list_html +=
                '<div class="col-sm-8">' +
                '<div class="checkbox">' +
                '<label>' +
                '<input type="checkbox" value="' + r_list[index].resort.resort_id + '" checked="">' +
                '<span class="cr"><i class="cr-icon glyphicon glyphicon glyphicon-ok"></i></span>&nbsp;' +
                '<span class="namex" id="rl_pop_rn_' + r_list[index].resort.resort_id + '">...</span>' +

                '</label>' +
                '</div>' +
                '</div>' +
                '<div class="col-sm-2">' +
                '<span id="rl_pop_rating_' + r_list[index].resort.resort_id + '"></span>' +
                '</div>' +
                '<div class="col-sm-2">' +
                '<span>' + site_currency + ' ' + r_list[index].minimumPrice + '</span>' +
                '</div>';

            //get resort info
            $.ajax({
                url: SERVICES_URL + '/resorts',
                type: 'GET',
                crossDomain: true,
                data: 'resort_id=' + r_list[index].resort.resort_id + '&search_type=country&language=en',
                async: true,
                success: function (_ajax_response) {
                    if (_ajax_response && _ajax_response !== "undefined") {
                        var resort = _ajax_response; //JSON.parse(_ajax_response);
                        jQuery('#rl_pop_rn_' + resort.resort_id).text(resort.resort_name);
                        jQuery('#rl_pop_rating_' + resort.resort_id).html(' <span class="star-' + Math.floor(new Number(resort.average_rating)) + '"></span>');
                    }
                }
            });

        }

        var html =
            '<div class="row">' +
            '<div class="col-xs-12">' +
            '<h4><i class="fa fa-list"></i> Filter Resorts</h4>' +
            '<small>select resorts to filter</small>' +
            '</div>' +
            '</div>' +
            '<div class="row"><hr></div>' +
            '<div class="row filter_resort_list_container" id="listx">' +
            resort_list_html +
            '</div>' +
            '<div class="row" style="margin-top:1rem;">' +
            '<div class="col-md-12">' +
            '<div class="row">' +
            '<div class="col-sm-6">' +
            '<a href="#" id="uncheck-all" class="btn-cancel uncheck">Check / Uncheck All</a>' +
            '</div>' +
            '<div class="col-sm-6">' +
            '<a href="#" id="lv_filter_resorts" class="btn-continue" data-dismiss="modal">OK</a>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>';

        response_container.html(html);
    },


    _more_filters: function (filter) {
        var filtered = featureMap._resort_with_wls_full.filter(
            function (value, index, arr) {
                //return ( ((value.min_price / price_conversion_rate) >= filter.price[0] &&  (value.min_price / price_conversion_rate) <= filter.price[1]) && (value.rating >= filter.stars[0] && value.rating <= filter.stars[1]) && (value.guest_rating >= filter.rating[0] && value.guest_rating  <= filter.rating[1]) );
                return ((value.min_price >= filter.price[0] && value.min_price <= filter.price[1]) && (value.rating >= filter.stars[0] && value.rating <= filter.stars[1]) && (value.guest_rating >= filter.rating[0] && value.guest_rating <= filter.rating[1]));


            }
        );

        featureMap._mc.clearMarkers();
        featureMap._draw_cluster(filtered);
    },

    _reset_more_filters: function () {
        console.log("_reset_more_filters")
        arrSeleccionados = []
        $('#amenitiesMoreFilters').empty();

        //reset more filters slider
        var slider = document.getElementById('slider');
        if (slider != null && slider.noUiSlider !== undefined) {
            //slider.noUiSlider.reset();
            slider.noUiSlider.destroy();
        }

        var starsSlider = document.getElementById('starsSlider');
        if (starsSlider != null && starsSlider.noUiSlider !== undefined) {
            starsSlider.noUiSlider.reset();
        }

        var ratingSlider = document.getElementById('ratingSlider');
        if (ratingSlider != null && ratingSlider.noUiSlider !== undefined) {
            ratingSlider.noUiSlider.reset();
        }

        var priceSliderBar = document.getElementById('priceSliderBar');
        if (priceSliderBar != null && priceSliderBar.noUiSlider !== undefined) {
            priceSliderBar.noUiSlider.reset();
        }

        var ratingSliderBar = document.getElementById('ratingSliderBar');
        if (ratingSliderBar != null && ratingSliderBar.noUiSlider !== undefined) {
            ratingSliderBar.noUiSlider.reset();
        }

        //clean all used filters
        arrUsedFilters = new Set();
        arrUsedFiltersBar = new Set();

        //Clear bar filters color
        $("#btnPriceBar").css({ "background": "#FFF", "color": "grey" });
        $("#btnRatingBar").css({ "background": "#FFF", "color": "grey" });
        $("#btnStarsBar").css({ "background": "#FFF", "color": "grey" });


        //reset filter hotel name
        document.getElementById('filter-resort-name').value = '';

        morefiltersObj = { amenities: [], price: [0, 5000], rating: [0, 5], stars: [1, 5], modal: { rating: [0, 5], stars: [1, 5] }, bar: {} };


        const search = document.getElementById("filter-resort-name");
        const matchList = document.getElementById("match-list");
        search.innerHTML = "";
        matchList.innerHTML = "";

        document.getElementById("contFilters").innerHTML = "";
        document.getElementById("btnMoreFilters").style.borderColor = "#DDD";
    },

    _min_max_price_markers: function (markers_in_viewport) {
        let arrPrice = [];
        let price_array = [];
        for (let x = 0; x < markers_in_viewport.length; x++) {
            let price = markers_in_viewport[x].minimumPrice;
            arrPrice.push(price)
        }

        const groupArray = _.groupBy(arrPrice);
        let groupEntries = Object.entries(groupArray);

        let dataX = [];
        for (let x = 0; x < groupEntries.length; x++) {
            dataX.push(parseInt(groupEntries[x][0]));
        }

        let min_price = _.min(dataX);
        let max_price = _.max(dataX);

        //morefiltersObj.original = {price:[min_price, max_price]};

    },


};



var featureMapAdmin = {
    _init: function () {
        //console.log('featureMapAdmin._init....................................');

        var _this = $(this);
        var _autocomplete_places = $('#promotion_place');
        featureMapAdmin._apply_autocomplete_places(_autocomplete_places);

    },
    _apply_autocomplete_places: function (element) {
        var input = document.getElementById('promotion_place');
        var autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.setFields(['geometry', 'id', 'name']);

        google.maps.event.addListener(autocomplete, 'place_changed', function () {
            var place = autocomplete.getPlace();
            $('#promotion_place_id').val(JSON.stringify(place.geometry));
        });
    },


};




/**
 * Number.prototype.format(n, x)
 *
 * @param integer n: length of decimal
 * @param integer x: length of sections
 */
Number.prototype.format = function (n, x) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
};

_.mixin({
    'findByValues': function (collection, property, values) {
        return _.filter(collection, function (item) {
            return _.includes(values, item[property]);
        });
    }
});



(function (window, undefined) {

    // Bind to StateChange Event
    History.Adapter.bind(window, 'statechange', function () { // Note: We are using statechange instead of popstate
        var state = History.getState(); // Note: We are using History.getState() instead of event.state
        //console.log('state-change: ' + state.data.path);
        //console.dir(state);
        if (typeof state.data.path !== "undefined") {
            featureMap[state.data.path](state.data.attributes);
        }
    });
})(window);


var SnippetPrice = function () {
    var price = 0;
    var show_price = false;

    var setPrice = function (new_price) {
        SnippetPrice.price = new_price
    }
    var setPriceShow = function (ban) {
        SnippetPrice.show_price = (ban ? true : false)
    }

    //== Public Functions
    return {
        // public variables
        price: price,
        show_price: show_price,
        // public functions
        // init: function() {
        //     setPrice(0);
        // },
        set: function (new_price) {
            setPrice(new_price);
        },
        setPriceShow: function (ban) {
            setPriceShow(ban);
        },
    };
}();

var SnippetMoreFilters = function () {
    var sliderBan = false;
    var checkBan = false;
    var sliderRating = false;

    var changeColorBtn = function () {
        console.log(SnippetMoreFilters.sliderBan, SnippetMoreFilters.checkBan);
        if (SnippetMoreFilters.sliderBan ||
            SnippetMoreFilters.sliderRating ||
            SnippetMoreFilters.checkBan) {
            $(`#moreFiltersApply`).css("background-color", "#465665");
            $(`#moreFiltersReset`).css("background-color", "#D2D5DA");
        } else { // originl color
            $(`#moreFiltersApply`).css("background-color", "#D2D5DA");
            $(`#moreFiltersReset`).css("background-color", "#465665");
        }
    }

    var setSliderBan = function (ban) {
        SnippetMoreFilters.sliderBan = ban
        SnippetMoreFilters.changeColorBtn();
    }
    var setSliderRatingBan = function (ban) {
        SnippetMoreFilters.sliderRating = ban
        SnippetMoreFilters.changeColorBtn();
    }
    var setCheckBan = function (ban) {
        SnippetMoreFilters.checkBan = ban
        SnippetMoreFilters.changeColorBtn();
    }
    var reset = function () {
        SnippetMoreFilters.sliderBan = false
        SnippetMoreFilters.sliderRating = false
        SnippetMoreFilters.checkBan = false
        SnippetMoreFilters.changeColorBtn();
    }
    //== Public Functions
    return {
        sliderBan: sliderBan,
        checkBan: checkBan,
        sliderRating: sliderRating,
        changeColorBtn: function () {
            changeColorBtn();
        },
        setSliderRatingBan: function (ban) {
            setSliderRatingBan(ban);
        },
        setSliderBan: function (ban) {
            setSliderBan(ban);
        },
        setCheckBan: function (ban) {
            setCheckBan(ban);
        },
        reset: function () {
            reset();
        },
    };
}();
