var apiVariables =  {
  "variables": {
      "event": {
          "value": "api_payment",
          "type": "String"
      },
      "merchantId": {
          "value": "",
          "type": "String"
      },
      "content": {
          "value": "",
          "type": "Json",

      },
      "charge_at": {
          "value": "",
          "type": "String"
      },
      "apiKey": {
          "value": "",
          "type": "String",
      },
      "app_id": {
          "value": "",
          "type": "String"
      },
  }
};
var PaymentVariables = {
  "language": "en",
  "command": "SUBMIT_TRANSACTION",
  "merchant": {
      "apiKey": "",
      "apiLogin": ""
  },
  "transaction": {
      "order": {
          "accountId": "",
          "referenceCode": "payment_test_00000001",
          "description": "payment test",
          "language": "es",
          "signature": "",
          "notifyUrl": "",
          "additionalValues": {
              "TX_VALUE": {
                  "value": 0,
                  "currency": "",
              }
          },
          "buyer": {
              "merchantBuyerId": "1",
              "fullName": '',
              "emailAddress": "",
              "contactPhone": "",
              "dniNumber": "",
              "shippingAddress": {
                  "street1": "",
                  "street2": "",
                  "city": "",
                  "state": "",
                  "country": "",
                  "postalCode": "",
                  "phone": ""
              }
          },
          // "shippingAddress": {
          //     "street1": "",
          //     "street2": "",
          //     "city": "",
          //     "state": "",
          //     "country": "",
          //     "postalCode": "",
          //     "phone": ""
          // }
      },
      "payer": {
          "merchantPayerId": "1",
          "fullName": "",
          "emailAddress": "",
          "contactPhone": "",
          "dniNumber": "",
          "billingAddress": {
              "street1": "",
              "street2": "",
              "city": "",
              "state": "",
              "country": "",
              "postalCode": "",
              "phone": ""
          }
      },
      "creditCard": {
          "number": "",
          "securityCode": "",
          "expirationDate": "",
          "name": ""
      },
      "extraParameters": {
          "INSTALLMENTS_NUMBER": 1
      },
      "type": "AUTHORIZATION_AND_CAPTURE",
      "paymentMethod": "",
      "paymentCountry": "",
      "deviceSessionId": "",
      "ipAddress": "127.0.0.1",
      "cookie": "",
      "userAgent": "Mozilla/5.0 (Windows NT 5.1; rv:18.0) Gecko/20100101 Firefox/18.0"
  },
  "test": false
};
var SnippetStripe = function() {
  var payment_variables = apiVariables;
  var payment_json = PaymentVariables;
  var socket;
  var stripe_pk;
  var dataInit;

  var handleInitData = function (data) {
    socket = io.connect(data.web_socket_url);
    socket2 = io.connect(data.web_socket_url);
    stripe_pk = data.stripe_pk;
    dataInit = data;
    let appInfo = (data.live == 'sandbox' ? false: true);
    //set language
    let language = data.features.language !== 'pt' ? data.features.language : 'pt_BR';
    // $i18n.locale = language;
    
    //merchant id
    payment_variables.variables.apiKey.value = data.merchant.apiKey;
    payment_variables.variables.app_id.value = data.merchant.app_id;
    
    //device attributes
    mode = appInfo.live ? 'Production' : 'Test';
    payment_json.test = !appInfo.live; //if true send to production, else testing
    payment_json.transaction.deviceSessionId = data.merchant.deviceSessionId;
    payment_json.transaction.cookie = data.merchant.sessionid;
    
    //mode params
    payment_variables.variables.event.value = data.payment.mode; //api mode
    payment_variables.variables.charge_at.value = data.payment.charge_at; //api charge_at
    payment_json.transaction.order.description = data.payment.description;
    
    //buyer
    payment_json.transaction.order.buyer.fullName = data.buyer.name;
    payment_json.transaction.order.buyer.emailAddress = data.buyer.email;
    payment_json.transaction.order.buyer.merchantBuyerId = data.buyer.buyerId;
    payment_json.language = data.features.language;
    payment_json.transaction.paymentCountry = data.buyer.country;
    if(data.buyer.savedCard !== null && data.buyer.savedCard !== undefined){
      payment_json.savedCard = data.buyer.savedCard;
    }else {
      payment_json.savedCard = undefined;
    }
    
    
    //currency
    payment_json.transaction.order.additionalValues.TX_VALUE.value = data.payment.amount;
    payment_json.transaction.order.additionalValues.TX_VALUE.currency = data.payment.currency;
    
    //stripe_payment
    // if(provider.toLowerCase() == 'stripe' || provider.toLowerCase() == 'payu'){
    payment_json.stripe_payment = data.stripe_payment;
    // payment_json.payment_type = data.session.session_params.payment_type;
    payment_json.payment_type = 'inventory';
    payment_json.payment_description =  data.payment.description;
    // }
      
    //session
    session = data.session;
    //url
    success_url = data.success_url;
    cancel_url = data.cancel_url;  

    if (data.saved_card_id == 'alipay' || data.saved_card_id == 'wechat') {
      setCreateSource(data.saved_card_id, 'STRIPE', 1)
    } else {
      setCreditCardSave(data);
    }
  }
  var handleSendData = function () { //post function
    NProgress.start();

    console.log('%cPOST','color:green');
    var paymethod = 'card';
    if(payment_json.transaction.paymentMethod == 'alipay' || payment_json.transaction.paymentMethod == 'wechat'){
      paymethod = payment_json.transaction.paymentMethod;
    }
    payment_variables.variables = {...payment_variables.variables,paymentMethod: { "value":paymethod, "type": "String" }};

    // send object via post
    pending = true;
    let stringJson = JSON.stringify(payment_json);
    payment_variables.variables.content.value = stringJson;

    // let _= this;
    axios.post(dataInit.payment_api_url+'/Process_payment',
        payment_variables, {
            headers: { 'Content-Type': 'application/json', }
        }
    )
    .then(response => {
        //SUSCRIBE TO THE CHANNEL WITH CAMUNDA ID
      NProgress.start();

        socket.on(`payment.${response.data.id}`, (dataRes) => {
            handleCamundaData(dataRes, response.data.id);
        });
        
    })
    .catch(error => {
        console.log('%cError','color:red');
        console.log(error,'color:red');
        return error;
    });
  }
  var setCreditCardSave = function (data) { 
    NProgress.start();
    axios.post(dataInit.payment_api_url+'/getMethod', {
      country_name: data.api_params_request.country_name,
      wallet_id: data.api_params_request.wallet_id,
      buyerId: data.api_params_request.buyerId,
      environment: data.api_params_request.environment,
      mode:data.api_params_request.mode
    } , {
        headers: { 'Content-Type': 'application/json', }
      }
    )
    .then(response => {
       if(response.data.length > 0){
          var cardcss; 
          items = response.data[0].paymentMethod;
          provider = response.data[0].provider;
          var cardpay = items.find(el => el.type === 'creditcard');
          // if(cardpay.items.length == 1){
          //      this.paydefault = 'card';
          // }else{
          for (h = 0; h < cardpay.items.length; h++) {
                if(cardpay.items[h].hasOwnProperty('creditCard')){
                    // cardcss = this.cards.find(el => el.type === cardpay.items[h].creditCard.paymentMethod);
                    // cardpay.items[h].creditCard.css = cardcss.css;
                    console.log(cardpay.items[h].creditCard.card_id, data.saved_card_id);
                    
                    if(cardpay.items[h].creditCard.card_id == data.saved_card_id){
                        cardData = cardpay.items[h].creditCard;
                    }
                }  
          }
          // }

          if(typeof cardData !== 'undefined'){
            console.log('%cCredit card Save', 'color:blue');
            payment_json.transaction.creditCard.securityCode = 'xxx';
            //fill out with existing data
            payment_json.transaction.paymentMethod = cardData.paymentMethod;
            payment_json.transaction.paymentCountry = cardData.paymentCountry;
            payment_json.transaction.creditCardTokenId = cardData.creditCardTokenId;
            payment_json.transaction.customer = cardData.customer;
            payment_json.transaction.creditCard.number = "save";
            payment_json.transaction.payer = cardData.payer;
            
            payment_json.transaction.order.buyer.contactPhone = payment_json.transaction.payer.contactPhone
            payment_json.transaction.order.buyer.shippingAddress.country = payment_json.transaction.payer.billingAddress.country;
            payment_json.transaction.order.buyer.shippingAddress.street1 = payment_json.transaction.payer.billingAddress.street1;
            payment_json.transaction.order.buyer.shippingAddress.city = payment_json.transaction.payer.billingAddress.city;
            payment_json.transaction.order.buyer.shippingAddress.postalCode = payment_json.transaction.payer.billingAddress.postalCode;
        
            fullName = payment_json.transaction.payer.fullName;
            handleSendData();
      
          }
        }
      });
    
}
        // Callback from socket response
  var handleCamundaData = function (data, camunda_id) {

    NProgress.start();
    console.log('%cCAMUNDA DATA','color:red');
    console.log(data);
    let transactionResponse = JSON.parse(data.transactionResponse);
    // let getProvider = this.provider.toLowerCase();
    let getProvider = 'stripe';
    const errorCode = new Set(['card_declined']); //car_declined has declined messages
    const transactionState = new Set(['APPROVED','PENDING','SUBMITTED']);
    console.log(transactionResponse);
    console.log(`%cProvider ${getProvider}`, `color:red;`);
    let controller_url = dataInit.controller_url;
    if(transactionResponse.payment_intent != undefined){
      console.log('payment_intent')
      controller_url += '&payment_intent='+transactionResponse.payment_intent + '&payment_method='+transactionResponse.payment_method;
      console.log('controller_url ', controller_url);
    }
    if(transactionResponse.orderId != undefined){
      console.log('orderId')
      controller_url += '&orderId='+transactionResponse.orderId
      console.log('controller_url ', controller_url);
      
    }
    dataInit.controller_url = controller_url;

    if (transactionResponse.responseCode === "authentication_required" && (getProvider == 'stripe' || getProvider == 'payu')) {
      var stripe = Stripe(stripe_pk);
      if (transactionResponse.client_secret != null && transactionResponse.payment_intent != null && transactionResponse.payment_method != null) {
        // var _this = this;
        NProgress.done();
        stripe.confirmCardPayment(transactionResponse.client_secret, {
          payment_method: transactionResponse.payment_method
        }).then(function(result) {
          console.log('result       >>>>>>>>>>>>>');
          console.log('3d Secure       >>>>>>>>>>>>>');
          console.log(result);
          
          if (result.error) {
            // Show error to your customer
            handleError(result.error.message);

          } else {
            if ((result.paymentIntent.status === 'succeeded' || (result.paymentIntent.status === "requires_capture"))) {
              console.log(`%cElse 5`, `color:blue;`);
              //unsuscribe from formed channel
              // socket.off(`payment.${camunda_id}`);
              NProgress.start();

              //loader false to stop loading animation
              success = true;
              pending = false;
              sucessData = data;
              timeOut = setTimeout(()=>{
                handleSuccess(sucessData);
              }, 1000);
            }
          }
        });
        
      }else {
            handleError('Error');

      }
    }else if (data.code === "SUCCESS") {
        if(!transactionState.has(transactionResponse.state)) {
            console.log(`%cIF 1`, `color:blue;`);

            // tryAgainBtn.customer = transactionResponse.hasOwnProperty('customer') ? transactionResponse.customer : '';
            // handleError($t(`message.${getProvider}.${$i18n.locale}.message.${transactionResponse.responseCode}`));
            if(errorCode.has(transactionResponse.responseCode)) {
                handleError($t(`message.${getProvider}.${$i18n.locale}.message.${transactionResponse.decline_code}`));
            } else if(getProvider == 'stripe') {
                handleError($t(`message.${getProvider}.${$i18n.locale}.message.generic.${transactionResponse.responseCode}`));
            } else if(getProvider == 'payu') {
                handleError($t(`message.${getProvider}.${$i18n.locale}.message.${transactionResponse.responseCode}`));
            } else {
                handleError($t(`message.${transactionResponse.responseCode}`));
            }
        }else if(getProvider == 'stripe' && transactionResponse.hasOwnProperty('qr_code_url')){
            console.log(`%cIF 2 Wechat`, `color:blue;`);
            //unsuscribe from formed channel
            // socket.unsubscribe(`payment.${camunda_id}`);
            socket.off(`payment.${camunda_id}`);
            // var qrcode = new QRCode(document.getElementById("qrcode"), {
            //   text: transactionResponse.qr_code_url,
            //   width: 128,
            //   height: 128,
            //   colorDark : "#000000",
            //   colorLight : "#ffffff",
            //   correctLevel : QRCode.CorrectLevel.H
            // });
            NProgress.done();
            $('#qrcode').empty().qrcode({
              render  : "table",
              text : transactionResponse.qr_code_url, // users will be redirected tothis URL when scanning the QR-Code
              width : 300,// image width in pixel
              height : 300,// image height in pixel
              qrsize : 100// quality of the QR-Code in pixel
            });
            pending = false;
              // let _= this;
            // let pop = centeredPopup(transactionResponse.qr_code_url,'Wechat','800','500','yes');
            // new QRCode(document.getElementById("qrcode"), transactionResponse.qr_code_url);
            console.log(`%cOpen Modal QR`, `color:blue;`);
            console.log(transactionResponse.qr_code_url);
            

            $("#myModal-wechat").modal();

              // EventBus.$emit('qr_code_url',{qr_code_url:transactionResponse.qr_code_url,amount:transactionResponse.sources.amount,currency:transactionResponse.sources.currency});
              socket2.on(`payment_charges.${camunda_id}`, (dataRes) => {
                  console.log("subscribe >>>>>>>>>>>>>>>>");
                  console.log(dataRes);
                  handleCamundaData_source(dataRes, camunda_id);
            });  
        }else if(getProvider == 'stripe'  && transactionResponse.hasOwnProperty('sources') && transactionResponse.sources.hasOwnProperty('redirect')){  
            console.log(`%cIF 3 AliPay`, `color:blue;`);
            socket.off(`payment.${camunda_id}`);
            pending = false;
            // let   = 
            let url = transactionResponse.sources.redirect.url;
            console.log('before handleCamundaData_source 2',camunda_id);
            //SUSCRIBE TO THE CHANNEL WITH CAMUNDA ID
            socket2.on(`payment_charges.${camunda_id}`, (dataRes) => {
              console.log('response payment_charge ',camunda_id);
              
                handleCamundaData_source(dataRes, camunda_id);
                // handleCamundaData(dataRes, response.data.id);
            });
            
            let pop = centeredPopup(url,'AliPay','800','500','yes');
            // EventBus.$emit('nextStep');
            pending = true;

            console.log('after centeredPopup ');
            
            // timeOut = setTimeout(()=>{
            //       pending = false;
            // }, 12000);
            // socket.subscribe(`payment_charges.${camunda_id}`, (data) => {
            //       // handleCamundaData_source(data, camunda_id);
            //       console.log('unsubscribw');
                  
            // });  
        } else if(getProvider == 'payu' && transactionResponse.hasOwnProperty('responseCode') && transactionResponse.responseCode == "PENDING_TRANSACTION_CONFIRMATION") {
            console.log(`%cIF 4`, `color:blue;`);
            socket.unsubscribe(`payment.${camunda_id}`);
            let url = transactionResponse.extraParameters.URL_PAYMENT_RECEIPT_HTML;
            // centeredPopup(url,'Payu','800','500','yes');
            //EventBus.$emit('URL_PAYMENT_RECEIPT_PDF',transactionResponse.extraParameters.URL_PAYMENT_RECEIPT_PDF);
            //EventBus.$emit('nextStep');
            success = true;
            pending = false;
            sucessData = data;
            timeOut = setTimeout(()=>{
                handleSuccess();
            }, 6000);
        } else {
            console.log(`%Else 5 success`, `color:blue;`);
            //unsuscribe from formed channel
            // socket.unsubscribe(`payment.${camunda_id}`);
            //loader false to stop loading animation
            success = true;
            pending = false;
            sucessData = data;
            timeOut = setTimeout(()=>{
              handleSuccess(sucessData);
            }, 1000);
        }
    } else {
        if(transactionResponse != null){
            console.log(`%cIF 6`, `color:blue;`);
            // tryAgainBtn.customer = transactionResponse.hasOwnProperty('customer') ? transactionResponse.customer : '';
            // if(errorCode.has(transactionResponse.responseCode)) {
            //     handleError($t(`message.${getProvider}.${$i18n.locale}.message.${transactionResponse.decline_code}`));
            // } else if(getProvider == 'stripe') {
            //     handleError($t(`message.${getProvider}.${$i18n.locale}.message.generic.${transactionResponse.responseCode}`));
            // } else if(getProvider == 'payu') {
            //     handleError($t(`message.${getProvider}.${$i18n.locale}.message.${transactionResponse.responseCode}`));
            // } else {
            //     handleError($t(`message.${transactionResponse.responseCode}`));
            // }
            handleError(`${transactionResponse.responseCode}: ${transactionResponse.outcome}`);

        }else{
            handleError(data.error);
        }
    
    }
  }
  
  var handleError = function (error) {
    // this.error = true;
    // this.errorMessage = error;
    // setTimeout(()=>{ this.pending = false; },100);
    alert(error)
    NProgress.done();

  }
  var handleSuccess = function (sucessData) {
    console.log('sucessData');
    NProgress.start();

    // clearTimeout(this.timeOut); //stop timeout from camunda data
    window.parent.postMessage({action: 'response', data: sucessData}, "*");
    // this.closeIframe();
    window.parent.postMessage({ action: 'close' }, "*");
    setTimeout(()=>{

      finish({action: 'response', data: sucessData})

    }, 1000);
  }
  
  var finish = function (event) {
    console.log('finish');
    console.log(event);
    
    switch (event.action) {
      case 'close':
          document.getElementsByName("payu_name")[0].style.display = "none";
          break;
      case 'response':
          console.log('tokenFN >>>>');
          setTimeout(()=>{
            tokenFN(event);
          }, 1000);

          // document.getElementsByName("payu_name")[0].style.display = "none";
          break;
    }

    // NProgress.done();
  }

  var handleCamundaData_source = function (data, camunda_id) {
    console.log('%cCAMUNDA DATA SOURCE','color:red');
    console.log(data);
    let transactionResponse = JSON.parse(data.transactionResponse);
    // let getProvider = this.provider.toLowerCase();
    let getProvider = 'stripe';

    const errorCode = new Set(['card_declined']); //car_declined has declined messages
    const transactionState = new Set(['APPROVED','PENDING','SUBMITTED','chargeable']);
    console.log(transactionResponse);
    console.log(`%cProvider ${getProvider}`, `color:red;`);
    if (data.code === "SUCCESS") {
        if(!transactionState.has(transactionResponse.state)) {
            // this.tryAgainBtn.customer = transactionResponse.hasOwnProperty('customer') ? transactionResponse.customer : '';
            // this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.${transactionResponse.responseCode}`));
            if(errorCode.has(transactionResponse.responseCode)) {
                // this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.${transactionResponse.decline_code}`));
                handleError(transactionResponse.decline_code);

            } else if(getProvider == 'stripe') {
                // this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.generic.${transactionResponse.responseCode}`));
                handleError(transactionResponse.responseCode);

            } else if(getProvider == 'payu') {
                // this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.${transactionResponse.responseCode}`));
                handleError(transactionResponse.responseCode);

            } else {
                // this.handleError(this.$t(`message.${transactionResponse.responseCode}`));
                handleError(transactionResponse.responseCode);

            }
        }else{
            //unsuscribe from formed channel
            // this.sockets.unsubscribe(`payment_charges.${camunda_id}`);
            //loader false to stop loading animation
            success = true;
            pending = false;
            sucessData = data;
            // timeOut = setTimeout(()=>{
            handleSuccess(sucessData);
            // }, 6000);
        }
    }else {
        // this.tryAgainBtn.customer = transactionResponse.hasOwnProperty('customer') ? transactionResponse.customer : '';
        if(errorCode.has(transactionResponse.responseCode)) {
            // this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.${transactionResponse.decline_code}`));
            handleError(transactionResponse.decline_code);

        } else if(getProvider == 'stripe') {
            // this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.generic.${transactionResponse.responseCode}`));
            handleError(transactionResponse.responseCode);

        } else if(getProvider == 'payu') {
            this.handleError(this.$t(`message.${getProvider}.${this.$i18n.locale}.message.${transactionResponse.responseCode}`));
            handleError(transactionResponse.responseCode);

        } else {
            // this.handleError(this.$t(`message.${transactionResponse.responseCode}`));
            handleError(transactionResponse.responseCode);

        }
    }
  }
  var setCreateSource = function (paymentMethod,provider,expire = null,return_payment= null) {
    console.log("createSource >>>>>");
    console.log(paymentMethod);
    console.log(provider);
    console.log(expire);
    // this.provider = provider;
    // this.paymentMethod = paymentMethod;
    if(expire!=null){
        payment_variables.variables = {
          ...payment_variables.variables,
          TIME_EXPIRED: { 
            "value":`PT${expire}H`, 
            "type": "String" 
          },
          return_payment:{ 
            "value":return_payment != null ? return_payment: '' , 
            "type": "String" 
          }
        };
    }
    payment_variables.variables.event.value = "create_source_payment";
    payment_json.transaction.paymentMethod = paymentMethod;
    handleSendData()
    //EventBus.$emit('nextStep');

  }

  var centeredPopup = function (url,winName,w,h,scroll) {
    console.log('centeredPopup');
    
    NProgress.done();
    let LeftPosition = (screen.width) ? (screen.width-w)/2 : 0;
    let TopPosition = (screen.height) ? (screen.height-h)/2 : 0;
    let settings ='height='+h+',width='+w+',top='+TopPosition+',left='+LeftPosition+',scrollbars='+scroll+',resizable'
    // eslint-disable-next-line no-unused-vars
    let popupWindow = window.open(url,winName,settings)

    console.log('popupWindow', popupWindow);
    return popupWindow
    
  }
  
  var showMessage = (message) => {
    if (message !== "" && message !== null && message !== undefined) {
      $('#message #text').html(message);
      $('#message').modal('show');
    }
  }

  var showMessageHtml = (html) => {
    if (html !== "" && html !== null && html !== undefined) {
      $('#message div[class=modal-body]').html(htmlDecode(html));
      $('#message').modal('show');
      
      function htmlDecode(input) {
          var e = document.createElement('div');
          e.innerHTML = input;
          return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
      }
    }
  }
  
  var openStripeSession = (sessionId, pk) => {
    if(sessionId !== null && sessionId !== undefined && 
      pk !== null && pk !== undefined
    ){
      var stripe = Stripe(pk);
      stripe.redirectToCheckout({
        // Make the id field from the Checkout Session creation API response
        // available to this file, so you can provide it as parameter here
        sessionId
      }).then(function(r) {
        // If `redirectToCheckout` fails due to a browser or network
        // error, display the localized error message to your customer
        // using `result.error.message`.
        showMessage(r.error.message)

      });

    }
  }
  var sendReserveBookingInfo = () => {
    // /reservations/booking-info
    $("#btn-send-booking-info").on("click", async() => {
      NProgress.start();

      // const data = getDataForm();
      // console.log('data >>>',data);
      const urlPost = '/reservations/booking-info2'
      const form = $('#form-booking-info')
      var dataForm = $(form).serialize()

      try {
        const bookingRes = await window.axios({
          method: 'post',
          url: urlPost,
          data: dataForm,
        })
        
        const data = bookingRes.data;
        if (data.hasOwnProperty('payment')) {
          if (data.payment === '/reservations/payment') {
            console.log('entross>>')
            $('body').inspira('_redirect', '/reservations/payment' );
          }
          if (data.payment === null) {
            $('body').inspira('_redirect', '/' );
          }
          const pk = data.payment.pk || null
          const sessionId = data.payment.session || null
          console.log(sessionId,pk);
          if (pk !== null && sessionId !== null) {
            openStripeSession(sessionId, pk)
          } else {
            const paymentOption = data.payment|| null
            if (paymentOption !== null ) {
              console.log('entro>>  handleInitData')

              handleInitData(paymentOption);
  
            }
          }
          
        } else {
          NProgress.done();
          showMessage(data.message)
          showMessageHtml(data.html)
          
        }
      } catch (error) {
        console.log(error);
        NProgress.done();
      }
      
    });
    
  }
  var tokenFN = (token) => {
    var recurring_payment = 1;
    NProgress.start();
    console.log('RESPONSE >>>>>> ');
    console.log(token);
    console.log(dataInit.payment.amount);
    var params =  { 
      provider: 'payu', 
      type: 'inventory',
      token_id: token.data.instance_id,
      amount: dataInit.payment.amount, 
      currency: dataInit.payment.currency,
      confirm_payment: token.data.confirm,
      description: dataInit.payment.description, 
      code_id: '',
      id: 1, 
      recurring_payment: recurring_payment,
      user_id: dataInit.user_id 
    }

    $.post( dataInit.controller_url, params, function(response) {
      $('#register-modal').modal('toggle');

      if(response.error == false ) {
          $('body').inspira('_redirect', dataInit.return );
      } else {
        NProgress.done();
        $('#message #text').html(response.message);
        $('#message').modal('show');
        $('#message').on('hidden.bs.modal', function () {
            $('body').inspira('_redirect', '#');
        });
      }

    }).fail(function() {
      console.log( 'error');
      NProgress.done();
    }).always(function() {
      NProgress.done();
      console.log( 'finished' );
    });
  }
  return {
    // public functions
    initPayment: function(data) {
      NProgress.start();
      handleInitData(data);

    },
    init: function () {
      sendReserveBookingInfo();
    },
  };
}();
$(document).ready(function(){
  SnippetStripe.init();
})

