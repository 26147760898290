var amenities_array ={
  "Swimming_pool":'@lang("inspira.Swimming_pool")',
  "Indoor_swimming_pool": '@lang("inspira.Swimming_pool")',
  "Laundry_service":'@lang("inspira.Laundry_service")',
  "Bar": '@lang("inspira.Bar")',
  "Sauna_or_Steam_Room":'@lang("inspira.Sauna_or_Steam_Room")',
  "Fitness_Center": '@lang("inspira.Fitness_Center")',
  "Jacuzzi":'@lang("inspira.Jacuzzi")',
  "Housekeeping": '@lang("inspira.Housekeeping")',
  "Sport_Courts":'@lang("inspira.Sport_Courts")',
  "Game_room": '@lang("inspira.Game_room")',
  "Spa":'@lang("inspira.Spa")',
  "Multilingual_staff": '@lang("inspira.Multilingual_staff")',
  "Gift_Shop":'@lang("inspira.Gift_Shop")',
  "Grocery_Shopping_Service": '@lang("inspira.Grocery_Shopping_Service")',
  "Movie_Rental":'@lang("inspira.Movie_Rental")',
  "Billiards_or_pool_table": '@lang("inspira.Billiards_or_pool_table")',
  "Television_in_lobby": '@lang("inspira.Television_in_lobby")',
  "Internet_in_public_areas": '@lang("inspira.Internet_in_public_areas")',
  "Car_Rental":'@lang("inspira.Car_Rental")',
  "Conference_facilities":'@lang("inspira.Conference_facilities")',
  "Live_Entertainment":'@lang("inspira.Live_Entertainment")',
  "Clubhouse":'@lang("inspira.Clubhouse")',
  "Fishing":'@lang("inspira.Fishing")',
  "Medical_facility":'@lang("inspira.Medical_facility")',
  "Safe-deposit_box":'@lang("inspira.Safe-deposit_box")',
  "Organized_Activities":'@lang("inspira.Organized_Activities")',
  "Barbecue_Facilities":'@lang("inspira.Barbecue_Facilities")',
  "ATM":'@lang("inspira.ATM")',
  "Salon":'@lang("inspira.Salon")',
  "Pool_towels_provided":'@lang("inspira.Pool_towels_provided")',
  "Lake":'@lang("inspira.Lake")',
  "Nightclub":'@lang("inspira.Nightclub")',
  "Marina":'@lang("inspira.Marina")',
  "Currency_exchange":'@lang("inspira.Currency_exchange")',
  "Picnic_Area":'@lang("inspira.Picnic_Area")',
  "Post_Office":'@lang("inspira.Post_Office")',
  "Miniature_Golf":'@lang("inspira.Miniature_Golf")',
  "Putting_Green":'@lang("inspira.Putting_Green")',
  "RCI_Onsite":'@lang("inspira.RCI_Onsite")',
  "Driving_Range":'@lang("inspira.Driving_Range")',
  "No_Pets_Allowed":'@lang("inspira.No_Pets_Allowed")',
  "Internet_in_public_areas":'@lang("inspira.Internet_in_public_areas")'
}